import { reactive } from "vue";
import iAxios from "@/store/axiosInterface";
import { userStore } from "@/store/userStore";

export const UserService = {
  async getData(includeAdmin,timeStamp) {
    try {
      const store = userStore();
      const response = await iAxios.get(process.env.VUE_APP_BASE_API_URL + "/company/sub-list");
      const data = await response.data;
      const subs = await data.subs;
      if (includeAdmin){
        const store=userStore();
        const whoAmIData=store.whoAmIData;
        const admin={
          cid: whoAmIData.cid,
          country:whoAmIData.county,
          email:whoAmIData.email,
          firstName: whoAmIData.firstName,
          isMain: whoAmIData.isMain,
          mobile:whoAmIData.mobile,
          role: whoAmIData.role,
          surName: whoAmIData.surName,
          userName: whoAmIData.userName,
        }
        subs.push(admin);
      }
      store.dataStates.users = timeStamp;
      store.setDataToStorage("users", subs);
      return Promise.resolve(subs);
    } catch (e) {
      console.log(e);
    }
  },


  async checkForChanges() {
    const response = await iAxios.get(process.env.VUE_APP_BASE_API_URL + "/company/change-stats ");
    const data = await response.data;
    return Promise.resolve(data);
},

 /*  async getUsers(includeAdmin) {
      return Promise.resolve(this.getData(includeAdmin));
  },
 */
  async getUsers(includeAdmin) {
    const key = "users";
    const store = userStore();
    const states = await this.checkForChanges();
    const timestampBE = states?.users;
    const timestampFE = store?.dataStates?.users;

    if (states?.cid != store?.dataStates?.cid) {
        store.dataStates.cid = states?.cid;
        return Promise.resolve(this.getData(includeAdmin,timestampBE));
    }

    const foragePassed = await store.forageCheckForKey(key);
    if (!foragePassed || !timestampBE || !timestampFE || (timestampBE > timestampFE)) {
        return Promise.resolve(this.getData(includeAdmin,timestampBE));
    } else {
        return store.getDataFromStorage(key);
    }
},

  /**
   * 
   * @param {*} userUid - UserUid
   * @param {*} userList - Optional bei Eingabe wird die Liste nicht neu geladen
   * @returns - Mitarbeiter
   */
  async getUserDetails(userUid, userList) {
    try {
      //hole die userListe von getData und filtere nach userUid und gebe den User zurück
      userList?? await this.getData();
      const user = userList.filter((user) => user.uid === userUid);
      return Promise.resolve(user);
    } catch (e) {
      console.log(e);
    }
  },

  async createUser(user) {
    try {
      const response = await iAxios.post(process.env.VUE_APP_BASE_API_URL + "/company/sub-register", user);
      const data = await response.data;
      return Promise.resolve(data);
    } catch (e) {
      console.log(e);
    }
  },

  async updateUser(user) {
    try {
      const response = await iAxios.put(process.env.VUE_APP_BASE_API_URL + "/company/sub-modify", user);
      const data = await response.data;
      return Promise.resolve(data);
    } catch (e) {
      console.log(e);
    }
  },

  async deleteUser(userUid) {
    try {
      const response = await iAxios.delete(process.env.VUE_APP_BASE_API_URL + "/company/sub-delete/" + userUid);
      const data = await response.data;
      return Promise.resolve(data);
    } catch (e) {
      console.log(e);
    }
  },

};

