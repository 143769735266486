<template>
  <div>
    <header>
      <div class="flex flex-row flex-wrap text-xl py-3 font-semibold">
        Standorte, Filialen, Lager ...
      </div>
    </header>
    <!-- v-if in datatable notwendig wegen bearbeitungsmodus beim hinzufügen-->
    <DataTable
      v-if="extendedShopSettings?.locations?.length > 0"
      :value="extendedShopSettings.locations"
      v-model:editingRows="editingRows"
      size="small"
      editMode="row"
      dataKey="id"
      @row-edit-init="onRowEditInit"
      @row-edit-save="onRowEditSave"
      :pt="{
        table: { style: 'min-width: 50rem' },
        column: {
          bodycell: ({ state }) => ({
            style:
              state['d_editing'] &&
              'padding-top: 0.75rem; padding-bottom: 0.75rem',
          }),
        },
      }">
      <Column
        v-for="col in cols"
        :key="col.field"
        :field="col.field"
        :style="col.field == 'houseNumber' ? 'width:2%' : ''"
        :header="col.header">
        <template #editor="{ data, field }">
            <AutoComplete v-if="field==='country'"
                  inputClass="text-xs w-full"
                  class="w-full text-xs"
                  v-model="data[field]"
                  optionLabel="name"
                  :suggestions="filteredCountries"
                  @complete="countrySearch"
                   />
          <InputText v-else
            v-model="data[field]"
            class="text-xs"
            :class="
              col.field == 'postalCode' || col.field == 'houseNumber'
                ? 'w-4rem'
                : ''
            " />
        </template>
        <template #body="{ data, field }">
          <span v-if="field==='country'">{{ data[field].name }}</span>
          <span v-else>{{ data[field] }}</span>
        </template>
      </Column>
      <Column header="Aktionen" :rowEditor="true"> </Column>
      <Column field="id">
        <template #body="{ data }">
          <Button
            icon="pi pi-trash text-xs p-0 m-0"
            text
            severity="danger"
            class="text-xs p-0 m-0"
            @click="removeAddress(data.id)" />
        </template>
      </Column>
      <template #footer>
        <Button
          label="Neuen Standort hinzufügen"
          outlined
          severity="success"
          class="text-xs"
          @click="addAddress" />
      </template>
    </DataTable>
  </div>
</template>

<script setup>
import { ref,onMounted,reactive } from "vue";
import { v4 as uuidv4 } from "uuid";
import { useToast } from "primevue/usetoast";
import { userStore} from "@/store/userStore"
import { SettingsExtended } from "@/service/SettingsExtended";
import { CountryService } from "@/service/CountryService";

const countries = ref();
const filteredCountries = ref();
const editingRows = ref([]);
const toast=useToast();
const store=userStore();
const extendedShopSettings = reactive({});
const cols = [
  { field: "label", header: "Bezeichnung" },
  { field: "notice", header: "Hinweis" },
  { field: "phone", header: "Telefon" },
  { field: "street", header: "Straße" },
  { field: "houseNumber", header: "Hausnr" },
  { field: "postalCode", header: "PLZ" },
  { field: "city", header: "Ort" },
  { field: "country", header: "Land" },
  { field: "email", header: "Email" },
  { field: "openingHours", header: "Öffnungszeiten" },
];
const addresses = ref([
  {
     id: uuidv4(),
    label: "Zentrale",
    notice: "Bald nicht mehr",
    phone: "01234 567890",
    street: "Hauptstraße",
    houseNumber: "1",
    postalCode: "12345",
    city: "Musterstadt",
    country: {name:"Deutschland",code:"DE"},
    email: "zentrale@example.com",
    openingHours: "Mo-Fr 08:00-18:00",
    public:true,
    available:true,
  },
  {
    id: uuidv4(),
    label: "Filiale Nord",
    notice: "Lagerplatz",
    phone: "09876 543210",
    street: "Nordweg",
    houseNumber: "22",
    postalCode: "54321",
    city: "Nordstadt",
    country: {name:"Deutschland",code:"DE"},
    email: "nord@example.com",
    openingHours: "Mo-Sa 09:00-20:00",
    public:true,
    available:true,
  },
  {
    id: uuidv4(),
    label: "Filiale Süd",
    notice: "Neue Eröffnung demnächst",
    phone: "02345 678901",
    street: "Südallee",
    houseNumber: "3B",
    postalCode: "67890",
    city: "Südstadt",
    country: {name:"Deutschland",code:"DE"},
    email: "sued@example.com",
    openingHours: "Di-So 10:00-18:00",
    public:true,
    available:true,
  },
]);

const mainAddress = {
    id: uuidv4(),
    label: "Hauptanschrift",
    notice: "",
    phone: store.whoAmIData?.phone,
    street: store.whoAmIData?.streetName,
    houseNumber: store.whoAmIData?.houseNumber,
    postalCode: store.whoAmIData?.postCode,
    city: store.whoAmIData?.city,
    country: store.whoAmIData?.country,
    email: store.whoAmIData,
    openingHours: "Mo-Sa 09:00-20:00",
    public:false,
    available:true,
  };

const addAddress = () => {
  const newEntry = {
    id: uuidv4(),
    label: "Neue Adresse",
    notice: "",
    phone: "",
    street: "",
    houseNumber: "",
    postalCode: "",
    city: "",
    country: {name:"Deutschland",code:"DE"},
    email: "",
    openingHours: "",
    public:false,
    available:true,
  };
  extendedShopSettings.locations.push(newEntry);
  editingRows.value.push(newEntry);
};

const onRowEditInit = (event) => {
  console.log("🚀 ~ file: Locations.vue:154 ~ event.data:", event.data);

  const data = event.data;

  // Bearbeiten erlauben
  if (!editingRows.value.some((row) => row.id === data.id)) {
    editingRows.value.push(data);
  }
};
const onRowEditSave = async (event) => {
  console.log("🚀 ~ file: Locations.vue:140 ~ event:", event);

  let { newData, index } = event;
  extendedShopSettings.locations[index] = newData;
  saveLocations();
};

const saveLocations =async ()=>{
    await SettingsExtended.setSettingsExtended({ extendedShopSettings }).then((response) => {
       if (response.status===200){
        toast.add({
          severity: "success",
          summary: "Erfolgreich",
          detail: "Daten gespeichert",
          life: 3000,
        });
       } 
    });
}

const removeAddress = (id) => {
    extendedShopSettings.locations = extendedShopSettings?.locations.filter((address) => address.id !== id);
};

const werteLaden = async () => {
  await SettingsExtended.getSettingsExtended().then((response) => {
    if (!response) {
      return;
    }
    Object.assign(extendedShopSettings, response?.extendedShopSettings);
  });
};



const countrySearch = (event) => {
  setTimeout(() => {
        if (!event.query.trim().length) {
            filteredCountries.value = [...countries.value];
        } else {
            filteredCountries.value = countries.value.filter((country) => {
                return country.name.toLowerCase().startsWith(event.query.toLowerCase());
            });
        }
    }, 250);
};

onMounted(async () => {
  await werteLaden();
  CountryService.getCountries().then((data) => {
    countries.value = data;
    filteredCountries.value = countries.value;
  });
  // extendedShopSettings.locations=[...addresses.value]
  console.log("🚀 ~ file: Locations.vue:228 ~ extendedShopSettings.locations:", extendedShopSettings.locations)
});
</script>

<style>
.text-xs {
  font-size: 0.75rem;
}
</style>
