<template>
  <div>
    <TabView>
      <TabPanel header="Allgemein" v-if="!store.debug">
        <DocumentSettings />
      </TabPanel>
      <TabPanel header="Online Shop" v-if="isMainUser || isManagementUser">
        <ShopSettings />
      </TabPanel>
      <TabPanel header="Allgemein" v-if="store.debug">
        <DocumentSettings />
      </TabPanel>
      <TabPanel header="Versandeinstellungen">
        <VersandSettings />
      </TabPanel>
      <TabPanel
        header="Shopify"
        v-if="store?.whoAmIData?.rules?.activatedModules?.shopify">
        <ShopifySettingsView />
      </TabPanel>
      <TabPanel v-if="store.debug" header="DB-Tools">
        <DbTools />
      </TabPanel>
      <TabPanel header="Zahlungsmodul" v-if="store.debug">
        <MolliePayment />
      </TabPanel>
    </TabView>
  </div>
</template>

<script setup>
import ShopSettings from "@/components/ShopSettings.vue";
import DocumentSettings from "./DocumentSettings.vue";
import MolliePayment from "./MolliePayment.vue";
import ShopifySettingsView from "./ShopifySettingsView.vue";
import DbTools from "./DbTools.vue";
import { userStore } from "@/store/userStore";
import { storeToRefs } from "pinia";
import { onMounted, reactive, defineAsyncComponent, ref, provide } from "vue";
import { SettingsExtended } from "@/service/SettingsExtended";
import VersandSettings from "./VersandSettings.vue";
import { shippingMethods } from "@/utils/shippingUtils.js";
import { socialMediaLinks, serviceNotes, faqs } from "../utils/shopUtils.js";

/* const VersandSettings = defineAsyncComponent(() =>
  import("./VersandSettings.vue")
); */
const store = userStore();
const { isMainUser, isManagementUser } = storeToRefs(store);

const extendedShopSettings = reactive({
  isOnlineShopActive: false,
  homeSlider: [
    {
      homePageTitel: "BOAHH!",
      homePageSubTitel: "Ein Erlebnis das Niemand vergisst!",
      homeImages: null,
    },
  ],
  socialMediaLinks: {
    enabled: false,
    socialMediaAccount: "",
    list: [],
  },
  faqs: {
    enabled: true,
    list: [],
  },
  serviceNotes: {
    enabled: true,
    layout: "horizontal",
    list: [],
  },
  sliderItems: {
    ItemAutoplayInterval: 0,
    enabled: false,
    list: [],
  },
  imageCompare: {
    enabled:false,
    mainTitel:'',
    image1: null,
    image2: null,
    imageTitel1: "Vorher",
    imageTitel2: "Nachher",
  },
  hideItemsWithoutStock: false,
  laufText: {
    message: "",
    duration: 20,
    bgColor: "red",
  },
  partnerLogos: {
    enabled: false,
    list: [],
  },
  contentAds: [],
  contentMenu: [],
  componentPositions: [
    { index: 0, label: "Service Angaben", value: "serviceNotes" },
    { index: 1, label: "FAQ", value: "faqs" },
  ],
  shippingKinds: [...shippingMethods],
  defaultShippingType: "flat_rate",
  freeShipping: {
    enabled: false,
    minAmountForFree: 150,
    type: "",
  },
});
const B2CEnabled = ref(false);
const B2CVK = ref("VK-1");
provide("extendedShopSettings", extendedShopSettings);
provide("B2CEnabled", B2CEnabled);
provide("B2CVK", B2CVK);
const werteLaden = async () => {
  let settingsExtended = null;
  //await SettingsExtended.setSettingsExtended({extendedShopSettings:{}})

  await SettingsExtended.getSettingsExtended().then((response) => {
    if (!response) {
      return;
    }
    console.warn("🚀 ~ extendedShopSettings:", response.extendedShopSettings);

    B2CEnabled.value = response?.B2CEnabled;
    B2CVK.value = response?.B2CVK;
    settingsExtended = response?.extendedShopSettings;
    Object.assign(extendedShopSettings, response?.extendedShopSettings);
  });

  const needInitialize = mergeDeep(settingsExtended, extendedShopSettings); //initialisierung des BE mit fehlenden Keys und defaultwerten.

  //extendedShopSettings.componentPositions=null;
  if (!extendedShopSettings?.componentPositions) {
    extendedShopSettings.componentPositions = [
      { index: 0, label: "Service Angaben", value: "serviceNotes" },
      { index: 1, label: "FAQ", value: "faqs" },
    ];
  }

  //initialisiering socialMediaLinks
  if (
    !extendedShopSettings.socialMediaLinks?.list ||
    extendedShopSettings.socialMediaLinks?.list.length == 0
  ) {
    extendedShopSettings.socialMediaLinks.list = [...socialMediaLinks];
  }

  //initialisiering Service-Angaben
  if (
    !extendedShopSettings.serviceNotes?.list ||
    extendedShopSettings.serviceNotes?.list.length == 0
  ) {
    extendedShopSettings.serviceNotes.list = [...serviceNotes];
  }

  //initialisierung faqs
  if (
    !extendedShopSettings.faqs?.list ||
    extendedShopSettings.faqs?.list.length == 0
  ) {
    extendedShopSettings.faqs.list = [...faqs];
  }
  // mergeDeep(extendedShopSettings.socialMediaLinks, socialMediaLinks); //initialisierung socialMediaLinks
  if (needInitialize?.proceedInitialize)
    await SettingsExtended.setSettingsExtended({
      extendedShopSettings: settingsExtended,
    }); // speichern auf BE
};

const mergeDeep = (target, source) => {
  let proceedInitialize = false;
  for (const key in source) {
    if (source.hasOwnProperty(key)) {
      // Wenn der Wert in target fehlt oder null/undefined ist, übernehmen wir den Wert aus source
      if (
        typeof source[key] === "object" &&
        source[key] !== null &&
        !Array.isArray(source[key])
      ) {
        // Stelle sicher, dass target[key] ein leeres Objekt ist, wenn es nicht existiert
        if (!target[key]) {
          target[key] = {}; // Wenn der Schlüssel in target nicht existiert, erstelle ein neues Objekt
          proceedInitialize = true; // Nur setzen, wenn der Schlüssel neu ist
        }

        mergeDeep(target[key], source[key]); // Rekursiver Aufruf für verschachtelte Objekte
      } else {
        // Überschreibe den Wert nur, wenn er nicht existiert (null oder undefined)
        if (target[key] === undefined || target[key] === null) {
          target[key] = source[key]; // Wenn der Schlüssel in target nicht existiert, füge ihn hinzu
          proceedInitialize = true; // Nur setzen, wenn der Schlüssel neu ist
        }
      }
    }
  }
  return { target, proceedInitialize };
};

onMounted(async () => {
/*   extendedShopSettings.imageCompare= {
    enabled:false,
    image1: null,
    image2: null,
    imageTitel1: "",
    imageTitel2: "",
  } */
  await werteLaden();
});
</script>
