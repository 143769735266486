<template>
  <Fieldset
    ref="generalRef"
    :toggleable="true"
    class="col-12"
    :collapsed="true"
    :pt="{
      legend: {
        class: '0 m-0 bg-white border-none',
        style: { left: '-25px!important', position: 'relative' },
      },
      togglerIcon: { class: '0 m-0 hidden' },
      toggleableContent: { class: '0 m-0 bg-gray-100' },
      toggler: { class: 'py-0 my-0' },
      root: { class: '0 my-0' },
    }">
    <template #legend>
      <div class="flex align-items-center ga0 px-0 py-1">
        <i
          class="text-base mx-3"
          :class="
            generalRef?.d_collapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up'
          "></i>
        <span class="text-base">Generelle Einstellungen</span>
      </div>
    </template>
    <div>
      <div class="flex justify-content-end pb-3">
        <Button
          label="Speichern"
          icon="pi pi-save text-xs"
          class="text-xs"
          outlined
          @click="saveForm" />
      </div>
      <div class="bg-blue-100">
        <i class="pi pi-info"></i>
        Derzeit Unterstüzt der Onlineshop B2B Kunden.

        <div
          v-if="extendedShopSettings.isOnlineShopActive"
          class="2 bg-blue-100">
          Die Shopseite ist über <br /><a
            :href="'https://shop.famtura.de/home/' + store.whoAmIData.slug"
            target="_blank"
            ><b>https://shop.famtura.de/home/{{ store.whoAmIData.slug }}</b></a
          >
          erreichbar.
          <br />
          staging:
          <a
            :href="
              'https://stagingcustomer.famtura.de/home/' + store.whoAmIData.slug
            "
            target="_blank"
            ><b
              >https://stagingcustomer.famtura.de/home/{{
                store.whoAmIData.slug
              }}</b
            ></a
          >
          <br />Denken Sie auch daran die Rechtstexte zu aktuallisieren.
        </div>
      </div>
      <Divider></Divider>
      <div class="grid pt-3">
        <div class="col-6">Onlineshop aktivieren:</div>
        <div class="col-6">
          <InputSwitch
            v-model="extendedShopSettings.isOnlineShopActive"
            @update:model-value="prepareOnlineShopActivation"
            :binary="true" />
        </div>
        <div class="col-6">B2C Aktivieren:</div>
        <div class="col-6">
          <InputSwitch v-model="B2CEnabled" :binary="true" />
        </div>
        <div class="col-6">B2C VK-Stufe:</div>
        <div class="col-6">
          <Dropdown
            :options="calcUtils.vkStufenLabel.value"
            v-model="B2CVK"
            optionLabel="optionLabel"
            optionValue="optionValue" />
        </div>
        <div class="col-6">
          selbständiges registrieren von B2B Kunden erlauben:
        </div>
        <div class="col-6">
          <InputSwitch v-model="extendedShopSettings.allowRegister" />
        </div>
        <div class="col-6">Produkte ohne Bestand ausblenden:</div>
        <div class="col-6">
          <InputSwitch v-model="extendedShopSettings.hideItemsWithoutStock" />
        </div>
        <div class="col-6">Neue Produkte in Hauptkategorie anzeigen:</div>
        <div class="col-6">
          <InputSwitch v-model="extendedShopSettings.showNewProducts" />
        </div>
        <div class="col-6">Produkte mit Rabatt in Hauptkategorie anzeigen:</div>
        <div class="col-6">
          <InputSwitch v-model="extendedShopSettings.showDiscountProduct" />
        </div>
        <div class="col-6">
          Produkte als Neu markieren,bis maximale Tage nach Erstellung:
        </div>
        <div class="col-6">
          <InputNumber
            inputClass="w-full"
            v-if="
              (extendedShopSettings.maxDaysLeftForNewProduct =
                extendedShopSettings.maxDaysLeftForNewProduct || 5)
            "
            v-model="extendedShopSettings.maxDaysLeftForNewProduct"
            :show-buttons="true" />
        </div>
        <template
          v-if="
            extendedShopSettings?.homeSlider?.length == 1 &&
            extendedShopSettings?.homeSlider[0].homeImages == null
          ">
          <div class="col-6">Titel-Text für die Homeseite:</div>
          <div class="col-6">
            <InputText
              v-if="extendedShopSettings?.homeSlider?.[0]"
              class="w-full"
              v-model="extendedShopSettings.homeSlider[0].homePageTitel"
              v-tooltip="'Empfohlen max 10 Zeichen'" />
          </div>
          <div class="col-6">Unter-Titel-Text für die Homeseite:</div>
          <div class="col-6">
            <InputText
              v-if="extendedShopSettings?.homeSlider?.length == 1"
              v-model="extendedShopSettings.homeSlider[0].homePageSubTitel"
              class="w-full"
              v-tooltip="'Empfohlen max 35 Zeichen'" />
          </div>
        </template>
      </div>
    </div>
  </Fieldset>
  <Fieldset
    ref="laufTextRef"
    :toggleable="true"
    class="col-12"
    :collapsed="true"
    :pt="{
      legend: {
        class: '0 m-0 bg-white border-none',
        style: { left: '-25px!important', position: 'relative' },
      },
      togglerIcon: { class: '0 m-0 hidden' },
      toggleableContent: { class: '0 m-0 bg-gray-100' },
      toggler: { class: 'py-0 my-0' },
      root: { class: '0 my-0' },
    }">
    <template #legend>
      <div class="flex align-items-center ga0 px-0 py-1">
        <i
          class="text-base mx-3"
          :class="
            laufTextRef?.d_collapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up'
          "></i>
        <span class="text-base">Komponente: Info Lauftext</span>
      </div>
    </template>
    <div class="col-6">Lauftext Nachricht für die Homeseite:</div>
    <div class="col-6">
      <InputText
        v-model="extendedShopSettings.laufText.message"
        class="w-full" />
    </div>
    <div class="col-6">Anzeige-Dauer in Sekunden, des Lauftextes:</div>
    <div class="col-6">
      <InputNumber
        v-model="extendedShopSettings.laufText.duration"
        :min="0"
        class="w-5/6" />
      <span class="pl-4">Hintergrund Farbe:</span
      ><ColorPicker v-model="extendedShopSettings.laufText.bgColor" />
    </div>
  </Fieldset>
  <Fieldset
    ref="homeBilderSliderRef"
    :toggleable="true"
    class="col-12"
    :collapsed="true"
    :pt="{
      legend: {
        class: '0 m-0 bg-white border-none',
        style: { left: '-25px!important', position: 'relative' },
      },
      togglerIcon: { class: '0 m-0 hidden' },
      toggleableContent: { class: '0 m-0 bg-gray-100' },
      toggler: { class: 'py-0 my-0' },
      root: { class: '0 my-0' },
    }">
    <template #legend>
      <div class="flex align-items-center ga0 px-0 py-1">
        <i
          class="text-base mx-3"
          :class="
            homeBilderSliderRef?.d_collapsed
              ? 'pi pi-chevron-down'
              : 'pi pi-chevron-up'
          "></i>
        <span class="text-base">Komponente: Hauptseite Bild Slider</span>
      </div>
    </template>
    <div class="grid col-12">
      <div class="col-6">Bild/Bilder für Homeseite:</div>
      <div class="col-6">
        <FileUpload
          mode="basic"
          name="homeImages"
          url="/api/upload"
          accept="image/*"
          class="text-xs"
          :maxFileSize="3000000"
          customUpload
          @uploader="customBase64Uploader($event, 'homeImages', false)"
          :auto="true"
          chooseLabel="Bild Hochladen" />
      </div>
    </div>
    <div
      v-if="
        extendedShopSettings?.homeSlider?.length > 0 &&
        extendedShopSettings?.homeSlider[0].homeImages != null
      ">
      <div
        v-for="(element, index) in extendedShopSettings.homeSlider"
        :key="index"
        class="grid grid-nogutter pb-3">
        <div class="grid grid-nogutter col-10 pl-1">
          <div class="col-12">
            <InputText
              v-model="element.homePageTitel"
              placeholder="Haupttitel"
              class="w-full" />
          </div>
          <div class="col-12">
            <InputText
              v-model="element.homePageSubTitel"
              placeholder="Untertitel"
              class="w-full" />
          </div>
        </div>
        <div class="col-2">
          <Image
            preview
            :src="element.homeImages || require('@/assets/no-image.svg')"
            alt="Image"
            imageClass="h-6rem object-contain"
            width="50" />
          <Button
            icon="pi pi-trash"
            rounded
            size="small"
            text
            severity="danger"
            @click="deleteSlider(index)" />
        </div>
      </div>
    </div>
    <div>
      <Button
        label="Speichern"
        size="small"
        class="text-xs"
        icon="pi pi-check"
        outlined
        @click="save()" />
    </div>
  </Fieldset>
  <Fieldset
    ref="itemSliderRef"
    :toggleable="true"
    class="col-12"
    :collapsed="true"
    :pt="{
      legend: {
        class: '0 m-0 bg-white border-none',
        style: { left: '-25px!important', position: 'relative' },
      },
      togglerIcon: { class: '0 m-0 hidden' },
      toggleableContent: { class: '0 m-0 bg-gray-100' },
      toggler: { class: 'py-0 my-0' },
      root: { class: '0 my-0' },
    }">
    <template #legend>
      <div class="flex align-items-center ga0 px-0 py-1">
        <i
          class="text-base mx-3"
          :class="
            itemSliderRef?.d_collapsed
              ? 'pi pi-chevron-down'
              : 'pi pi-chevron-up'
          "></i>
        <span class="text-base">Komponente: Artikel Slider</span>
      </div>
    </template>
    <div class="flex align-items-center w-full">
      <div>Artikel Slider verwenden?</div>
      <div class="pl-2 my-2">
        <InputSwitch
          :binary="true"
          v-model="extendedShopSettings.sliderItems.enabled"
          @change="save('sliderItems', 'Artikel Slider')" />
      </div>
    </div>
    <div class="col">Autoplay Intervall in Sek.</div>
    <div class="col">
      <InputNumber
        v-model="extendedShopSettings.sliderItems.ItemAutoplayInterval"
        inputClass="text-xs"
        @update:model-value="save()" />
    </div>
    <div class="col">Artikel für Slider auswählen:</div>
    <div class="col">
      <div class="flex">
        <div class="flex-grow-1">
          <ItemInputSearch
            v-model="selectedItem"
            inputClass="text-xs w-full"
            filterItemsWithImage
            secondOptionClass="text-xs"
            optionClass="text-sm"
            ref="itemInputSearchRef" />
        </div>
        <div class="hidden md:block">
          <Button
            size="small"
            icon="pi pi-plus"
            outlined
            label="Hinzufügen"
            @click="addItemsToSlider" />
        </div>
        <div class="md:hidden">
          <Button
            size="small"
            icon="pi pi-plus"
            v-tooltip="'Hinzufügen'"
            outlined
            @click="addItemsToSlider" />
        </div>
      </div>
    </div>
    <div class="col"></div>
    <div class="col">
      <div
        v-for="(item, index) in extendedShopSettings.sliderItems.list"
        :key="index"
        class="py-2">
        <div class="flex grid ga2 border-round border-solid">
          <div class="col-2">{{ item.EAN }}</div>
          <div class="col-6">{{ item.itemName }}</div>
          <div class="col-1">
            <Image
              :src="item.itemImageUrl"
              alt="Image"
              width="50"
              preview
              imageClass="max-w-4rem max-h-3rem object-contain" />
          </div>
          <div class="col-1">
            <Button
              severity="danger"
              text
              size="small"
              rounded
              icon="pi pi-trash"
              @click="deleteProductFromSlider(item)" />
          </div>
        </div>
      </div>
    </div>
  </Fieldset>
  <Fieldset
    ref="partnerLogoSliderRef"
    :toggleable="true"
    class="col-12"
    :collapsed="true"
    :pt="{
      legend: {
        class: '0 m-0 bg-white border-none',
        style: { left: '-25px!important', position: 'relative' },
      },
      togglerIcon: { class: '0 m-0 hidden' },
      toggleableContent: { class: '0 m-0 bg-gray-100' },
      toggler: { class: 'py-0 my-0' },
      root: { class: '0 my-0' },
    }">
    <template #legend>
      <div class="flex align-items-center ga0 px-0 py-1">
        <i
          class="text-base mx-3"
          :class="
            partnerLogoSliderRef?.d_collapsed
              ? 'pi pi-chevron-down'
              : 'pi pi-chevron-up'
          "></i>
        <span class="text-base">Komponente: Partner Logo Slider</span>
      </div>
    </template>
    <div class="flex align-items-center w-full">
      <div>Partner Logo Slider verwenden?</div>
      <div class="pl-2 my-2">
        <InputSwitch
          :binary="true"
          v-model="extendedShopSettings.partnerLogos.enabled"
          @change="save('partnerLogos', 'Partner Logo Slider')" />
      </div>
    </div>
    <div class="col">
      <LogoSlider @onConverted="handleConvertedImage" />
    </div>
    <div class="col"></div>
    <div class="col flex fluid">
      <div class="w-full" v-if="extendedShopSettings.partnerLogos.list.length">
        <Listbox
          :options="extendedShopSettings.partnerLogos.list"
          v-model="selected"
          optionLabel="name"
          class="w-full md:w-14rem"
          listStyle="max-height:250px">
          <template #option="slotProps">
            {{ slotProps.option.name }}
            <div class="flex align-items-center">
              <div>
                <img
                  :src="slotProps.option?.image"
                  :alt="slotProps.option?.name"
                  class="card shadow-2 h-4rem" />
              </div>
              <Button
                size="small"
                severity="danger"
                icon="pi pi-trash"
                text
                @click="deletePartnerlogo(slotProps.option)"></Button>
            </div>
          </template>
        </Listbox>

        <!--         <VirtualScroller
          :items="extendedShopSettings.partnerLogos.list"
          :itemSize="160"
          showLoader
          :numToleratedItems="10"
          autoSize
          :key="extendedShopSettings.partnerLogos.list.length"
          appendOnly
          class="border-1 surface-border border-round"
          style="max-height: 500px">
          <template v-slot:item="{ item }">
            <div class="grid">
              <div class="col-1">
                <Button
                  size="small"
                  severity="danger"
                  icon="pi pi-trash"
                  text
                  @click="deletePartnerlogo(item)"></Button>
              </div>
              <div class="col-11">
                <div>
                  {{ item.name }}
                </div>
                <div>
                  <img
                    :src="item?.image"
                    alt="Graustufenbild Partnerlogo"
                    class="card shadow-2 h-4rem" />
                </div>
              </div>
              <Divider />
            </div>
          </template>
        </VirtualScroller> -->
      </div>
      <div
        v-if="!extendedShopSettings.partnerLogos?.list?.length"
        class="text-red-300">
        Es wurden noch keine Logos hochgeladen
      </div>
    </div>
  </Fieldset>
  <Fieldset
    ref="imageCompareRef"
    :toggleable="true"
    class="col-12"
    :collapsed="false"
    :pt="{
      legend: {
        class: '0 m-0 bg-white border-none',
        style: { left: '-25px!important', position: 'relative' },
      },
      togglerIcon: { class: '0 m-0 hidden' },
      toggleableContent: { class: '0 m-0 bg-gray-100' },
      toggler: { class: 'py-0 my-0' },
      root: { class: '0 my-0' },
    }">
    <template #legend>
      <div class="flex align-items-center ga0 px-0 py-1">
        <i
          class="text-base mx-3"
          :class="
            imageCompareRef?.d_collapsed
              ? 'pi pi-chevron-down'
              : 'pi pi-chevron-up'
          "></i>
        <span class="text-base">Komponente: Bild Vergleich</span>
      </div>
    </template>
    <div class="col-12 grid">
      <div class="col-4">Bildvergleich auf Homeseite verwenden?</div>
      <div class="col-6">
        <InputSwitch
          v-model="extendedShopSettings.imageCompare.enabled"
          :binary="true"
          @change="save('imageCompare', 'Bild Vergleich')" />
      </div>
    </div>
    <div class="col-12 grid flex items-center">
      <div class="col-4">Titel/Text</div>
      <div class="col-8"><InputText
          v-model="extendedShopSettings.imageCompare.mainTitel"
          placeholder="optional Titel oder Text"
          class="w-full" /></div>
    </div>
    <div class="grid col-12">
      <div class="col-3">Bild 1</div>
      <div class="col-3 flex align-items-center">
        <span class="pr-2">Bezeichnung: </span
        ><InputText
          v-model="extendedShopSettings.imageCompare.imageTitel1"
          class="w-full" />
      </div>
      <div class="col-3"></div>
      <div class="col-3 flex">
        <Image
          v-if="extendedShopSettings?.imageCompare?.image1"
          :src="extendedShopSettings.imageCompare.image1"
          alt="Image"
          width="50"
          preview
          imageClass="max-w-4rem max-h-3rem object-contain" />
        <FileUpload
          mode="basic"
          name="homeImages"
          url="/api/upload"
          accept="image/*"
          class="text-xs"
          :maxFileSize="3000000"
          customUpload
          @uploader="customBase64Uploader($event, 'compareImage1', false)"
          :auto="true"
          chooseLabel="Bild 1 Hochladen" />
      </div>
      <div class="col-3">Bild 2</div>
      <div class="col-3 flex align-items-center">
        <span class="pr-2">Bezeichnung: </span
        ><InputText
          v-model="extendedShopSettings.imageCompare.imageTitel2"
          class="w-full" />
      </div>
      <div class="col-3"></div>
      <div class="col-3 flex">
        <Image
          v-if="extendedShopSettings?.imageCompare?.image2"
          :src="extendedShopSettings.imageCompare.image2"
          alt="Image"
          width="50"
          preview
          imageClass="max-w-4rem max-h-3rem object-contain" />
        <FileUpload
          mode="basic"
          name="homeImages"
          url="/api/upload"
          accept="image/*"
          class="text-xs"
          :maxFileSize="3000000"
          customUpload
          @uploader="customBase64Uploader($event, 'compareImage2', false)"
          :auto="true"
          chooseLabel="Bild 2 Hochladen" />
      </div>
    </div>
    <div
      v-if="
        !extendedShopSettings?.imageCompare?.image1 ||
        !extendedShopSettings?.imageCompare?.image2
      "
      class="text-red-300">
      <span>Fehler:</span>
      <ul>
        <li v-if="!extendedShopSettings?.imageCompare?.image1">
          <span>Bild 1 fehlt</span>
        </li>
        <li v-if="!extendedShopSettings?.imageCompare?.image2">
          <span>Bild 2 fehlt</span>
        </li>
      </ul>
    </div>
    <div
      v-if="
        extendedShopSettings?.homeSlider?.length > 0 &&
        extendedShopSettings?.homeSlider[0].homeImages != null
      ">
      <div
        v-for="(element, index) in extendedShopSettings.homeSlider"
        :key="index"
        class="grid grid-nogutter pb-3">
        <div class="grid grid-nogutter col-10 pl-1">
          <div class="col-12">
            <InputText
              v-model="element.homePageTitel"
              placeholder="Haupttitel"
              class="w-full" />
          </div>
          <div class="col-12">
            <InputText
              v-model="element.homePageSubTitel"
              placeholder="Untertitel"
              class="w-full" />
          </div>
        </div>
        <div class="col-2">
          <Image
            preview
            :src="element.homeImages || require('@/assets/no-image.svg')"
            alt="Image"
            imageClass="h-6rem object-contain"
            width="50" />
          <Button
            icon="pi pi-trash"
            rounded
            size="small"
            text
            severity="danger"
            @click="deleteSlider(index)" />
        </div>
      </div>
    </div>
    <div>
      <Button
        label="Speichern"
        size="small"
        class="text-xs"
        icon="pi pi-check"
        outlined
        @click="save()" />
    </div>
  </Fieldset>
  <Fieldset
    ref="socialMediaRed"
    :toggleable="true"
    class="col-12"
    :collapsed="true"
    :pt="{
      legend: {
        class: '0 m-0 bg-white border-none',
        style: { left: '-25px!important', position: 'relative' },
      },
      togglerIcon: { class: '0 m-0 hidden' },
      toggleableContent: { class: '0 m-0 bg-gray-100' },
      toggler: { class: 'py-0 my-0' },
      root: { class: '0 my-0' },
    }">
    <template #legend>
      <div class="flex align-items-center ga0 px-0 py-1">
        <i
          class="text-base mx-3"
          :class="
            socialMediaRed?.d_collapsed
              ? 'pi pi-chevron-down'
              : 'pi pi-chevron-up'
          "></i>
        <span class="text-base">Komponente: Social Media Follow Links</span>
      </div>
    </template>
    <div class="grid col-12">
      <div class="col-6 grid">
        <div class="col-6">Social Folgen Links auf Homeseite verwenden?</div>
        <div class="col-6">
          <InputSwitch
            v-model="extendedShopSettings.socialMediaLinks.enabled"
            :binary="true"
            @change="save('socialMediaLinks', 'Social Media Follow Links')" />
        </div>
      </div>
      <div class="col-5 grid flex align-items-center">
        <div class="col-6 lg:col-2 font-bold">Account</div>
        <div class="col-6">
          <InputText
            class="bg-blue-100 w-full"
            :class="
              extendedShopSettings.socialMediaLinks?.socialMediaAccount?.length
                ? 'font-bold'
                : ''
            "
            placeholder="Bsp. famtura"
            v-model="extendedShopSettings.socialMediaLinks.socialMediaAccount"
            size="small" />
        </div>
      </div>
      <div>
        <Button
          size="small"
          outlined
          label="Speichern"
          icon="pi pi-save text-xs"
          class="text-xs"
          @click="save()" />
      </div>
      <div
        v-for="sl in extendedShopSettings?.socialMediaLinks?.list"
        class="grid col-6">
        <div class="col-2">
          <span
            v-if="sl && sl.svgIcon"
            v-html="sl.svgIcon"
            :style="{
              width: sl.fontSize,
              height: sl.fontSize,
              display: 'inline-block',
              strokeWidth: sl.strokeWidth,
              stroke: sl.strokeColor,
            }">
          </span>
          <i v-else :class="'text-4xl ' + sl.icon"></i> {{ sl.platform }}
        </div>
        <div class="col">
          <Button
            text
            :label="
              sl.baseLink +
              (extendedShopSettings.socialMediaLinks?.socialMediaAccount?.length
                ? extendedShopSettings.socialMediaLinks.socialMediaAccount
                : '')
            "
            :class="
              extendedShopSettings.socialMediaLinks?.socialMediaAccount?.length
                ? ''
                : 'text-red-200'
            "
            @click="
              sl.link =
                sl.baseLink +
                (extendedShopSettings.socialMediaLinks?.socialMediaAccount
                  ?.length
                  ? extendedShopSettings.socialMediaLinks.socialMediaAccount
                  : '')
            "
            size="small"
            class="p-1 m-0 text-blue-300" />
          <InputText
            class="w-full"
            v-model="sl.link"
            :class="sl.link?.length > 0 ? 'font-semibold' : ''"
            placeholder="Kein Eintrag" />
        </div>
      </div>
    </div>
  </Fieldset>
  <Fieldset
    ref="serviceNotesRef"
    :toggleable="true"
    class="col-12"
    :collapsed="true"
    :pt="{
      legend: {
        class: '0 m-0 bg-white border-none',
        style: { left: '-25px!important', position: 'relative' },
      },
      togglerIcon: { class: '0 m-0 hidden' },
      toggleableContent: { class: '0 m-0 bg-gray-100' },
      toggler: { class: 'py-0 my-0' },
      root: { class: '0 my-0' },
    }">
    <template #legend>
      <div class="flex align-items-center ga0 px-0 py-1">
        <i
          class="text-base mx-3"
          :class="
            serviceNotesRef?.d_collapsed
              ? 'pi pi-chevron-down'
              : 'pi pi-chevron-up'
          "></i>
        <span class="text-base">Komponente: Service Angaben</span>
      </div>
    </template>
    <div class="flex align-items-center w-full">
      <div>Service-Angaben verwenden?</div>
      <div class="pl-2 my-2">
        <InputSwitch
          :binary="true"
          v-model="extendedShopSettings.serviceNotes.enabled"
          @change="save('serviceNotes', 'Service Angaben')" />
      </div>
    </div>
    <div class="w-full">
      <DataTable
        v-if="extendedShopSettings?.serviceNotes?.list?.length > 0"
        :value="extendedShopSettings.serviceNotes.list"
        editMode="cell"
        size="small"
        dataKey="id"
        @cell-edit-complete="onCellEditSave($event, 'serviceNotes')"
        :pt="{
          table: { style: 'min-width: 10rem' },
          column: {
            bodycell: ({ state }) => ({
              style:
                state['d_editing'] &&
                'padding-top: 0.75rem; padding-bottom: 0.75rem',
            }),
          },
        }">
        <Column field="isIcon" header="Symbol">
          <template #body="{ data }">
            <i
              v-if="data?.icon"
              :class="data.icon"
              class="text-3xl text-blue-500"></i>
            <span v-if="data?.svg" v-html="data.svg" class="flex"> </span>
          </template>
          <template #editor="{ data, field, index }">
            <div class="grid col-12">
              <div class="col-12">
                <i
                  v-if="data?.icon"
                  :class="data.icon"
                  class="text-3xl text-blue-500"></i>
                <span v-if="data?.svg" v-html="data.svg" class="flex"> </span>
              </div>
              <div class="col-12">
                <SelectButton
                  v-model="data.isIcon"
                  :allowEmpty="false"
                  optionValue="value"
                  optionLabel="label"
                  :options="isIconOptions"
                  @change="save()"
                  aria-labelledby="basic"
                  :pt="{ button: { class: 'p-2 m-0' } }">
                  <template #option="slotProps">
                    <span class="text-xs">{{ slotProps.option.label }}</span>
                  </template>
                </SelectButton>
              </div>
              <div v-if="data.isIcon">
                <Button
                  size="small"
                  outlined
                  label="Icon Auswahl"
                  class="p-1 m-0 text-xs"
                  @click="openIconPicker($event, data)" />
              </div>
              <div v-if="!data.isIcon">
                <FileUpload
                  mode="basic"
                  accept=".svg"
                  class="text-xs"
                  customUpload
                  :maxFileSize="500000"
                  :multiple="false"
                  :showUploadButton="false"
                  :showCancelButton="false"
                  @select="onSvgUpload($event, data)"
                  :auto="true"
                  chooseLabel="SVG Hochladen" />
              </div></div
          ></template>
        </Column>

        <Column field="title" header="Titel">
          <template #body="{ data, field }">
            {{ data[field] }}
          </template>
          <template #editor="{ data, field }">
            <InputText v-model="data[field]" class="text-xs w-full" />
          </template>
        </Column>
        <Column field="description" header="Beschreibung">
          <template #body="{ data, field }">
            {{ data[field] }}
          </template>
          <template #editor="{ data, field }">
            <InputText v-model="data[field]" class="text-xs w-full" />
          </template>
        </Column>

        <Column field="enabled" header="Öffentlich">
          <template #editor="{ data, field }">
            <Checkbox v-model="data[field]" :binary="true" />
          </template>
          <template #body="{ data, field }">
            <i
              :class="['pi', data[field] ? 'pi-check' : 'pi-times']"
              style="font-size: 1.2rem"></i>
          </template>
        </Column>

        <Column
          header="Aktionen"
          :rowEditor="true"
          style="width: 1%; min-width: 2rem"
          bodyStyle="text-align:center">
        </Column>
        <Column
          style="width: 1%; min-width: 2rem"
          bodyStyle="text-align:center">
          <template #body="rowData">
            <Button
              v-tooltip="'Löschen'"
              text
              icon="pi pi-trash"
              severity="danger"
              @click="removeRow(rowData, 'serviceNotes')" />
          </template>
        </Column>
        <template #footer>
          <div
            class="flex flex-fluid justify-content-between align-items-center ga4">
            <div class="flex align-items-center">
              <span>Darstellung</span>
              <span class="pl-2">
                <SelectButton
                  v-model="extendedShopSettings.serviceNotes.layout"
                  :allowEmpty="false"
                  optionValue="value"
                  optionLabel="label"
                  :options="layout"
                  @change="save()"
                  aria-labelledby="basic">
                  <template #option="slotProps">
                    <span class="text-xs">{{ slotProps.option.label }}</span>
                  </template>
                </SelectButton>
              </span>
            </div>
            <div>
              <Button
                label="Neue Service-Angabe hinzufügen"
                class="text-xs"
                icon="pi pi-plus text-xs"
                @click="addRow($event, 'serviceNotes')" />
            </div>
          </div>
        </template>
      </DataTable>
    </div>
    <div>
      <div><h2>Vorschau</h2></div>
      <div
        class="p-card"
        :class="
          extendedShopSettings?.serviceNotes.layout === 'horizontal'
            ? 'grid col-11 gap-4'
            : 'grid w-25rem gap-4'
        ">
        <!-- Feature Cards -->
        <template
          v-for="sn in extendedShopSettings?.serviceNotes.list"
          :key="sn.title">
          <div
            v-if="sn.enabled"
            class="col-12 md:col-6 lg:col-4 h-12rem w-25rem rounded-lg p-2 text-center">
            <i
              v-if="sn?.icon"
              :class="sn.icon"
              class="mb-4 text-3xl text-blue-500"></i>
            <span
              v-if="sn?.svg"
              v-html="sn.svg"
              class="mb-4 flex justify-content-center">
            </span>
            <h3 class="mb-2 text-lg font-bold">
              <span>{{ sn.title }}</span>
            </h3>
            <p class="text-sm text-gray-600">{{ sn.description }}</p>
          </div>
        </template>
      </div>
    </div>
  </Fieldset>
  <Fieldset
    ref="faqRef"
    :toggleable="true"
    class="col-12"
    :collapsed="true"
    :pt="{
      legend: {
        class: '0 m-0 bg-white border-none',
        style: { left: '-25px!important', position: 'relative' },
      },
      togglerIcon: { class: '0 m-0 hidden' },
      toggleableContent: { class: '0 m-0 bg-gray-100' },
      toggler: { class: 'py-0 my-0' },
      root: { class: '0 my-0' },
    }">
    <template #legend>
      <div class="flex align-items-center ga0 px-0 py-1">
        <i
          class="text-base mx-3"
          :class="
            faqRef?.d_collapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up'
          "></i>
        <span class="text-base">Komponente: Häufig gestellte Fragen (FAQ)</span>
      </div>
    </template>
    <div class="flex align-items-center w-full">
      <div>FAQ verwenden?</div>
      <div class="pl-2 my-2">
        <InputSwitch
          :binary="true"
          v-model="extendedShopSettings.faqs.enabled"
          @change="save('faqs', 'FAQ')" />
      </div>
    </div>
    <div class="w-full">
      <DataTable
        v-if="extendedShopSettings?.faqs?.list?.length > 0"
        :value="extendedShopSettings.faqs.list"
        editMode="cell"
        size="small"
        dataKey="id"
        @cell-edit-complete="onCellEditSave($event, 'faqs')"
        :pt="{
          table: { style: 'min-width: 10rem' },
          column: {
            bodycell: ({ state }) => ({
              style:
                state['d_editing'] &&
                'padding-top: 0.75rem; padding-bottom: 0.75rem',
            }),
          },
        }">
        <Column field="question" header="Frage">
          <template #body="{ data, field }">
            {{ data[field] }}
          </template>
          <template #editor="{ data, field }">
            <InputText v-model="data[field]" class="text-xs w-full" />
          </template>
        </Column>
        <Column field="answer" header="Antwort">
          <template #body="{ data, field }">
            {{ data[field] }}
          </template>
          <template #editor="{ data, field }">
            <InputText v-model="data[field]" class="text-xs w-full" />
          </template>
        </Column>

        <Column
          header="Aktionen"
          :rowEditor="true"
          style="width: 1%; min-width: 2rem"
          bodyStyle="text-align:center">
        </Column>
        <Column
          style="width: 1%; min-width: 2rem"
          bodyStyle="text-align:center">
          <template #body="rowData">
            <Button
              v-tooltip="'Löschen'"
              text
              icon="pi pi-trash"
              severity="danger"
              @click="removeRow(rowData, 'faqs')" />
          </template>
        </Column>
        <template #footer>
          <div
            class="flex flex-fluid justify-content-between align-items-center ga4">
            <div class="flex align-items-center"></div>
            <div>
              <Button
                label="Neue FAQ hinzufügen"
                class="text-xs"
                icon="pi pi-plus text-xs"
                @click="addRow($event, 'faqs')" />
            </div>
          </div>
        </template>
      </DataTable>
    </div>
    <div v-if="extendedShopSettings.faqs?.list">
      <div><h2>Vorschau</h2></div>
      <div
        class="surface-card p-6 shadow-2 border-round mx-auto"
        style="max-width: 56rem">
        <h1 class="text-2xl font-bold text-center mb-6">
          Häufig gestellte Fragen (FAQ)
        </h1>
        <Accordion :activeIndex="0">
          <AccordionTab
            v-for="(faq, index) in extendedShopSettings.faqs.list"
            :key="index"
            :header="faq.question">
            <p class="m-0">{{ faq.answer }}</p>
          </AccordionTab>
        </Accordion>
      </div>
    </div>
  </Fieldset>
  <Fieldset
    ref="customContentRef"
    :toggleable="true"
    class="col-12"
    :collapsed="true"
    :pt="{
      legend: {
        class: '0 m-0 bg-white border-none',
        style: { left: '-25px!important', position: 'relative' },
      },
      togglerIcon: { class: '0 m-0 hidden' },
      toggleableContent: { class: '0 m-0 bg-gray-100' },
      toggler: { class: 'py-0 my-0' },
      root: { class: '0 my-0' },
    }">
    <template #legend>
      <div class="flex align-items-center px-0 py-1">
        <i
          class="text-base mx-3"
          :class="
            customContentRef?.d_collapsed
              ? 'pi pi-chevron-down'
              : 'pi pi-chevron-up'
          "></i>
        <span class="text-base">Content Inhalte</span>
      </div>
    </template>
    <div>
      <div class="flex align-items-center gap-2">
        <Button
          label="Neuen Inhalt Hinzufügen"
          size="small"
          icon="pi pi-plus"
          class="text-xs my-4"
          @click="openContentEditorDialog(null)" />

        <div class="ml-4 flex">
          <span
            v-if="extendedShopSettings.contentMenu?.length > 0"
            class="pr-2 font-light text-xs"
            >Menü</span
          >
          <div v-for="menuItem in extendedShopSettings.contentMenu">
            <span
              class="font-medium shadow-2 border-round-sm p-1"
              v-tooltip="'=> Aufruf: ' + menuItem.menuLabel"
              >{{ menuItem.label }}</span
            >
          </div>
        </div>
      </div>

      <div class="gap-3 flex flex-wrap">
        <div
          v-for="ad in extendedShopSettings.contentAds"
          :key="ad.id"
          :class="hoveredAdId == ad.id ? 'shadow-4' : ''"
          class="flex relative overflow-hidden w-15rem shadow-2 border-round-xl bg-white">
          <div class="text-sm bg-green-100 w-full absolute p-2">
            {{ ad.label }}
          </div>
          <!-- Mini-Vorschau -->
          <iframe
            class="preview-frame"
            :srcdoc="ad.content"
            sandbox="allow-same-origin allow-scripts allow-popups"></iframe>

          <!-- Bearbeiten-Button -->
          <Button
            icon="pi pi-pencil text-xs p-0 m-0"
            class="py-1 m-1 absolute z-10 top-0 right-0"
            :outlined="hoveredAdId !== ad.id"
            @click="openContentEditorDialog(ad)"
            @mouseover="hoveredAdId = ad.id"
            @mouseleave="hoveredAdId = null" />
          <Button
            icon="pi pi-trash text-xs p-0 m-0"
            severity="danger"
            outlined
            class="py-1 m-1 absolute z-10 bottom-0 right-0"
            @click="confirmRemoveContent(ad)" />
        </div>
      </div>
      <!--     <Button label="Neuen Inhalt Hinzufügen" icon="pi pi-plus" class="my-4"/>
    </div>
    <div class="flex gap-4">
    <div class="h-15rem w-15rem  bg-white border-round-xl shadow-2">
      
    </div>
    <div class="h-15rem w-15rem  bg-white border-round-xl shadow-2"></div>
  </div>
    <div class="col flex fluid">
      <div class="w-full"></div> -->
    </div>
  </Fieldset>
  <Fieldset
    ref="componentPositionRef"
    :toggleable="true"
    class="col-12"
    :collapsed="true"
    :pt="{
      legend: {
        class: '0 m-0 bg-white border-none',
        style: { left: '-25px!important', position: 'relative' },
      },
      togglerIcon: { class: '0 m-0 hidden' },
      toggleableContent: { class: '0 m-0 bg-gray-100' },
      toggler: { class: 'py-0 my-0' },
      root: { class: '0 my-0' },
    }">
    <template #legend>
      <div class="flex align-items-center ga0 px-0 py-1">
        <i
          class="text-base mx-3"
          :class="
            componentPositionRef?.d_collapsed
              ? 'pi pi-chevron-down'
              : 'pi pi-chevron-up'
          "></i>
        <span class="text-base">Komponenten Reihenfolge</span>
      </div>
    </template>
    <div class="p-4">
      <Panel header="Sortiere die Komponenten per Drag & Drop">
        <div class="p-2">
          <div
            v-for="(item, index) in extendedShopSettings.componentPositions"
            :key="item.index"
            draggable="true"
            @dragstart="onDragStart($event, index)"
            @dragover="onDragOver($event, index)"
            @drop="onDrop($event, index)"
            @dragend="onDragEnd"
            class="drag-item mb-2 shadow-md border-round-lg border-dashed"
            :class="{
              'drag-placeholder':
                placeholderIndex === index && draggedItem !== null,
            }">
            <div class="p-card p-2 flex text-lg font-bold gap-2">
              <div class="flex">
                <div class="pr-2">[{{ item.index }}]</div>
                <div>{{ item.label }}</div>
              </div>
            </div>
          </div>
        </div>
      </Panel>
    </div>
  </Fieldset>
</template>
<script setup>
import { inject, ref, reactive, onMounted, defineAsyncComponent } from "vue";
import * as calcUtils from "@/utils/calculationUtils.js";
import { userStore } from "@/store/userStore";
import { storeToRefs } from "pinia";
import { SettingsExtended } from "@/service/SettingsExtended";
import ItemInputSearch from "@/components/ItemInputSearch.vue";
import LogoSlider from "@/components/LogoSlider.vue";
import fAxios from "@/store/faxiosInterface";
import { useDialog } from "primevue/usedialog";
import iAxios from "@/store/axiosInterface";
import { useToast } from "primevue/usetoast";
import { compareAsc } from "date-fns";
import Divider from "primevue/divider";
import { v4 as uuidv4 } from "uuid";
import { useConfirm } from "primevue/useconfirm";

const ContentEditorDialog = defineAsyncComponent(() =>
  import("@/components/ContentEditorDialog.vue")
);
const IconPicker = defineAsyncComponent(() =>
  import("@/components/IconPicker.vue")
);

const selected = ref();
const store = userStore();
const toast = useToast();
const dialog = useDialog();
const confirm = useConfirm();
const settingsExtended = reactive({});
const selectedItem = ref(null);
const extendedShopSettings = inject("extendedShopSettings");
const B2CEnabled = inject("B2CEnabled");
const B2CVK = inject("B2CVK");
const itemSliderRef = ref();
const partnerLogoSliderRef = ref();
const customContentRef = ref();
const socialMediaRed = ref();
const componentPositionRef = ref();
const faqRef = ref();
const serviceNotesRef = ref();
const homeBilderSliderRef = ref();
const imageCompareRef = ref();
const generalRef = ref();
const laufTextRef = ref();
const hoveredAdId = ref();
const deleteSlider = (index) => {
  if (extendedShopSettings.homeSlider.length == 1) {
    extendedShopSettings.homeSlider[0].homeImages = null;
  } else {
    extendedShopSettings.homeSlider.splice(index, 1);
  }
};

const deleteProductFromSlider = (item) => {
  const index = extendedShopSettings.sliderItems.list.indexOf(item);
  if (index > -1) {
    extendedShopSettings.sliderItems.list.splice(index, 1);
  }
};

const addItemsToSlider = async () => {
  const sliderItems = extendedShopSettings.sliderItems.list;

  const itemListShema = {
    item_id_company: 0,
    EAN: "",
    itemImageUrl: 1,
    itemName: "",
  };
  const newItem = JSON.parse(JSON.stringify(itemListShema));
  if (selectedItem.value && typeof selectedItem === "object") {
    if (!selectedItem.value.itemImageUrl[0]) {
      toast.add({
        severity: "error",
        summary: "Error",
        detail: "Produkt hat kein Bild",
        life: 3000,
      });
      return;
    }

    newItem.itemName = selectedItem.value.itemName;
    newItem.item_id_company = selectedItem.value.item_id_company;
    newItem.itemImageUrl = selectedItem.value.itemImageUrl[0].fileUrl.replace(
      "/auth",
      ""
    );
    newItem.EAN = selectedItem.value.EAN;
    //wenn item bereits vorhanden, dann entfernen
    const index = sliderItems.findIndex(
      (item) =>
        item.itemName === newItem.itemName &&
        item.item_id_company === newItem.item_id_company &&
        item.itemImageUrl === newItem.itemImageUrl &&
        item.EAN === newItem.EAN
    );
    if (index !== -1) {
      sliderItems.splice(index, 1);
    }
    //neues item hinzufügen
    sliderItems.push(newItem);
    selectedItem.value = null;
    toast.add({
      severity: "success",
      summary: "Success",
      detail: "Produkt hinzugefügt",
      life: 3000,
    });
    save();
  } else {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: "Bitte wählen Sie ein Produkt aus",
      life: 3000,
    });
    return;
  }
};

const customBase64Uploader = async (event, type, removebg) => {
  console.log("customBase64Uploader", type);
  const isHomeImage = type === "homeImages";
  const isCompareImage1 = type === "compareImage1";
  const isCompareImage2 = type === "compareImage2";
  const file = event.files[0];
  //const file2 = event.files[0];
  const reader = new FileReader();
  reader.onloadend = async function () {
    const base64Data = reader.result;

    const fileData = new FormData();
    //const fileData2 = new extendedShopSettings();
    fileData.append("filedata", file);
    //fileData2.append('filedata', file);
    if (removebg == "removebg_stamp") fileData.append(removebg, true);
    if (removebg == "removebg_signature") fileData.append(removebg, true);
    try {
      const response = await fAxios.post("/company/" + type, fileData);
      let url2 =
        "https://storage.famtura.de/api/auth/files/upload/" +
        store.apiKeyStorage +
        "/" +
        store.cid;
      if (store.debug)
        console.log("🚀 ~ file: DocumentSettings.vue:3259 ~ url2:", url2);

      const response2 = await fAxios.post(url2, fileData);

      const parts = await response2.data.fileUrl.split("files/");
      const resultURL = parts[1];
      const publicUrl = "https://storage.famtura.de/api/files/" + resultURL;
      if (store.debug)
        console.log(
          "🚀 ~ file: DocumentSettings.vue:1695 ~ publicUrl:",
          publicUrl
        );

      if (isCompareImage1) {
        extendedShopSettings.imageCompare.image1 = publicUrl;
      }
      if (isCompareImage2) {
        extendedShopSettings.imageCompare.image2 = publicUrl;
      }

      if (isHomeImage) {
        if (
          extendedShopSettings?.homeSlider &&
          extendedShopSettings?.homeSlider?.length > 0 &&
          extendedShopSettings?.homeSlider?.[0].homeImages != null
        ) {
          extendedShopSettings.homeSlider.push({
            homeImages: publicUrl,
            homePageTitel: "",
            homePageSubTitel: "",
          });
        } else {
          extendedShopSettings.homeSlider[0].homeImages = publicUrl;
        }
      }

      onUpload();
      /*       setTimeout(async () => {
        await loadImage();

      }, 100); */

      // Wenn du weitere Aktionen mit der Antwort durchführen möchtest, füge den Code hier hinzu.
    } catch (error) {
      console.error(error);
      //errorToast(toast, error);
    }

    //   werteLaden(); // Dies sollte außerhalb des try-catch-Blocks sein, wenn es nach dem Upload ausgeführt werden soll.
  };

  // Lade die Blob-Daten und starte den Konvertierungsprozess
  let blob = await fetch(file.objectURL).then((r) => r.blob());
  reader.readAsDataURL(blob);
  setTimeout(async () => {
    console.log("🚀 ~ extendedShopSettings:", extendedShopSettings);
    save();
  }, 300);
};

const onUpload = () => {
  toast.add({
    severity: "info",
    summary: "Success",
    detail: "Bild hochgeladen",
    life: 3000,
  });
};

const save = (komponente, kLabel, mode, uuid) => {
  console.log("🚀 ~ uuid:", uuid);

  console.log("🚀 ~ komponente:", komponente);
  const remove = (mode != "add" && mode == "remove") || mode == null;

  if (komponente) {
    //komponente entfernen oder hinzufügen
    const pos = extendedShopSettings.componentPositions.findIndex(
      (item) => item.value === komponente && item.uuid === uuid
    );

    if (pos > -1 && remove) {
      // Element entfernen
      extendedShopSettings.componentPositions.splice(pos, 1);

      // Indexe der verbleibenden Elemente neu setzen
      extendedShopSettings.componentPositions.forEach((item, idx) => {
        item.index = idx + 1; // Neu durchnummerieren (1-basiert)
      });
    } else {
      // Neues Element hinzufügen
      extendedShopSettings.componentPositions.push({
        index: extendedShopSettings.componentPositions.length + 1,
        label: kLabel,
        value: komponente,
        uuid: uuid,
      });
    }
  }

  // speichern
  SettingsExtended.setSettingsExtended({ extendedShopSettings }).then(() => {
    toast.add({
      severity: "success",
      summary: "Speichern",
      detail: "Daten geändert und gespeichert",
      life: 2000,
    });
  });
};

const handleConvertedImage = (event) => {
  if (!extendedShopSettings?.partnerLogos?.list) {
    extendedShopSettings.partnerLogos = { enabled: false, list: [] };
  }
  extendedShopSettings.partnerLogos.list.push({
    name: event.name,
    image: event.image,
  });
  save();
  console.log(extendedShopSettings.partnerLogos);
};

const deletePartnerlogo = (data) => {
  const index = extendedShopSettings.partnerLogos.list.indexOf(data);
  if (index > -1) {
    extendedShopSettings.partnerLogos.list.splice(index, 1);
  }
  save();
};

const prepareOnlineShopActivation = () => {
  if (extendedShopSettings.isOnlineShopActive == true) {
    checkOnlineShopInitialData();
  }
};

const checkOnlineShopInitialData = async () => {
  //wenn shippingKinds vorhanden ist sollte es bereits initialisiert sein
  await SettingsExtended.getSettingsExtended().then(async (response) => {
    if (response?.extendedShopSettings?.shippingKinds?.length > 0) {
      return;
    } else {
      save();
    }
  });
  console.log(
    "🚀 ~ file: DocumentSettings.vue:3504 ~ extendedShopSettings:",
    extendedShopSettings
  );
};

const saveForm = async () => {
  await SettingsExtended.setSettingsExtended({
    extendedShopSettings: extendedShopSettings,
    B2CEnabled: B2CEnabled.value,
    B2CVK: B2CVK.value,
  })
    .then(async (res) => {
      setTimeout(() => {
        toast.add({
          severity: "success",
          summary: "Success",
          detail: "Daten gespeichert",
          life: 3000,
        });
      }, 100);
      // console.log(res);
    })
    .catch((err) => {
      console.log(err);
    })
    .then(() => {});
};

const layout = ref([
  { label: "Vertikal", value: "vertical" },
  { label: "Horizontale", value: "horizontal" },
]);
const isIconOptions = ref([
  { label: "Icon", value: true },
  { label: "SVG", value: false },
]);

const onCellEditSave = async (event, key) => {
  const { newData, index } = event;
  extendedShopSettings[key][index] = newData;
  save();
};

const removeRow = (rowData, key) => {
  const index = rowData.index;
  extendedShopSettings[key].list.splice(index, 1);
  save();
};

const addRow = (event, key) => {
  let newEntry = {
    enabled: false,
    isIcon: true,
    svg: null,
    icon: "pi pi-tags",
    title: "Titel",
    description: "Beschreibung",
  };
  if (key == "faqs")
    newEntry = {
      answer: "Frage",
      question: "Antwort",
    };
  extendedShopSettings[key].list.push(newEntry);
  save();
};

const onSvgUpload = (event, rowData) => {
  const file = event.files[0];

  if (!file || !file.name.endsWith(".svg")) {
    console.error("Nur SVG-Dateien sind erlaubt!");
    return;
  }

  const reader = new FileReader();
  reader.onload = (e) => {
    rowData.svg = e.target.result; // Richtig: Speichert SVG in das Objekt
    console.log("SVG-Inhalt:", rowData.svg);
  };
  reader.readAsText(file);
};

const openIconPicker = (event, rowData) => {
  dialog.open(IconPicker, {
    props: {
      closable: true,
      header: "Icon Auswahl",
      modal: true,
    },
    data: rowData,
    onClose: (urlJsonArray) => {
      if (urlJsonArray.data) {
        const itemWithUrls = ref({});
        itemWithUrls.item_id_company = props.item.item_id_company;
        itemWithUrls.itemImageUrl = urlJsonArray.data;
        saveUrls(itemWithUrls, true);
      }
    },
  });
};

//drag & drop positions
const draggedItem = ref(null);
const placeholderIndex = ref(null);

const onDragStart = (event, index) => {
  draggedItem.value = index;
  placeholderIndex.value = index;
  event.dataTransfer.effectAllowed = "move";
};

const onDragOver = (event, index) => {
  event.preventDefault();
  if (draggedItem.value !== null) {
    placeholderIndex.value = index;
  }
};

const onDrop = (event, index) => {
  event.preventDefault();

  if (draggedItem.value === null) return;

  const movedItem = extendedShopSettings.componentPositions.splice(
    draggedItem.value,
    1
  )[0];
  extendedShopSettings.componentPositions.splice(index, 0, movedItem);

  // Indexe neu setzen
  extendedShopSettings.componentPositions.forEach((item, idx) => {
    item.index = idx;
  });
  save();
  draggedItem.value = null;
  placeholderIndex.value = null;
};

const onDragEnd = () => {
  draggedItem.value = null;
  placeholderIndex.value = null;
};

//content-ads
const openContentEditorDialog = (ad) => {
  let activeState = false;
  let isHomeContentState = false;
  let isMenuContentState = false;
  let oldAd = {};
  const BspContent =
    '<iframe height="300" width= "400" src="https://www.tiktok.com/player/v1/7305578650542329093?&music_info=1&description=1" allow="fullscreen" title="test"></iframe>';
  const BspContent2 =
    '<iframe height="300" width= "400" src="https://www.tiktok.com/player/v1/6718335390845095173?&music_info=1&description=1" allow="fullscreen" title="test"></iframe>';
  if (!ad) {
    ad = {};
    (ad.id = uuidv4()), (ad.inHomeContent = true), (ad.menuLabel = "");
    ad.label =
      "Neuer Content " +
      (extendedShopSettings.contentAds?.length
        ? extendedShopSettings.contentAds?.length + 1
        : 1);
    ad.content = BspContent;
    ad.menuPosition = 0;
    ad.isNew = true;
    ad.enabled = true;
    ad.isHomeContent = true;
    ad.isMenuContent = false;
    ad.menuLabel = "";
    ad.menuPlace = "header";
    ad.mainMenuLabel = "";
  } else {
    activeState = ad.enabled == true;
    isHomeContentState = ad.isHomeContent == true;
    isMenuContentState = ad.isMenuContent == true;
    oldAd = JSON.parse(JSON.stringify(ad));
  }

  const dialogRef = dialog.open(ContentEditorDialog, {
    data: { ad },
    props: {
      header: "Inhalt bearbeiten",
      modal: true,
      closable: true,
      style: {
        width: "45vw",
      },
    },
    onClose: (event) => {
      console.log("🚀 ~ event:", event);
      if (event.data) {
        if (ad.isNew) {
          extendedShopSettings.contentAds.push(ad);
        }
        if (ad.enabled) {
        }
        const mode = selectAction(
          ad.isNew,
          ad.enabled,
          activeState == ad.enabled && isHomeContentState == ad.isHomeContent
        );
        delete ad.isNew;

        console.log("🚀 ~ modus:", mode);
        if (isMenuContentState != ad.isMenuContent) addRemoveContentMenu(ad);
        editMenuLabel(oldAd, ad);
        if (mode == "save") {
          save();
        } else save("contentAds", ad.label, mode, ad.id);
        sortContentMenu();
      }
    },
  });
};

const selectAction = (neu, aktiv, sameState, isHomeContent) => {
  if (sameState || (neu && !aktiv)) return "save";
  if (neu && aktiv && isHomeContent) return "add";
  if (!neu && aktiv && isHomeContent) return "add";
  return "remove";
};

const editMenuLabel = (oldAd, ad) => {
  const index = extendedShopSettings.contentMenu.findIndex(
    (item) => item.id === oldAd.id
  );
  if (index > -1) {
    Object.assign(extendedShopSettings.contentMenu[index], ad);
  }
};

//fehlt edit ContentMenu
const addRemoveContentMenu = (ad, remove) => {
  if (ad.isMenuContent && !remove) {
    extendedShopSettings.contentMenu.push(ad);
  } else {
    const index = extendedShopSettings.contentMenu.findIndex(
      (item) => item.id === ad.id
    );
    // const index = extendedShopSettings.contentMenu.indexOf(ad);
    if (index > -1) {
      extendedShopSettings.contentMenu.splice(index, 1);
    }
  }
};

//Anhand der Menüposition sortieren
const sortContentMenu = () => {
  if (extendedShopSettings.contentMenu?.length > 0) {
    extendedShopSettings.contentMenu = extendedShopSettings.contentMenu.sort(
      (a, b) => a.menuPosition - b.menuPosition
    );
  }
};

const confirmRemoveContent = (ad) => {
  confirm.require({
    message: "Bestätigen Sie den Inhalt unwiederuflich zu löschen?",
    header: "Löschen bestätigen",
    icon: "pi pi-info-circle",
    acceptClass: "p-button-danger",
    acceptLabel: "Ja",
    rejectLabel: "Nein",
    defaultFocus: "reject",
    accept: () => {
      const index = extendedShopSettings.contentAds.indexOf(ad);
      if (index > -1) {
        extendedShopSettings.contentAds.splice(index, 1);
        addRemoveContentMenu(ad, true);
        sortContentMenu();
        save("contentAds", ad.label, "remove", ad.id);
        toast.add({
          severity: "warn",
          summary: "Löschen",
          detail: "Erfolgreich gelöscht",
          life: 3000,
        });
      }
    },
    reject: () => {
      toast.add({
        severity: "warn",
        summary: "Abbruch",
        detail: "Löschen wurde abgebrochen",
        life: 3000,
      });
    },
  });
};

onMounted(() => {
  // wenn laufText kein Object ist dann setze es auf ein Object
  //extendedShopSettings.contentMenu=[]; save();
  if (typeof extendedShopSettings?.laufText !== "object") {
    extendedShopSettings.laufText = {
      message: "",
      duration: 20,
      bgColor: "#000",
    };
  }
});
</script>
<style scoped>
.drag-item {
  cursor: grab;
  transition: transform 0.2s ease-in-out;
}

.drag-item:active {
  cursor: grabbing;
  transform: scale(0.95);
  opacity: 0.5;
}

/* Platzhalter sichtbar machen */
.drag-placeholder {
  opacity: 0.5;
  border: 2px dashed #007bff;
  background-color: rgba(0, 123, 255, 0.1);
  height: 40px;
  margin-bottom: 10px !important;
  transition: all 0.2s ease-in-out;
}

/* Miniatur-Vorschau */
.preview-frame {
  padding-top: 150px;
  width: 500%;
  height: 500%;
  border: none;
  transform: scale(0.25); /* Vorschaugröße auf 25% reduzieren */
  transform-origin: top left; /* Vorschau an der oberen linken Ecke fixieren */
  pointer-events: none; /* Keine Interaktion möglich */
}
</style>
