<!-- Folgetermin-option, Benutzerauswahl: nur Termine eines bestimmten Benutzers-->
<template>
  <div class="h-full">
    <Loading v-if="loading"></Loading>
    <div class="calendar-wrapper">
      <div class="calendar-container">
        <vue-cal
          ref="vuecal"
          v-model:active-view="activeView"
          v-model:selected-date="selectedDate"
          v-model:events="mappedEvents"
          today-button
          :dblclick-to-navigate="false"
          :time-from="8 * 60"
          :time-to="24 * 60"
          :views="['day', 'week', 'month', 'year']"
          view="day"
          locale="de"
          :snapToInterval="30"
          week-numbers
          cell-contextmenu
          show-time-in-cells
          time-at-cursor
          show-all-day-events
          events-count-on-year-view
          hide-view-selector
          @event-click="onEventClick"
          @event-create="handleTimeRangeClick"
          @cell-dblclick="handleDblClickNewEvent"
          style="--vuecal-height: 100%"
          :schedules="dayScheduleHeadings">
          <template #schedule-heading="{ schedule, view }">
            <strong :style="`color: ${schedule.color}`">{{
              schedule.label
            }}</strong>
          </template>
          <!-- 
      <template #event="{ event }">
        {{console.log(".............event",event)}}
  <div class="event-content">
    <div class="-mt-2 -pt-2 pl-1 bg-gray-100 text-color">{{ event.start.formatTime('HH:mm') }} - {{ event.end.formatTime('HH:mm') }}</div>
    <div>
    <span v-if="event?.calData?.targetName">{{ event?.calData?.targetName }}:</span>
    <span class="pl-1">{{ event.title }}</span>

  </div>

    <div v-if="event.duration < 60" class="event-extension">
      ⏳ Zeigt mehr...
    </div>
  </div>
</template> -->

          <template #event="{ event }">
            <div
              class="event-content"
              :style="{ backgroundColor: event.backgroundColor }">
              <!-- Zeitbereich mit eigener Hintergrundfarbe -->
              <div
                v-if="event.duration < 70"
                class="hidden md:flex event-time text-color grid grid-nogutter bg-white border-round-xl px-1">
                <div class="col-6">
                  {{ event.start.formatTime("HH:mm") }} -
                  {{ event.end.formatTime("HH:mm") }}
                </div>
                <div class="col-6 max-h-1rem">
                  {{ event?.calData?.targetName }}
                </div>
              </div>

              <!-- Event-Titel und Details -->
              <div class="event-details p-0 -mt-1 pb-2 pt-0 text-xs">
                <div
                  v-if="event.duration >= 70"
                  class="hidden md:flex text-color grid grid-nogutter bg-white border-round-xl px-1 font-bold mt-1 mb-1">
                  <div class="">
                    {{ event.start.formatTime("HH:mm") }} -
                    {{ event.end.formatTime("HH:mm") }}
                  </div>
                  <div class="pl-1">{{ event?.calData?.targetName }}</div>
                </div>
                <span v-if="event.duration < 40" class="text-xs">
                  <div>{{ event.title }}</div>
                </span>
                <span v-else>
                  <div>{{ event.title }}</div>
                  <div class="md:hidden bg-gray-500 mx-0">
                    {{ event?.calData?.targetName }}
                  </div>
                  <div>{{ event?.calData?.address }}</div>
                  <div class="flex justify-content-between mx-auto mt-1">
                    <span class="pi pi-user text-xs"></span>
                    <div class="flex flex-column align-items-start">
                      <small
                        v-for="user in event?.calData?.assignedToUserCids"
                        :key="user.userName">
                        {{ user.userName }}
                      </small>
                    </div>
                  </div>
                </span>
              </div>
            </div>
          </template>

          <template #column="{ column }">
            <div @click="handleColumnClick(column.id)">
              {{ column.label }}
            </div>
          </template>
          <!-- Benutzerdefinierte Kopfzeile mit #diy-Slot -->
          <template #header="{ view }">
            <div class="flex gap-2 p-1 align-items-center bg-gray-100">
              <!-- Button für vorheriges Datum -->
              <Button
                icon="pi pi-chevron-left"
                class="p-button-text p-button-rounded"
                @click="view.previous()" />

              <!-- Dynamischer Titel (z. B. "Mars 2025" oder "Semaine 10") -->

              <div class="text-base">
                <div class="custom-date-input" @click="openDatePicker">
                  <span class="md:hidden">{{
                    titleDateFormat(view.viewDate, true)
                  }}</span>
                  <span class="hidden md:inline">{{
                    titleDateFormat(view.viewDate, false)
                  }}</span>
                  <input
                    ref="dateInput"
                    type="date"
                    v-model="selectedTitleDate"
                    @change="navigateToDate"
                    class="hidden-date-input" />
                </div>
              </div>

              <!-- Button für nächstes Datum -->
              <Button
                icon="pi pi-chevron-right"
                class="p-button-text p-button-rounded"
                @click="view.next()" />
              <small>
                <Button
                  size="small"
                  class="p-1 md:p-2"
                  :severity="view.containsToday ? 'info' : 'secondary'"
                  text
                  label="Heute"
                  @click="view.goToToday()" />
              </small>

              <!-- Buttons für die Ansicht (Tag, Woche, Monat, Jahr) -->
              <div class="flex gap-2 ml-auto">
                <Button
                  :text="!view.isDay"
                  :outlined="view.isDay"
                  :severity="view.isDay ? 'info' : 'secondary'"
                  label="Tag"
                  class="p-1 md:p-2"
                  size="small"
                  @click="view.switch('day')" />
                <Button
                  :text="!view.isWeek"
                  :outlined="view.isWeek"
                  :severity="view.isWeek ? 'info' : 'secondary'"
                  label="Woche"
                  class="p-1 md:p-2"
                  size="small"
                  @click="view.switch('week')" />
                <Button
                  :text="!view.isMonth"
                  :outlined="view.isMonth"
                  :severity="view.isMonth ? 'info' : 'secondary'"
                  label="Monat"
                  class="p-1 md:p-2"
                  size="small"
                  @click="view.switch('month')" />
                <Button
                  :text="!view.isYear"
                  :outlined="view.isYear"
                  :severity="view.isYear ? 'info' : 'secondary'"
                  label="Jahr"
                  class="p-1 md:p-2"
                  size="small"
                  @click="view.switch('year')" />
                <Button
                  size="small"
                  outlined
                  class="hidden md:inline p-1 md:p-2"
                  iconPos="left"
                  icon="pi pi-plus"
                  label="Neuer Termin"
                  @click="openCalendarDialog(new Date(), 60, null, true)" />
              </div>
            </div>
            <div class="md:hidden w-full flex justify-content-end mb-3">
              <Button
                size="small"
                class="md:hidden p-1 md:p-2"
                iconPos="left"
                icon="pi pi-plus"
                text
                label="Neuer Termin"
                @click="openCalendarDialog(new Date(), 60, null, true)" />
            </div>
          </template>
        </vue-cal>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, defineAsyncComponent, computed,nextTick,reactive, markRaw } from "vue";
import { VueCal } from "vue-cal";
import "vue-cal/style";
import { format } from "date-fns";
import { de } from "date-fns/locale";
import { useToast } from "primevue/usetoast";
import { useDialog } from "primevue/usedialog";
import { CalendarService } from "@/service/CalendarService";
import { UserService } from "@/service/UserService";
import Loading from "@/components/Loading";
import { addDatePrototypes } from "vue-cal";
import { CalendarFormData } from "@/models/CalendarFormData";
import { parseLocalDateTime,colorArray } from "@/utils/calendarUtils.js";


const CalendarDialog = defineAsyncComponent(() => import("@/components/CalendarDialog.vue"));
const CalendarDialogFooter = defineAsyncComponent(() => import("@/components/CalendarDialogFooter.vue"));

const vuecal = ref(null);
const toast = useToast();
const dialog = useDialog();
const loading = ref(false);
// v-models
const activeView = ref("day");
const selectedDate = ref(new Date());
const selectedTitleDate = ref(new Date().toISOString().split("T")[0]);

const events = ref([]); // wird über v-model:events mit vue-cal synchronisiert
const mappedEvents = ref([]);

// Weitere States
const interval = ref(30);
const showDialog = ref(false);
const eventTitle = ref("");
const eventStart = ref(new Date().toISOString().slice(0, 16));
const eventDuration = ref(60);


const allUsers = ref([]);
// Funktion zum Runden der Zeit auf das nächste Intervall
const roundToNearestInterval = (date) => {
  const minutes = date.getMinutes();
  const roundedMinutes = Math.round(minutes / interval.value) * interval.value;
  date.setMinutes(roundedMinutes);
  date.setSeconds(0);
  return new Date(date);
};

/** Formatiert ein Date-Objekt ins Format "yyyy-MM-dd'T'HH:mm" für datetime-local. */
const formatDateTime = (date) => {
  const roundedDate = roundToNearestInterval(date);
  //return format(roundedDate, "yyyy-MM-dd'T'HH:mm")
  return format(date, "yyyy-MM-dd'T'HH:mm");
};

const titleDateFormat = (date, short) => {
  if (short) {
    return date.toLocaleDateString("de-DE", {
      weekday: "long",
      day: "2-digit", // Tag zweistellig (z. B. "09")
      month: "2-digit", // Monat als vollständiger Name (z. B. "März")
      year: "numeric", // Jahr vierstellig (z. B. "2025")
    });
  }
  return date.toLocaleDateString("de-DE", {
    weekday: "long",
    day: "2-digit", // Tag zweistellig (z. B. "09")
    month: "long", // Monat als vollständiger Name (z. B. "März")
    year: "numeric", // Jahr vierstellig (z. B. "2025")
  });
};

// Referenz zum input-Element
const dateInput = ref(null);

// Öffnet den Datepicker
const openDatePicker = () => {
  dateInput.value.showPicker();
};

// Navigiert zum ausgewählten Datum in Vue-Cal
const navigateToDate = () => {
  selectedDate.value = selectedTitleDate.value;
  const date = new Date(selectedDate.value);

  // Wechsle die Ansicht basierend auf dem ausgewählten Datum
  vuecal.value.view.updateViewDate(date);
};

// Klick auf ein existierendes Event
const onEventClick = (data) => {
  //absichtlich {event} nicht in parameter genommen, macht fehler frag nicht mach einfach
let event=data.event;

// eventData enthält z.B. { start: Date, end: Date, title: string, ... }

//Aufgrund von Multiday workarround daten direkt von events nehmen
const index = events.value.findIndex((e) => e.calDocumentId === event.calDocumentId);
      if (index !== -1) {
        event=events.value[index];
      }
  //debug */
  openCalendarDialog(event.start, event.duration, event);
};

const onCellClick = ({ e }) => {
  console.log(
    "e.srcElement.dataset.schedule",
    data.e.srcElement.dataset.schedule
  );
};

// Drag-and-drop zum Erstellen eines Zeitraums
const handleTimeRangeClick = (eventData) => {
  const cid = eventData.e.srcElement.dataset.schedule;
  // In vue-cal 5 bekommst du hier z.B. { start: Date, end: Date, allDay: boolean, ... }
  const start = formatDateTime(eventData.cell.start);
  const duration = (eventData.end - eventData.cell.start) / 60000; // in Minuten
  openCalendarDialog(start, duration);
};

// Doppelklick auf eine freie Zelle
const handleDblClickNewEvent = async (data) => {
  const uCid = data?.e?.srcElement?.dataset?.schedule;
  //const event={calData:{}}
  const user = await allUsers.value.find((u) => u.cid === uCid);

  const assignedToUserCids = [];
  assignedToUserCids.push(user);
  const calData = { assignedToUserCids };
  const event = { calData: calData };

  if (!data) {
    console.error("Ungültige daten übergeben");
    return;
  }
  const start = data.cursor.date;
  // console.log("[handleDblClick] data:", data);

  // date ist ein Date-Objekt

  eventStart.value = roundToNearestInterval(start);

  // console.log("🚀 ~ eventStart.value :", eventStart.value);

  openCalendarDialog(start, 60, event, true);
  //openCalendarDialog(eventStart.value, 60)
};

// Öffnet dein eigenes Dialog-Fenster
const openCalendarDialog = (start, duration, event, isNew) => {
  const assignedToUserCids=event?.calData?.assignedToUserCids || null;
  dialog.open(
    CalendarDialog,
    {
      props: {
        closable: true,
        header: isNew ? "Termin hinzufügen" : "Termin bearbeiten",
        style: { width: "75vw" },
        modal: true,
        maximizable:true,
        pt: {
          //workarround for breakpoints
          root: {
            class: "w-screen w-full md:w-10 xl:w-8 _2xl:w-5",
          },
        },
        breakpoints: {
          "331px": "100vw",
        },
      },
      templates: {
        footer: markRaw(CalendarDialogFooter)},
      data: {
        isNew,
        start: start,
        duration,
        event:isNew?reactive(new CalendarFormData(assignedToUserCids)):reactive(event),
      },
      onClose: (options) => {
        const data = options.data;
        if (data?.buttonType === "add") {
          addEvent(data.formData);
        }
        if (data?.buttonType === "save") {
          saveEvent(data.formData);
        }
        if (data?.buttonType === "delete") {
          deleteEvent(data.formData);
        }
      },
    }
  );
};

const dayScheduleHeadings = ref([]);


/** Fügt ein neues Event hinzu */
const addEvent = async(data) => {
  data.start= parseLocalDateTime(data.start);
  data.end = parseLocalDateTime(data.end);

  events.value.push(data);
  await processEvents();
  CalendarService.createCalendarEntry(data);
};

/** Löscht den existierendes Event */
const deleteEvent = async(data) => {
  events.value=events.value.filter(e=>e.calDocumentId!=data.calDocumentId);




  await processEvents();
  CalendarService.deleteCalendarEntry(data);
};

/** Speichert / aktualisiert ein existierendes Event */
const saveEvent = (data) => {
  data.start = new Date(data.start);
  data.end = new Date(data.end);

  const index = events.value.findIndex((event) => event.calDocumentId === data.calDocumentId);
  if (index !== -1) {
    events.value[index] = { ...data };
  }

  processEvents();
  CalendarService.updateCalendarEntry(data);
};



/* const mappedEvents = computed(() => {
  return events.value.flatMap((event) =>
    event.calData.assignedToUserCids.map((user) => {
      // Finde die Farbe für den jeweiligen User in dayScheduleHeadings
      const userHeading = dayScheduleHeadings.value.find(
        (u) => u.id === user.cid
      );
      return {
        ...event, // Kopie des Events
        schedule: user.cid, // Neue Key "schedule" mit der cid
        backgroundColor: userHeading ? userHeading.color : "#ccc", // Fallback-Farbe falls nicht gefunden
      };
    })
  );
}); */



const splitMultidayEvents = (event) => {
  const days = [];
  let currentDate = new Date(event.start);
  const endDate = new Date(event.end);

  while (currentDate <= endDate) {
    let segmentEnd = new Date(currentDate);
    segmentEnd.setHours(23, 59, 59); // Setzt das Ende auf Tagesende

    if (segmentEnd > endDate) {
      segmentEnd = new Date(endDate);
    }

    days.push({
      ...event,
      start: new Date(currentDate),
      end: new Date(segmentEnd),
      multiday: true,
    });

    currentDate.setDate(currentDate.getDate() + 1);
    currentDate.setHours(0, 0, 0); // Setzt auf Tagesanfang
  }

  return days;
};

// 🔄 Methode zur Verarbeitung von Events
const processEvents = async() => {
  if (!events.value || events.value.length === 0) {
    mappedEvents.value = []; // Falls keine Events vorhanden sind, setzen wir es auf ein leeres Array
    return;
  }
    mappedEvents.value = events.value.flatMap((event) =>
    event.calData.assignedToUserCids.flatMap((user) => {
      const userHeading = dayScheduleHeadings.value.find((u) => u.id === user.cid);
      const isMultiday = new Date(event.start).toDateString() !== new Date(event.end).toDateString();

      return isMultiday
        ? splitMultidayEvents(event).map((splitEvent) => ({
            ...splitEvent,
            schedule: user.cid,
            backgroundColor: userHeading ? userHeading.color : "#ccc",
          }))
        : {
            ...event,
            schedule: user.cid,
            backgroundColor: userHeading ? userHeading.color : "#ccc",
            multiday: isMultiday,
          };
    })
  );

  // Vue zwingt zur Neudarstellung, indem das Array vorübergehend geleert wird, anscheinend bekommt vue-cal sonst nicht mit und aktualisiert irgendwo die events nicht
  const updatedEvents = [...events.value];
  events.value = []; // kurzzeitig leeren

  nextTick(() => {
    events.value = updatedEvents; // zurücksetzen auf die aktualisierten Events
  });
};

onMounted(async () => {
  addDatePrototypes();
  loading.value = true;
  await CalendarService.getCalendarEntries().then((data) => {
    if (data)
    events.value = [...data];
  else events.value = [];
  });
  allUsers.value = await UserService.getUsers(true);
  dayScheduleHeadings.value = allUsers.value.map((user, index) => ({
    label: user.userName,
    id: user.cid,
    color: colorArray[index % colorArray.length], // Farben rotieren, falls mehr als 50 Nutzer
  }));
  await processEvents();
  // Füge Overlays nach dem Initialen Rendern hinzu
  loading.value = false;
});
</script>

<style>
.vuecal--default-theme .vuecal__view-button {
  display: inline-flex;
  border-width: 1px;
  border-radius: 4px;
  font-weight: 400;
  font-size: 0.8em;
}

.vuecal--default-theme .vuecal__header {
  color: black;
}

.vuecal--default-theme .vuecal__title-bar {
  position: relative;
  background-color: #eaeff4;
  padding-left: 0.6em;
  padding-right: 0.6em;
  /* background-color: 
color-mix(in srgb, var(--vuecal-secondary-color) 20%, transparent); */
}

.vuecal--default-theme .vuecal__header {
  background-color: #f2f4f6;
  color: black;
}

/* Optionales Styling für vue-cal-Events */
.vuecal__event {
  min-height: 1.6rem; /* Mindesthöhe setzen */
  padding: 5px;
  box-sizing: border-box;
  border-radius: 4px;
  justify-content: center;
  display: flex;
}

/* 🔹 Uhrzeit wird über dem Event angezeigt */
.event-time {
  position: absolute;
  top: -12px;
  left: 0;
  width: 100%;
  text-align: center;
  font-size: 0.8em;
  font-weight: bold;
}

/* 🔹 Event-Titel flexibel platzieren */
.event-title {
  width: 100%;
  text-align: center;
  font-size: 0.9em;
  white-space: nowrap; /* Kein Zeilenumbruch */
  overflow: hidden;
  text-overflow: ellipsis; /* Falls der Text zu lang ist */
}
</style>
<style scoped>
.custom-date-input {
  display: inline-flex;
  align-items: center;
  padding: 0.5rem;
  cursor: pointer;
  border: none;
  background: none;
  font-size: 1rem;
}

.custom-date-input:hover {
  background-color: #f0f0f0;
}

.hidden-date-input {
  opacity: 0;
  position: absolute;
  width: 0;
  height: 0;
  pointer-events: none;
}

/* 🔹 Wrapper für horizontales Scrollen */
.calendar-wrapper {
  width: 100%;
  overflow-x: auto; /* Scrollbar aktivieren */
  white-space: nowrap; /* Verhindert Umbruch */
}

/* 🔹 Container für Vue-Cal */
.calendar-container {
  min-width: 1000px; /* Mindestbreite */
  width: auto; /* Automatische Anpassung an Inhalte */
  max-width: none; /* Keine Begrenzung */
}
</style>
