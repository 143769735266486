
export const findCategoryNameByKey = (key, categories) => {
    for (let category of categories) {
        if (category.key === key) {
            return category.label;
        }
        if (category.children) {
            let found = findCategoryNameByKey(key, category.children);
            if (found) return found;
        }
    }
    return null;
};

export const findCategoryBySubPath = (key, categories, path = []) => {
    for (let i = 0; i < categories.length; i++) {
        if (categories[i].key === key) {
            return [...path, i];
        }
        if (categories[i].children) {
            let found = findCategoryBySubPath(key, categories[i].children, [
                ...path,
                i,
                "children",
            ]);
            if (found) return found;
        }
    }
    return null;
};

export const findCategoryByPath = (key, categories, path = []) => {
    for (let i = 0; i < categories.length; i++) {
        const newPath = path.concat({
            key: categories[i].key,
            label: categories[i].label,
            children: [],
            discountImageUrl: categories[i].discountImageUrl,
            newProductImageUrl: categories[i].newProductImageUrl,
        });
        if (categories[i].key === key) {
            return newPath;
        }
        if (categories[i].children) {
            const found = findCategoryByPath(key, categories[i].children, newPath);
            if (found) {
                newPath[newPath.length - 1].children = [found[found.length - 1]];
                return newPath;
            }
        }
    }
    return null;
};

export const getCategoryPathString = (structure) => {
    let labels = [];
    const traverse = (category) => {
        labels.push(category.label);
        if (category.children.length > 0) {
            traverse(category.children[0]);
        }
    };
    traverse(structure[0]);
    return labels.join(" > ");
};
