// src/models/CalendarFormData.js
export class CalendarFormData {
  constructor(assignedToUserCids = []) {
    this.title= null,
    this.start= new Date().toISOString().slice(0, 16),
    this.duration= 60,
    this.calData= {
        eventKind:null,
        assignedToUserCids:assignedToUserCids,
        auftragsDaten:{},
        address:null,
        internNotice:null,
        customerNotice:"Wir freuen uns auf Sie!",
        mobilePhone:null,
        phone:null,
        smsNumber:null,
        smsMessage:null,
        sendSMS:false,
        history:[],
    }
  }
}