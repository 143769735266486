export const dateToIso = (dateString) => {  
    //Notwendig damit es vom BE das Datum im Datumfeld darstellen kann
    // 1) String in Date-Objekt umwandeln
    const dateObj = new Date(dateString);
  
    // 2) Einzelne Werte aus dem Date-Objekt holen (in dessen *aktueller* Lokaleinstellung)
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, "0");
    const day = String(dateObj.getDate()).padStart(2, "0");
    const hours = String(dateObj.getHours()).padStart(2, "0");
    const minutes = String(dateObj.getMinutes()).padStart(2, "0");
  
    // 3) Zusammenbauen ins Format YYYY-MM-DDTHH:mm
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

 export const getDocIdFromHeader = (response, injectedValue) => {
 //   console.log('Alle Header:');
/*     Object.entries(response.headers).forEach(([name, value]) => {
        console.log(`${name}: ${value}`);
    }); */

    // Header auslesen
    if (typeof response.headers === 'object' && response.headers !== null) {
        injectedValue.calDocumentId = response.headers.caldocumentid;
        return response.headers.caldocumentid;
    } else {
        console.log('Die Header-Informationen sind nicht verfügbar.');
    } 
}

// 🔹 Hilfsfunktion: Erstellt ein lokales `Date`-Objekt aus einem ISO-String (ohne UTC-Shift)
export const parseLocalDateTime = (isoString) => {
    if (!isoString) return null;
  
    const dateParts = isoString.split("T"); // Trenne Datum und Uhrzeit
    const date = dateParts[0].split("-"); // YYYY-MM-DD → [2025, 03, 12]
    const time = dateParts[1]?.split(":") || ["00", "00"]; // HH:MM oder ["00", "00"]
  
    // Erstellt ein Date-Objekt, aber OHNE automatische UTC-Anpassung
    const localDate = new Date(
      Number(date[0]),   // Jahr
      Number(date[1]) - 1, // Monat (JS Monate sind 0-basiert)
      Number(date[2]),   // Tag
      Number(time[0]),   // Stunde
      Number(time[1])    // Minute
    );
  
    return localDate; // Gibt ein Date-Objekt zurück
  };
  

// 50 verschiedene, sich nicht stark ähnelnde Farben
export const colorArray = [
  "#2a4d96",
  "#d9534f",
  "#5cb85c",
  "#f0ad4e",
  "#5bc0de",
  "#7a3b69",
  "#4267B2",
  "#F4A261",
  "#3D348B",
  "#8A9A5B",
  "#1B998B",
  "#D7263D",
  "#3F88C5",
  "#F49F0A",
  "#9A031E",
  "#658E9C",
  "#FF6F61",
  "#6A0572",
  "#00A6A6",
  "#F95738",
  "#C3B091",
  "#364156",
  "#D81E5B",
  "#6C757D",
  "#C70039",
  "#A1A6B4",
  "#6610F2",
  "#B23A48",
  "#5E4B56",
  "#4ECDC4",
  "#FFAA33",
  "#669BBC",
  "#D9BF77",
  "#8D6A9F",
  "#5B5B5B",
  "#9E0031",
  "#F77F00",
  "#FFBA08",
  "#58355E",
  "#9C6644",
  "#5C415D",
  "#D9BF77",
  "#C850C0",
  "#1C77C3",
  "#2A9D8F",
  "#F4E409",
  "#E63946",
  "#8ECAE6",
  "#E76F51",
  "#FFC857",
];
