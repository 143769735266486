<template>
  <SupportWidget />
  <ConfirmDialog />
  <DynamicDialog />
  <Toast />
  <div id="app" class="app">
    <div v-if="isAuthenticated && firstLogin === false">
      <header
        class="p-component bg-gray-900"
        :class="isStandAlone ? 'headerStandalone' : 'header'">
        <div class="grid">
          <div class="flex pt-2 px-0 mx-0">
            <!-- Kopfzeileninhalt -->
            <!--       <img src="@/assets/famtura-with-name-sh.png" alt="Logo" class="logo" />-->
            <!-- Weitere Kopfzeileninhalte -->
            <button
              v-if="isMobile"
              v-ripple
              class="px-3 h-2rem bg-gray-900 hover:bg-gray-700 flex inline-flex border-none justify-content-center md:align-items-center cursor-pointer"
              @click="mobileMenu">
              <i class="text-2xl md:text-2xl text-primary pi pi-bars"></i>
            </button>
            <!--    <span v-if="isMobile"  v-ripple  
           class="pi pi-bars mobile-sidebar-toggle text-primary text-xl p-2 border-round transition-all border-transparent hover:border-solid hover:border-2 hover:border-primary hover:surface-primary cursor-pointer"  @click="mobileMenu" ></span>
            -->
            <img
              v-if="!isMobile && !isSidebarExpanded"
              src="@/assets/famtura_icon_fb.svg"
              alt="Sidebar Icon"
              class="scheinShadow"
              :class="isStandAlone ? 'sidebar-icon-standalone' : 'sidebar-icon'"
              @click="
                holdSidebar = true;
                isSidebarExpanded = true;
              " />
            <img
              v-if="isSidebarExpanded"
              src="@/assets/famtura_logo_t.svg"
              alt="Sidebar Icon"
              class="scheinShadow"
              :class="
                isStandAlone
                  ? 'sidebar-icon-long-standalone'
                  : 'sidebar-icon-long'
              "
              @click="
                holdSidebar = false;
                isSidebarExpanded = false;
              " />
            <img
              v-if="isMobile"
              src="@/assets/famtura_icon_fb.svg"
              alt="Icon"
              :class="isStandAlone ? 'logo-standalone' : 'logo'" />
            <div v-if="debug" class="text-white">{{ loggedUserName }}</div>
            <span v-if="debug">{{ console.log("store", store) }}</span>
          </div>
          <div class="col flex card align-items-center">
            <div class="flex-grow-1 align-items-center justify-content-center">
              <span
                class="font-light text-xl"
                style="color: #ddd; font-weight: 400; font-size: auto"
                v-if="!isMobile">
                <span v-html="headerTitel"></span>
                <span
                  class="text-orange-500"
                  v-if="debug"
                  @dblclick="setDebugOff"
                  ><strong> !! DEBUG MODUS AKTIVIERT !!</strong></span
                >
              </span>
              <span
                style="color: #ddd; font-weight: 400"
                class="text-xs md:text-base"
                v-else>
                {{ headerTitel }}
              </span>
            </div>
            <div class="flex align-items-center justify-content-end -mt-2">
              <span class="pl-1 text-xs md:text-base">{{
                loggedUserName
              }}</span>
              <Button
                text
                rounded
                icon="pi pi-cog text-white text-xl"
                v-tooltip.left="'Einstellungen'"
                id="settingsButton"
                aria-haspopup="true"
                @click="popupMenuToggle" />
              <Menu
                ref="popupMenu"
                id="overlay_popupMenu"
                :model="popupMenuItemsOwner"
                :popup="true"
                aria-controls="overlay_menu">
                <template #headericon="{ item }">
                  <span
                    v-if="item.iconKind == 'primevue'"
                    :class="item.icon"
                    style="padding-right: 10px"></span>
                  <span
                    v-if="item.iconKind == 'google'"
                    style="padding-right: 9px; margin-left: -5px"
                    class="material-symbols-outlined text-xl"
                    >{{ item.customIcon }}</span
                  >
                </template>
                <template #itemicon="{ item }">
                  <span
                    v-if="item.iconKind == 'primevue'"
                    :class="item.icon"
                    style="padding-right: 10px"></span>
                  <span
                    v-if="item.iconKind == 'google'"
                    style="padding-right: 9px; margin-left: -5px"
                    class="material-symbols-outlined text-xl"
                    >{{ item.customIcon }}</span
                  >
                </template>
              </Menu>

              <Button
                icon="pi pi-sign-out text-white text-xl"
                id="buttonLogout"
                text
                v-tooltip.left="'Abmelden'"
                rounded
                @click="confirmLogOut" />
              <Button
                icon="pi pi-ellipsis-v"
                style="color: #ddd"
                text
                rounded
                aria-label="help"
                @click="toggle"
                aria-haspopup="true"
                aria-controls="overlay_menu" />
              <Menu
                ref="menu"
                id="overlay_menu"
                :model="popUpItems"
                :popup="true" />
            </div>
          </div>
        </div>
      </header>
      <div class="main-container">
        <div
          v-show="!isMobile"
          :class="
            isStandAlone ? 'sidebar-container-standalone' : 'sidebar-container'
          ">
          <div
            class="sidebar bg-gray-900 shadow-7 border-round-bottom-2xl"
            :class="{ 'sidebar-expanded': isSidebarExpanded }"
            @mouseenter="expandSidebar"
            @mouseleave="collapseSidebar">
            <!-- Seitenleisteninhalt -->

            <PanelMenu
              v-model:expandedKeys="expandedKeys"
              :separator="false"
              :model="menuItems"
              :style="{ width: isSidebarExpanded ? '200px' : '50px' }"
              :multiple="false"
              @command="handleMenuCommand"
              class="menu-panel"
              :pt="{
                headerContent: { class: 'bg-gray-900' },
                menuContent: { class: 'bg-gray-900' },
                headerLabel: { class: 'text-white' },
                label: {
                  class: 'text-white',
                },
              }">
              <template #headericon="{ item }">
                <span
                  class="bg-gray-900 text-white text-xl"
                  v-if="item.iconKind == 'primevue' && !item.demo"
                  :class="item.icon"
                  style="padding-right: 10px"></span>
                <template v-if="item.demo">
                  <template v-if="store?.whoAmIData?.status !== 'ACTIVE'">
                    <span
                    v-if="item.iconKind == 'primevue'"
                    v-badge.warning="(store?.whoAmIData?.availableTrialDays)"
                    class="p-overlay-badge bg-gray-900 text-white text-xl"
                    :class="item.icon"
                    style="padding-right: 5px"
                    icon="pi pi-info-circle"/>
                  </template>
                  <template v-else>
                    <span
                    v-if="item.iconKind == 'primevue'"
                    class="p-overlay-badge bg-gray-900 text-white text-xl"
                    :class="item.icon"
                    style="padding-right: 5px"
                    icon="pi pi-info-circle"/>
                  </template>
                </template>
                <span
                  v-if="item.iconKind == 'google'"
                  style="padding-right: 9px; margin-left: -5px"
                  class="material-symbols-outlined bg-gray-900 text-white  text-xl"
                  >{{ item.customIcon }}</span
                >
              </template>
              <template #itemicon="{ item }">
                <span
                  class="bg-gray-900 text-white  text-xl"
                  v-if="item.iconKind == 'primevue'"
                  :class="item.icon"
                  style="padding-right: 10px"></span>
                <span
                  v-if="item.iconKind == 'google'"
                  style="padding-right: 9px; margin-left: -5px"
                  class="material-symbols-outlined bg-gray-900 text-white  text-xl"
                  >{{ item.customIcon }}</span
                >
              </template>
            </PanelMenu>
          </div>
        </div>

        <div v-if="isMobile">
          <!--           <div @click="toggleSidebar">
 -->
          <Sidebar
            v-model:visible="MenuVisible"
            :showCloseIcon="false"
            autoZIndex
            blockScroll
            :modal="false"
            :pt="{
              root: {
                style: { top: isStandAlone ? '30px' : '60px' },
                class: 'w-20rem bg-gray-900 text-white',
              },
            }">
            <div>
              <PanelMenu
                v-model:expandedKeys="expandedKeys"
                :separator="false"
                :model="menuItems"
                :multiple="false"
                class="w-full top:21rem md:w-25rem text-white"
                :pt="{
                  headerContent: { class: 'bg-gray-900' },
                  menuContent: { class: 'bg-gray-900' },
                  headerLabel: { class: 'text-white' },
                  label: {
                    class: 'text-white',
                  },
                }">
                <template #headericon="{ item }">
                  <span
                    v-if="item.iconKind == 'primevue'"
                    class="bg-gray-900 text-white"
                    :class="item.icon"
                    style="padding-right: 10px"></span>
                  <span
                    v-if="item.iconKind == 'google'"
                    style="padding-right: 9px; margin-left: -5px"
                    class="material-symbols-outlined bg-gray-900 text-white"
                    >{{ item.customIcon }}</span
                  >
                </template>
                <template #itemicon="{ item }">
                  <span
                    v-if="item.iconKind == 'primevue'"
                    class="bg-gray-900 text-white"
                    :class="item.icon"
                    style="padding-right: 10px"></span>
                  <span
                    v-if="item.iconKind == 'google'"
                    style="padding-right: 9px; margin-left: -5px"
                    class="material-symbols-outlined bg-gray-900 text-white"
                    >{{ item.customIcon }}</span
                  >
                </template>
              </PanelMenu>
            </div>
          </Sidebar>
          <!--           </div>
 -->
        </div>
      </div>
    </div>
    <Tip
      v-if="isAuthenticated && firstLogin == false"
      tipId="newFeatures5"
      target="body"
      :overlay="true" />
    <div
      v-if="isAuthenticated && firstLogin == false"
      class="content"
      :style="{ 'margin-left': isMobile ? '0' : '70px' }">
      <router-view></router-view>
    </div>
    <div v-else class="content">
      <router-view></router-view>
    </div>
    <!-- Hauptinhalt der Anwendung -->
  </div>
</template>

<script setup>
import { ref, onMounted, computed, provide } from "vue";
import { userStore } from "@/store/userStore";
import { storeToRefs } from "pinia";
import { useRouter, useRoute } from "vue-router";
import { useConfirm } from "primevue/useconfirm";
import { useI18n } from "vue-i18n";
import "material-symbols/outlined.css";
import { useToast } from "primevue/usetoast";
import SupportWidget from "@/components/SupportWidget.vue";

const toast = useToast();
const { locale } = useI18n();
const confirm = useConfirm();

const currentRoute = useRoute();
const router = useRouter();
const store = userStore();
const {
  isMainUser,
  isManagementUser,
  isWorkerUser,
  isAssistantUser,
  isStuffUser,
  tipEnabledList,
  headerTitel,
  rules,
  isAuthenticated,
  firstLogin,
  debug,
  companySettings,
  loggedUserName,
} = storeToRefs(store);

const isSidebarExpanded = ref(false);
const isMobile = ref(false);
const expandedKeys = ref([]);
const isSidebarCollapsed = ref(false);
const MenuVisible = ref(false);

const fastStartVisible = computed(() => {
  return (
    (!tipEnabledList?.value.tipsList?.hasOwnProperty("fastStartVisible") ||
      tipEnabledList?.value.tipsList?.["fastStartVisible"]) &&
    isMainUser.value
  );
});

const holdSidebar = ref(false);
let dbgZlr = 0;
const menuItems = computed(() => {
  return [
    {
      key: "0",
      label: "Schnellstart",
      icon: "pi pi-star",
      iconKind: "primevue",
      url: "/faststart",
      visible: fastStartVisible.value,
      command: () => {
        MenuVisible.value = false;
        headerTitel.value = "SCHNELLSTART";
      },
    },
    {
      key: "0",
      label: "Inventur",
      icon: "pi pi-barcode",
      iconKind: "primevue",
      url: "/inventurScanner",
      visible: rules.value.activatedModules.inventory && isStuffUser.value,
      command: () => {
        MenuVisible.value = false;
        headerTitel.value = "IVENTUR";
      },
    },
    {
      key: "1",
      label: "Dashboard",
      icon: "pi pi-home",
      iconKind: "primevue",
      visible: rules.value.activatedModules.dashboard,
      url: "/dashboard",
      command: () => {
        MenuVisible.value = false;
        headerTitel.value = "DASHBOARD";
      },
    },
    {
      key: "2",
      label: "Katalog",
      icon: "pi pi-warehouse",
      iconKind: "primevue",
      visible: rules.value.activatedModules.catalog,
      url: "/itemsView",
      command: () => {
        MenuVisible.value = false;
        headerTitel.value = "Katalog";
      },
    },
    {
      key: "3",
      label: "Adressen & Verwaltung",
      icon: "pi pi-address-book",
      iconKind: "primevue",
      visible: rules.value.activatedModules.customer,
      url: "/kundenView",
      command: () => {
        MenuVisible.value = false;
        headerTitel.value = "ADRESSEN & VERWALTUNG";
      },
    },
    {
      key: "4",
      label: "Angebote",
      iconKind: "primevue",
      icon: "pi pi-file",
      visible: rules.value.activatedModules.offer,
      url: "/OffersView",
      command: () => {
        MenuVisible.value = false;
        headerTitel.value = "ANGEBOTE";
      },
    },
    {
      key: "5",
      label: "Aufträge",
      iconKind: "primevue",
      icon: "pi pi-book",
      visible: rules.value.activatedModules.order,
      url: "/ordersView",
      command: () => {
        MenuVisible.value = false;
        headerTitel.value = "AUFTRÄGE";
      },
    },
    {
      key: "6",
      label: "Rechnungen",
      iconKind: "primevue",
      //customIcon: "receipt_long",
      icon: "pi pi-wallet",
      url: "/invoicesView",
      visible: rules.value.activatedModules.invoice,
      command: () => {
        MenuVisible.value = false;
        headerTitel.value = "RECHNUNGEN";
      },
    },
    {
      key: "7",
      label: "Gutschriften",
      iconKind: "primevue",
      //customIcon: "receipt_long",
      icon: "pi pi-cart-minus",
      url: "/creditnoteView",
      visible: rules.value.activatedModules.creditNote,
      command: () => {
        MenuVisible.value = false;
        headerTitel.value = "GUTSCHRIFTEN";
      },
    },
    {
      key: "8",
      label: "Geschäftsschreiben",
      iconKind: "primevue",
      icon: "pi pi-file-edit",
      url: "/businessLetter",
      visible: rules.value.activatedModules.businessLetter,
      command: () => {
        MenuVisible.value = false;
        headerTitel.value = "GESCHÄFSBRIEFE";
      },
    },
    {
      key: "9",
      label: "Versandabwicklung",
      icon: "pi pi-truck",
      iconKind: "primevue",
      url: "/shippingPrepareView",
      visible: rules.value.activatedModules.picking && isWorkerUser.value,
      command: () => {
        MenuVisible.value = false;
        headerTitel.value = "VERSAND - ABWICKLUNG";
      },
    },
    {
      key: "10",
      label: "Versand",
      icon: "pi pi-qrcode",
      iconKind: "primevue",
      url: "/shipping",
      visible: rules.value.activatedModules.shipping && !isWorkerUser.value,
      command: () => {
        MenuVisible.value = false;
        headerTitel.value = "VERSAND & QR SCANNER";
      },
    },
    {
      key: "10",
      label: "Kalendar",
      icon: "pi pi-calendar",
      iconKind: "primevue",
      url: "/calendarView",
      visible: rules.value.activatedModules.calendar,
      command: () => {
        MenuVisible.value = false;
        headerTitel.value = "Kalendar";
      },
    },
    {
      key: "20",
      label: " -> Lizenz buchen",
      demo: true,
      icon: "pi pi-info-circle",
      iconKind: "primevue",
      visible: ()=>{return debug.value && store?.whoAmIData?.status != 'ACTIVE'},
      url: "/pricingPlan",
      command: () => {
        MenuVisible.value = false;
        headerTitel.value = "Lizenz buchen";
      },
    },
  ];
});

const menu = ref();
const popUpItems = ref([
  {
    items: [
      {
        label: "Handbuch",
        icon: "pi pi-file",
        url: "/handbook",
        command: () => {
          headerTitel.value = "Handbuch";
        },
      },
      {
        label: "Support / Hilfe erhalten",
        iconKind: "primevue",
        //customIcon: "receipt_long",
        icon: "pi pi-ticket",
        url: "/ticketsView",
        command: () => {
          MenuVisible.value = false;
          headerTitel.value = "SUPPORT";
        },
      },
      {
        label: "",
        visible: () => {
          return store.dbgUser == true;
        },
        command: () => {
          handleDebug();
        },
      },
    ],
  },
]);

const handleDebug = () => {
  dbgZlr++;
  if (dbgZlr > 2) {
    dbgZlr = 0;
    store.setDebug(true);
    toast.add({
      severity: "info",
      summary: "DEBUG MODUS",
      detail: "DEBUG MODUS AKTIVIERT",
      life: 3000,
    });
  } else store.dbgUser = true;
};

let dlgCtr = 0;
const toggle = (event) => {
  if (dlgCtr > 4) {
    store.dbgUser = true;
    dlgCtr = 0;
  } else {
    if (dbgZlr > 0) {
      store.dbgUser = true;
    } else store.dbgUser = false;
    dlgCtr++;
  }

  menu.value.toggle(event);
};

const popupMenu = ref();

const popupMenuItemsLight = ref([
  {
    key: "1",
    label: "Rechtstexte",
    url: "legalTextsSettingsForm",
    iconKind: "primevue",
    icon: "pi pi-graduation-cap",
    //customIcon: "gavel",
    //icon: "ti ti-section-sign",
    //iconClass: "ti ti-section-sign",
    command: () => {
      MenuVisible.value = false;
      headerTitel.value = "RECHTSTEXTE";
    },
  },
  {
    key: "2",
    label: "Erweitert",
    url: "Settings",
    iconKind: "primevue",
    icon: "pi pi-sliders-h",
    command: () => {
      MenuVisible.value = false;
      headerTitel.value = "Einstellungen";
    },
  },
]);

const popupMenuItemsOwner = computed(() => {
  return [
  {
      key: "1",
      label: "Benutzerkonto",
      icon: "pi pi-wrench",
      iconKind: "primevue",
      url: "/AccountSettings",
      disabled: () => {
        return !isMainUser.value;
      },
      command: () => {
        MenuVisible.value = false;
        headerTitel.value = "BENUTZERKONTO";
      },
    },
    {
      key: "2",
      label: "Mitarbeiter",
      icon: "pi pi-user-edit",
      iconKind: "primevue",
      url: "UserSettingsView",
      disabled: () => {
        return !isMainUser.value;
      }, //     disabled: !isMainUser.value (ist falsch oder bug in primevue nicht reaktiv),
      command: () => {
        MenuVisible.value = false;
        headerTitel.value = "MITARBEITER";
      },
    },
    {
      key: "3",
      label: "Rechtstexte",
      url: "legalTextsSettingsForm",
      iconKind: "primevue",
      icon: "pi pi-graduation-cap",
      disabled: () => {
        return !isMainUser.value;
      },
      //customIcon: "gavel",
      //icon: "ti ti-section-sign",
      //iconClass: "ti ti-section-sign",
      command: () => {
        MenuVisible.value = false;
        headerTitel.value = "RECHTSTEXTE";
      },
    },
    {
      key: "4",
      label: "Erweitert",
      url: "Settings",
      iconKind: "primevue",
      icon: "pi pi-sliders-h",
      command: () => {
        MenuVisible.value = false;
        headerTitel.value = "Einstellungen";
      },
    },
  ];
});

const popupMenuToggle = (event) => {
  popupMenu.value.toggle(event);
};

const confirmLogOut = (data, draftToInvoice) => {
  let msg = "Bestätigen Sie die Abmeldung?";
  confirm.require({
    message: msg,
    header: "Abmelden bestätigen",
    icon: "pi pi-info-circle",
    acceptClass: "p-button-danger",
    acceptLabel: "Ja",
    rejectLabel: "Nein",
    defaultFocus: "reject",
    accept: () => {
      logout();
    },
    reject: () => {},
  });
};

const setDebugOff = () => {
  store.setDebug(false);
  toast.add({
    severity: "warn",
    summary: "DEBUG MODUS",
    detail: "DEBUG MODUS DEAKTIVIERT",
    life: 3000,
  });
};

const logout = () => {
  try {
    store.logout();
    MenuVisible.value = false;
    router.push("/");
  } catch (e) {
    console.log("\n\n logout erro", e);
  }
};

const mobileMenu = () => {
  MenuVisible.value = !MenuVisible.value;
  if (MenuVisible.value === true) {
    const mask = document.querySelector(".p-sidebar-mask");
    const overlayClass = "p-component-overlay-enter";
    if (mask && mask.classList.contains(overlayClass)) {
      mask.classList.remove(overlayClass);
    }
  }
};

/* const toggleSidebar = () => {
  if (isMobile.value) {
    isSidebarCollapsed.value = !isSidebarCollapsed.value;
  }
}; */

//watchEffect(() => {
/*   if (currentRoute.name=="MailConfirm"){
  console.log("🚀 ~ file: App.vue:306 ~ watchEffect ~ currentRoute.name:", currentRoute.name)
  console.log("currentRoute=MailConfirm");
}else{
  console.log("🚀 ~ file: App.vue:306 ~ watchEffect ~ currentRoute.name:", currentRoute.name)
  console.log("currentRoute!=MailConfirm");
}

if (store.isAuthenticated==false && currentRoute.name!="login" && currentRoute.name!="MailConfirm") {
  //router.push('/');
} */
//});

const expandSidebar = () => {
  if (holdSidebar) isSidebarExpanded.value = true;
};

const collapseSidebar = () => {
  if (holdSidebar) isSidebarExpanded.value = false;
};

const handleMenuCommand = (event) => {
  // Aktion nach dem Betätigen eines Menüpunkts
  // Hier die Sidebar verkleinern
  collapseSidebar();
};

const checkMobile = () => {
  isMobile.value = window.innerWidth < 769; // Anpassen der Bildschirmbreite nach Bedarf

  if (isMobile.value) {
    isSidebarExpanded.value = false; // Sidebar ausblenden auf mobilen Geräten
  }
};

const isStandAlone = computed(() => {
  const heightDifference = screen.height - window.innerHeight;

  /*   console.log("window.innerHeight:",window.innerHeight)
  console.log("window:",window)
  console.log("screen:",screen) */
  //if (store.standalone == undefined) store.standalone=window.matchMedia('(display-mode: standalone)').matches;
  const isStandalone =
    heightDifference < 50 ||
    window.matchMedia("(display-mode: standalone)").matches; // der einzige unterschied den ich feststellen konnte ob es standalone ist, da bei volbild display-mode nicht vorhanden ist bei standalone auch nicht

  return isStandalone;
});

const isBrowserMode = () => {
  let isBrowser = true;

  window.addEventListener("beforeinstallprompt", () => {
    isBrowser = true;
  });

  window.addEventListener("appinstalled", () => {
    isBrowser = false;
  });
  return isBrowser;
};

onMounted(async () => {
  console.log(
    "🚀 ~ file: App.vue:625 ~ rules.value.activatedModules:",
    rules.value.activatedModules
  );
  store.refreshUserData;
  locale.value = "de";
  checkMobile();
  window.addEventListener("resize", checkMobile);
});
</script>

<style scoped>
.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-x: hidden;
}

.header {
  position: fixed;
  background-color: #ffffff;
  padding-left: 7px;
  z-index: 2;
  height: 60px;
  width: 100%;
  transition: width 0.2s;
}
.headerStandalone {
  position: fixed;
  background-color: #ffffff;
  padding-left: 7px;
  z-index: 2;
  height: 30px;
  width: 100%;
  transition: width 0.2s;
}

.scheinShadow {
  filter: drop-shadow(-0.9rem -0.05rem 0.07rem #282828);
  /*   filter: drop-shadow(0px 3px 1px rgba(255, 255, 255,0.6));
 */
}

.logo {
  height: 40px;
  width: auto;
  margin-bottom: 5px;
  margin-top: 5px;
}
.logo-standalone {
  height: 20px;
  width: auto;
  margin-bottom: 5px;
  margin-top: 5px;
}

.main-container {
  flex-grow: 1;
  display: flex;
  position: relative;
  overflow-x: hidden;
}

.sidebar-container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  transition: width 0.3s ease;
  z-index: 2;
  top: 60px;
}
.sidebar-container-standalone {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  transition: width 0.3s ease;
  z-index: 2;
  top: 30px;
}

.sidebar {
  background-color: #fff;
  width: 70px;
  height: 100%;
  padding: 10px;
  transition: width 0.3s ease;
}

.sidebar-icon-standalone {
  height: 25px;
  width: auto;
  margin-bottom: 0px;
  margin-top: 0px;
}

.sidebar-icon {
  height: 50px;
  width: auto;
  margin-bottom: 5px;
  margin-top: 5px;
}

.sidebar-icon-long {
  height: 50px;
  width: auto;
  margin-top: 5px;
  margin-bottom: 5px;
}
.sidebar-icon-long-standalone {
  height: 25px;
  width: auto;
  margin-top: 0px;
  margin-bottom: 0px;
}

.sidebar-expanded {
  width: 220px;
}

.content {
  flex-grow: 1;
  padding: 10px;
  z-index: 1;
  margin-top: 60px;
}

:deep(.p-panelmenu .p-panelmenu-panel) {
  margin-bottom: -4px;
}

:deep(.p-panelmenu .p-panelmenu-header) {
  width: 55 px;
}

:deep(.p-panelmenu .p-panelmenu-header .p-panelmenu-header-content) {
  background-color: #ffffff;
  background: #ffffff;
  border: 0;
}

:deep(
    .p-panelmenu
      .p-panelmenu-header
      .p-panelmenu-header-content
      .p-panelmenu-header-action
  ) {
  padding: 1.25rem;
  font-weight: 700;
}

:deep(
    .p-panelmenu
      .p-panelmenu-header
      .p-panelmenu-header-content
      .p-panelmenu-header-action
      .pi
  ) {
  font-size: 24px;
}

:deep(
    .p-panelmenu
      .p-panelmenu-header
      .p-panelmenu-header-content
      .p-panelmenu-header-action
      .pi
      .material-symbols-outlined
  ) {
  font-size: 32px;
}

:deep(.p-panelmenu .p-submenu-icon) {
  display: none !important;
}

@media (min-width: 769px) {
  :deep(.p-menuitem-text) {
    display: none;
  }
}

:deep(.sidebar-expanded .p-menuitem-text) {
  display: inline;
  font-size: 12px;
  font-weight: 500;
}

:deep(.p-sidebar) {
  top: 62px;
}

.sidebar-expanded {
  width: 100%;
  /* Vollständige Breite der Sidebar */
}
</style>
