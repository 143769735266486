<template>
  <div class="container">
    <div class="banner">
      <h2>OVIsync UG</h2>
    </div>
    <div class="content">
      <h2>Add information to start accepting money</h2>
      <p>OVIsync UG is the world's leading air travel platform: join our team of pilots to help people travel faster.</p>
      <p v-if="error" class="error">Something went wrong!</p>
    </div>
    <div v-if="connectedAccountId || accountLinkCreatePending" class="dev-callout">
      <p v-if="connectedAccountId">Your connected account ID is: <code class="bold">{{ connectedAccountId }}</code></p>
      <p v-if="accountLinkCreatePending">Creating a new Account Link...</p>
    </div>
    <div class="info-callout">
      <p>
        This is a sample app for Stripe-hosted Connect onboarding. 
        <a href="https://docs.stripe.com/connect/onboarding/quickstart?connect-onboarding-surface=hosted" 
           target="_blank" rel="noopener noreferrer">View docs</a>
      </p>
    </div>
  </div>
</template>

<script setup>
import iAxios from '@/store/axiosInterface';
import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute();
const accountLinkCreatePending = ref(false);
const error = ref(false);
const connectedAccountId = ref(null);

const createAccountLink = async () => {
  accountLinkCreatePending.value = true;
  error.value = false;
  
  try {
    const response = await iAxios.post("/company/stripe/account_link", {
      account: connectedAccountId.value,
    });
    
    accountLinkCreatePending.value = false;
    
    const { url, error: fetchError } = response.data;
    if (url) {
      window.location.href = url;
    }
    
    if (fetchError) {
      error.value = true;
    }
  } catch (err) {
    accountLinkCreatePending.value = false;
    error.value = true;
  }
};

onMounted(() => {
  connectedAccountId.value = route.params.account;
  createAccountLink();
});
</script>
