<template>
  <template v-if="!store.rules.activatedModules.offer">
  <UpgradeLicence />
    </template>
  <template v-else>
  <div class="card p-fluid">
    <Loading v-show="loading" />

    <SpeedDial
      :model="MenuItems"
      :radius="80"
      type="semi-circle"
      direction="left"
      :style="{ zIndex: '999', top: 'calc(50% - 2rem)', right: 0 }"
      :tooltipOptions="{ position: 'left' }"
      showIcon="pi pi-bars"
      hideIcon="pi pi-times" />
    <div class="w-full">
      <MultiSelect
        v-show="showAdvanced"
        :modelValue="selectedColumns"
        :options="customerUid ? columnsCustomer : columns"
        optionLabel="header"
        @update:modelValue="onToggle"
        display="chip"
        placeholder="Spalten Ansicht wählen"
        :class="dtClass"
        class="w-auto" />
    </div>
    <DataTable
      v-model:filters="filters"
      :value="offers"
      editMode="row"
      :class="['dtClass', 'custom-datatable']"
      scrollable
      scrollHeight="85vh"
      :filterDisplay="showAdvanced ? 'row' : ''"
      tableStyle="min-width: 10%"
      resizableColumns
      columnResizeMode="fit"
      stripedRows
      selectionMode="single"
      dataKey="offerDocumentId"
      sortField="created_us"
      :sortOrder="-1"
      size="small"
      @row-dblclick="openOfferDialog"

      ref="dataTableRef">
      <template #empty>
          <div
            v-if="loading"
            class="flex justify-content-center align-items-center">
            <span
              class="text-center pi pi-spin pi-spinner text-4xl font-bold text-primary"></span>
            <span class="text-4xl px-4"> Daten werden geladen...</span>
            <span
              class="text-center pi pi-spin pi-spinner text-4xl font-bold text-primary"></span>
          </div>
          <div v-else>
            <span class="text-2xl font-bold text-primary"
              >Keine Einträge gefunden</span
            >
          </div>
        </template>
      <Column
        :headerStyle="
          showAdvanced ? 'width: 2rem; text-align: center' : 'display: none'
        "
        :bodyStyle="
          showAdvanced
            ? 'text-align: center; overflow: visible'
            : 'display: none'
        ">
        <template #header>
          <div>
            <div>
              <Button
                v-show="showAdvanced"
                type="button"
                outlined
                id="isNew"
                class="p-1"
                :class="dtClass"
                icon="pi pi-plus"
                :icon="dtClass"
                v-tooltip="'Neues Angebot erstellen'"
                @click="handleNewRow" />
            </div>
          </div>
        </template>
        <template #body="{ data }">
          <Button
            v-show="showAdvanced"
            type="button"
            class="p-0 m-0 h-2rem w-2rem"
            id="editCustomer"
            text
            :class="dtClass"
            icon="pi pi-file-edit"
            rounded
            @click="openOfferDialog({ data })" />
        </template>
      </Column>

      <Column
        field="docName"
        header="Angebotsnummer"
        :footer="showAdvanced ? 'Angebotsnummer' : ''"
        key="docName"
        :showFilterMenu="false"
        :filterMenuStyle="{ width: '15%' }"
        style="min-width: 15%"
        sortable>
        <template #body="{ data }">
          <template v-if="data.eventAction != 'OFFER_DRAFT'">
            {{ data.docName }}
          </template>
          <template v-else>
            <span class="font-light">{{ data.docName }}</span>
          </template>
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            v-model="filterModel.value"
            v-tooltip.top.focus="'Zum Anwenden Enter drücken'"
            type="text"
            @keydown.enter="filterCallback()"
            @input="filterModel.value || filterCallback()"
            :class="dtClass"
            class="p-column-filter" />
        </template>
      </Column>
      <Column
        v-for="col of selectedColumns"
        :key="col.field"
        :field="col.field"
        :header="col.header"
        :footer="showAdvanced ? col.header : ''"
        :showFilterMenu="false"
        sortable
        :sortField="isSortable(col.field) ? col.field.replace('_de', '_us') : col.field">
        <template #filter="{ filterModel, filterCallback }">
          <div v-if="col.field === 'created_de' || col.field === 'updated_de'">
            <Calendar
              v-model="filterModel.value"
              selectionMode="range"
              hideOnRangeSelection
              placeholder="Auswahl"
              mask="99.99.9999"
              showButtonBar
              showIcon
              :class="dtClass"
              :inputClass="dtClass"
              iconDisplay="input"
              @date-select="filterCallback()" />
          </div>
          <div v-else>
            <InputText
              v-model="filterModel.value"
              v-tooltip.top.focus="'Zum Anwenden Enter drücken'"
              type="text"
              @keydown.enter="filterCallback()"
              @input="filterModel.value || filterCallback()"
              :class="dtClass"
              class="p-column-filter" />
          </div>
        </template>
        <template #body="{ data, field }">
          {{ data[field] }}
        </template>
        <template #loading>
          <Skeleton width="60%" height="2rem" />
        </template>
      </Column>
      <Column
        field="documentStatus"
        header="Status"
        :footer="showAdvanced ? 'Status' : ''"
        key="documentStatus"
        :showFilterMenu="false"
        :filterMenuStyle="{ width: '15%' }"
        style="min-width: 15%"
        sortable>
        <template #body="{ data }">
          <template v-if="data.documentStatus === 'OFFER_CANCELED'">
            <div class="p-inputgroup">
              <Button
                disabled
                :label="getActionLabel(data.documentStatus)"
                :severity="getAngebotSeverity(data.documentStatus)"
                text
                class="py-2"
                size="small"
                rounded
                aria-haspopup="true" />
            </div>
          </template>
          <template v-if="data.documentStatus === 'OFFER_CORRECTION'">
            <div class="p-inputgroup">
              <Button
                disabled
                :label="getActionLabel(data.documentStatus)"
                :severity="getAngebotSeverity(data.documentStatus)"
                text
                class="py-2"
                :badgeClass="dtClass"
                :class="dtClass"
                :iconClass="dtClass"
                size="small"
                rounded
                aria-haspopup="true" />
            </div>
          </template>
          <template v-if="data.documentStatus === 'OFFER_DRAFT_OPEN'">
            <div class="p-inputgroup">
              <Button
                disabled
                icon="pi pi-file-edit"
                :label="getActionLabel(data.documentStatus)"
                :severity="getAngebotSeverity(data.documentStatus)"
                text
                class="py-2 text-gray-300"
                :badgeClass="dtClass"
                :class="dtClass"
                :iconClass="dtClass"
                size="small"
                rounded
                aria-haspopup="true" />
            </div>
          </template>
          <template
            v-if="
              data.documentStatus != 'OFFER_DRAFT_OPEN' &&
              data.documentStatus != 'OFFER_CORRECTION' &&
              data.documentStatus != 'OFFER_CANCELED'
            ">
            <div class="p-inputgroup">
              <Button
                icon="pi pi-angle-down"
                :label="getActionLabel(data.documentStatus)"
                :severity="getAngebotSeverity(data.documentStatus)"
                text
                class="py-2"
                :badgeClass="dtClass"
                :class="dtClass"
                :iconClass="dtClass"
                size="small"
                rounded
                aria-haspopup="true"
                @click="popUptoggle($event, data)"
                aria-controls="overlay_menu"
                iconPos="right" />
            </div>
            <Menu
              :selectedOptionIndex="data.documentStatus == 'OFFER_OPEN' ? 0 : 1"
              ref="menu"
              id="overlay_menu"
              :model="popUpitems"
              :popup="true" />
          </template>
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <Dropdown
            v-model="filterModel.value"
            @update:modelValue="applyReset(filterModel.value)"
            @change="filterCallback()"
            :options="statuses"
            optionLabel="label"
            optionValue="value"
            placeholder="Auswählen"
            class="p-column-filter"
            :class="dtClass"
            :inputClass="dtClass"
            style="min-width: 10%"
            :showClear="true">
            <template #option="slotProps">
              <Tag
                :value="slotProps.option.label"
                :class="slotProps.option.label"
                :severity="getAngebotSeverity(slotProps.option.value)" />
            </template>
          </Dropdown>
        </template>
      </Column>
      <ConfirmDialog group="offerRejeted">
        <template #message="slotProps">
          <div
            class="flex flex-column align-items-center w-full gap-3 border-bottom-1 surface-border p-3 mb-3 pb-0">
            <i
              :class="slotProps.message.icon"
              class="text-6xl text-primary-500"></i>
            Angebot <strong>{{ slotProps.message.data.docName }}</strong> wurde
            abgelehnt
            <p>{{ slotProps.message.message }}</p>
            <Textarea
              v-model="slotProps.message.data.docContent.rejectReason"
              class="w-full"
              rows="3"
              :autoResize="false"
              placeholder="Grund" />
          </div>
        </template>
      </ConfirmDialog>

      <!--       <Column headerStyle="width: 2rem; text-align: center" header="" footer=""
        bodyStyle="text-align: center; overflow: visible">
        <template #body="{ data }">
          <Button type="button" :userData="data" outlined text size="small" icon="pi pi-book" label="Storno"
            v-tooltip="'Korrektur Angebot anlegen'" severity="danger" rounded @click="openAngebot(data)" />
        </template>
      </Column> -->
    </DataTable>
  </div>
</template>
</template>

<script setup>
import {
  ref,
  onMounted,
  onBeforeMount,
  defineAsyncComponent,
  markRaw,
  reactive,
  computed,
} from "vue";
import { FilterMatchMode, FilterService } from "primevue/api";
import { OffersService } from "@/service/OffersService";
import { formatCurrencyWithZero } from "@/utils/formatUtils";
import Loading from '@/components/Loading.vue';
import { useRouter } from "vue-router";
import { useDialog } from "primevue/usedialog";
import { saveAndGetPDF, setDocumentStatus } from "@/utils/offerUtils";
import { nextTick } from "vue";
import iAxios from "@/store/axiosInterface";
import { userStore } from "@/store/userStore";
import { storeToRefs } from 'pinia';
import { useToast } from "primevue/usetoast";
import { useConfirm } from "primevue/useconfirm";

const store = userStore();
const confirm = useConfirm();
const toast = useToast();

const OffersEditComponent = defineAsyncComponent(() =>
  import("@/views/OffersEditComponent.vue")
);
const DefaultFooter = defineAsyncComponent(() =>
  import("@/views/OffersEditFooter.vue")
);
const DefaultHeader = defineAsyncComponent(() =>
  import("@/views/OffersEditHeader.vue")
);
const router = useRouter();
const OrdersEditComponent = defineAsyncComponent(() =>
  import("@/views/OrdersEditComponent.vue")
);
const OrderEditFooter = defineAsyncComponent(() =>
  import("@/views/OrdersEditFooter.vue")
);
const OrderEditHeader = defineAsyncComponent(() =>
  import("@/views/OrdersEditHeader.vue")
);

const InvoicesEditComponent = defineAsyncComponent(() =>
  import("@/views/InvoicesEditComponent.vue")
);
const InvoiceEditFooter = defineAsyncComponent(() =>
  import("@/views/InvoicesEditFooter.vue")
);
const InvoiceEditHeader = defineAsyncComponent(() =>
  import("@/views/InvoicesEditHeader.vue")
);

const { dtClass, customerUid, showAdvanced } = defineProps({
  dtClass: {
    type: String,
    required: false,
    default: "",
  },
  customerUid: {
    required: false,
    default: "",
  },
  showAdvanced: {
    type: Boolean,
    required: false,
    default: true,
  },
});

const columns = ref([
  { field: "kundenNummer", header: "Kundennr" },
  { field: "name1", header: "Name" },
  { field: "postCode", header: "PLZ" },
  { field: "documentValueBrutto", header: "Angebotsbetrag" },
  { field: "documentValueNetto", header: "Angebotsbetrag Netto" },
  { field: "created_de", header: "Erstelldatum" },
  { field: "updated_de", header: "Zuletzt Geändert" },
  { field: "createdUser", header: "Erstellt Benutzer" },
  { field: "updatedUser", header: "Geändert Benutzer" },
]);
const startColumns = ref([
  { field: "name1", header: "Name" },
  { field: "documentValueBrutto", header: "Angebotsbetrag" },
  { field: "created_de", header: "Erstelldatum" },
]);

const columnsCustomer = ref([
  { field: "documentValueBrutto", header: "Angebotsbetrag" },
  { field: "documentValueNetto", header: "Angebotsbetrag Netto" },
  { field: "created_de", header: "Erstelldatum" },
  { field: "updated_de", header: "Zuletzt Geändert" },
  { field: "createdUser", header: "Erstellt Benutzer" },
  { field: "updatedUser", header: "Geändert Benutzer" },
]);
const startColumnsCustomer = ref([
  { field: "created_de", header: "Erstelldatum" },
  { field: "documentValueBrutto", header: "Angebotsbetrag" },
]);

const selectedColumns = customerUid
  ? ref(startColumnsCustomer.value)
  : ref(startColumns.value);
const onToggle = (val) => {
  const cols = customerUid ? columnsCustomer : columns;
  const valFields = val.map((v) => v.field);
  cols.value.forEach((col) => {
    if (
      valFields.includes(col.field) &&
      !selectedColumns.value.some(
        (selectedCol) => selectedCol.field === col.field
      )
    ) {
      selectedColumns.value.push(col);
    } else if (!valFields.includes(col.field)) {
      selectedColumns.value = selectedColumns.value.filter(
        (selectedCol) => selectedCol.field !== col.field
      );
    }
  });
};
FilterService.register("dateFilter", dateFilter);

const filters = ref({
  docName: { value: null, matchMode: FilterMatchMode.CONTAINS },
  documentStatus: { value: null, matchMode: FilterMatchMode.CONTAINS },
  name1: { value: null, matchMode: FilterMatchMode.CONTAINS },
  postCode: { value: null, matchMode: FilterMatchMode.CONTAINS },
  documentValueBrutto: { value: null, matchMode: FilterMatchMode.CONTAINS },
  documentValueNetto: { value: null, matchMode: FilterMatchMode.CONTAINS },
  createdUser: { value: null, matchMode: FilterMatchMode.CONTAINS },
  created_de: {
    value: null,
    matchMode: "dateFilter",
    filterFunction: dateFilter,
    dateRange: [null, null],
  },
  updatedUser: { value: null, matchMode: FilterMatchMode.CONTAINS },
  updated_de: {
    value: null,
    matchMode: "dateFilter",
    filterFunction: dateFilter,
    dateRange: [null, null],
  },
  kundenNummer: { value: null, matchMode: FilterMatchMode.CONTAINS },
  documentStatus: { value: null, matchMode: FilterMatchMode.CONTAINS },
});

function dateFilter(value, filter) {
  // Konvertieren ob das Datum in das Format 'yyyy-MM-dd'
  const [day, month, yearTime] = value.split(".");
  const [year, time] = yearTime.split(" ");
  const date = new Date(`${year}-${month}-${day} ${time}`);
  const formattedDate =
    date.getFullYear() +
    "-" +
    (date.getMonth() + 1).toString().padStart(2, "0") +
    "-" +
    date.getDate().toString().padStart(2, "0");

  // Überprüfen, ob der Filterwert ein Bereich ist
  if (Array.isArray(filter)) {
    const [start, end] = filter.map((dateStr) => {
      const date = new Date(dateStr);
      return (
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1).toString().padStart(2, "0") +
        "-" +
        date.getDate().toString().padStart(2, "0")
      );
    });
    return formattedDate >= start && formattedDate <= end;
  }

  // Überprüfen, ob das formatierte Datum den Filterwert enthält
  return formattedDate.includes(filter);
}

function isSortable(field) {
  return field === "updated_de" || field === "created_de";
}
const isMobile = ref(false);
const loading = ref(true);

const lazyLoading = ref(false);
const loadLazyTimeout = ref();

const offers = ref([]);
const virtualOffers = ref([]);
const dialog = useDialog();
let rowData = null;
const dataTableRef = ref();
const popUpLabel = reactive({ label: "" });

const applyReset = (selectedFilter) => {
  console.log(
    "🚀 ~ file: OffersView.vue:409 ~ selectedFilter:",
    selectedFilter
  );

  if (selectedFilter === "" || selectedFilter === "OFFER_DRAFT_OPEN") {
    offers.value = allOffers.value;
  } else {
    offers.value = allOffers.value.filter(
      (offer) => offer.documentStatus !== "OFFER_DRAFT_OPEN"
    );
  }
};

const statuses = reactive([
  { label: "Entwurf", value: "OFFER_DRAFT_OPEN" },
  { label: "Ausstehend", value: "OFFER_OPEN" },
  { label: "Akzeptiert", value: "OFFER_ACCEPTED" },
  { label: "Abgelehnt", value: "OFFER_REJECTED" },
  { label: "Alle", value: "" },
]);

const popUptoggle = (event, data) => {
  rowData = data;
  popUpLabel.label = "Angebot " + rowData.docName;
  menu.value.toggle(event);
};

const menu = ref();
const popUpitems = computed(() => [
  {
    label: popUpLabel.label,
    items: [
      {
        label: "Ausstehend",
        icon: "pi pi-hourglass text-blue-400",
        command: () => {
          if (rowData && rowData.documentStatus != "OFFER_OPEN") {
            setDocumentStatus(rowData.offerDocumentId, "OFFER_OPEN");
            rowData.documentStatus = "OFFER_OPEN";
          }
        },
      },
      {
        label: "Akzeptiert",
        icon: "pi pi-check text-green-400",
        command: () => {
          if (rowData && rowData.documentStatus != "OFFER_ACCEPTED") {
            setDocumentStatus(rowData.offerDocumentId, "OFFER_ACCEPTED");
            rowData.documentStatus = "OFFER_ACCEPTED";
          }
        },
      },
      {
        label: "Abgelehnt",
        icon: "pi pi-times text-red-400",
        command: () => {
          if (rowData && rowData.documentStatus != "OFFER_REJECTED") {
            confirmRejected(rowData);
          }
        },
      },
    ],
  },
]);

const confirmRejected = (rowData) => {
  confirm.require({
    group: "offerRejeted",
    message: "Bitte Grund für Ablehnung eintragen und Bestätigen",
    icon: "pi pi-exclamation-circle",
    data: rowData,
    acceptIcon: "pi pi-check",
    rejectIcon: "pi pi-times",
    acceptLabel: "Bestätigen",
    rejectLabel: "Abbrechen",
    rejectClass: "p-button-outlined p-button-sm p-button-danger",
    acceptClass: "p-button-sm",
    accept: () => {
      setDocumentStatus(rowData.offerDocumentId, "OFFER_REJECTED");
      rowData.documentStatus = "OFFER_REJECTED";
      toast.add({
        severity: "info",
        summary: "Bestätigt",
        detail: "Angebot wurde abgelehnt",
        life: 3000,
      });
    },
    reject: () => {
      toast.add({
        severity: "error",
        summary: "Abgebrochen",
        detail: "Vorgang abgebrochen",
        life: 3000,
      });
    },
  });
};

const pdfFile = ref();
const handleNewRow = async (event) => {
  const dialogRef = dialog.open(OffersEditComponent, {
    props: {
      header: "Angebot erstellen",
      style: {
        width: "",
      },
      maximizable: true,
      breakpoints: {
        // breakpoints so lassen! bei fragen an abdullah
        "2135px": "48vw",
        "1800px": "50vw",
        "1600px": "60vw",
        "1460px": "70vw",
        "1336px": "75vw",
        "1000px": "90vw",
        "831px": "100vw",
      },
      modal: true,
      closable: false,
    },
    data: {
      offer: {
        offerDocumentId: null,
        readOnly: false,
        storno: false,
        isNew: true,
      },
      angebotsDaten: event.data,
    },
    templates: {
      footer: markRaw(DefaultFooter),
      header: markRaw(DefaultHeader),
    },
    onClose: async (options) => {
      console.log("🚀 ~ file: OffersView.vue:284 ~ options:", options);

      if (options.data) {
        const buttonType = options.data.buttonType;
        if (buttonType == "Save") {
          let responseFile = await saveAndGetPDF(dialogRef);
          pdfFile.value = dialogRef.data.pdfFile;
        } else if (buttonType == "Generate") {
          let response = speichern(dialogRef, true);
        } else if (buttonType == "Delete") {
          console.log("offer deleted1");
          //von virtalOffers löschen mit offerDocumentId
          refreshReRenderTable();
          /*           const index = virtualOffers.value.findIndex((offer) => offer.offerDocumentId == options.data.offerDocumentId);
                    if (index > -1) {
                      virtualOffers.value.splice(index, 1);
                    }
                    //von offers löschen mit offerDocumentId
                    const index2 = offers.value.findIndex((offer) => offer.offerDocumentId == options.data.offerDocumentId);
                    if (index2 > -1) {
                      offers.value.splice(index2, 1);
                    } */
        } else if (buttonType == "GoToOffer") {
          openOffer(options.data.offerDocumentId);
        } else if (buttonType == "GoToOrder") {
          openOrder(options.data.orderDocumentId);
        } else refreshReRenderTable();
      }
    },
  });
};

const refreshReRenderTable = async () => {
  await werteLaden();
  //    dataTableRef.value.filter();
  loadCustomersLazy({
    first: firstVirtualOfferIndex.value,
    last: lastVirtualOfferIndex.value + 1,
  });
};

const lastVirtualOfferIndex = ref(0);
const firstVirtualOfferIndex = ref(0);
const loadCustomersLazy = (event) => {
  !lazyLoading.value && (lazyLoading.value = true);

  if (loadLazyTimeout.value) {
    clearTimeout(loadLazyTimeout.value);
  }
  //simulate remote connection with a timeout
  loadLazyTimeout.value = setTimeout(() => {
    let tempVirtualOffers = [...offers.value];
    let { first, last } = event;
    lastVirtualOfferIndex.value = last;
    firstVirtualOfferIndex.value = first;

    //load data of required page
    const loadedCustomers = offers.value.slice(first, last);
    //populate page of virtual cars
    Array.prototype.splice.apply(tempVirtualOffers, [
      ...[first, last - first],
      ...loadedCustomers,
    ]);

    virtualOffers.value = tempVirtualOffers;
    lazyLoading.value = false;
  }, 1200);
};

const openOfferDialog = async (event, proceedType) => {
  console.log("openofferDialog");
  const isCloned = proceedType == "Clone";
  const isDraft = event.data.eventAction == "OFFER_DRAFT" || isCloned;
  const isCancelledDone = event.data.relationFrom.offerCancelledFromId;
  const isCancelled = proceedType == "Cancellation" || isCancelledDone;
  const isDuplicated = isCloned || isCancelled;

  //event.data.responsed.offerDocumentId = event.data.offerDocumentId || event.data.offerDocumentId;
  // event.data.responsed.documentName = event.data.docName;
  const dialogRef = dialog.open(OffersEditComponent, {
    props: {
      header: isCancelled ? "Angebotskorrektur " : "Angebot",
      subHeader: isDraft ? "(Entwurf)" : event.data.docName,
      maximizable: true,
      style: {
        width: "",
      },
      breakpoints: {
        // breakpoints so lassen! bei fragen an abdullah
        "2135px": "48vw",
        "1800px": "50vw",
        "1600px": "60vw",
        "1460px": "70vw",
        "1336px": "75vw",
        "1000px": "90vw",
        "831px": "100vw",
      },
      modal: true,
    },
    templates: {
      footer: markRaw(DefaultFooter),
      header: markRaw(DefaultHeader),
    },
    data: {
      offer: {
        isLoading: loading.value,
        isEditable: isDuplicated || isDraft,
        isCancelled: isCancelled,
        isCloned: isCloned,
        isDraft: isDraft,
        offerDocumentId: event.data.offerDocumentId
          ? event.data.offerDocumentId
          : event.data.offerDocumentId,
        docName: !isDuplicated ? event.data.docName : null,
        readOnly: false,
        storno: isCancelled,
      },
      angebotsDaten: event.data,
    },
    onClose: async (options) => {
      const buttonType = options?.data?.buttonType;
      if (options.data && isDraft) {
        if (buttonType == "Save") {
        } else if (buttonType == "Delete") {
          console.log("offer deleted2");
          //von virtalOffers löschen mit offerDocumentId
          refreshReRenderTable();
          /*           const index = virtualOffers.value.findIndex((offer) => offer.offerDocumentId == options.data.offerDocumentId);
                    if (index > -1) {
                      virtualOffers.value.splice(index, 1);
                    }
                    //von offers löschen mit offerDocumentId
                    const index2 = offers.value.findIndex((offer) => offer.offerDocumentId == options.data.offerDocumentId);
                    if (index2 > -1) {
                      offers.value.splice(index2, 1);
                    } */
        }
        refreshReRenderTable();
      } else if (buttonType == "offerToOrder") {
        console.log("offerToOrder");
        const co = convertToOrder(event);
        openOrderDialog( co, "fromOffer");
      }else if (buttonType == "offerToInvoice") {
        console.log("offerToInvoice");
        convertToInvoice(event);
      } else if (buttonType == "Clone") {
        openOfferDialog(cloneOffer(event, "Clone", dialogRef), "Clone");
      } else if (buttonType == "GoToOffer") {
        openOffer(options.data.offerDocumentId);
      } else if (buttonType == "GoToOrder") {
        openOrder(options.data.orderDocumentId);
      } else refreshReRenderTable();
    },
  });
};

const convertToOrder = (event) => {
    const copyEvent = JSON.parse(JSON.stringify(event));
    const angebotsDatenCopy = copyEvent.data;
  
    let docName = angebotsDatenCopy.docName;
    let offerDocumentId = angebotsDatenCopy.offerDocumentId;
    let orderModel = angebotsDatenCopy;
  

      //Rechnung elemente hinzufügen
      orderModel.isConverted = true;

    orderModel.docContent.documentDatum = new Date().toISOString().slice(0, 10);
    orderModel.relationFrom = {
      generatedFromOfferId: offerDocumentId,
      generatedFromOfferDocName: docName,
    };
    orderModel.docContent.orderTitel = "Auftrag";
    orderModel.salutationText = store.companySettings.salutationText;
    orderModel.docContent.orderIntroduction =
      "Sie erhalten anbei den Auftrag zum Angebot " + docName;
    orderModel.orderFinalText = store.companySettings.orderFinalText;
    orderModel.documentStatus = "ORDER_DRAFT_OPEN";
    orderModel.eventAction = "ORDER_DRAFT";
    orderModel.eventText = "ORDER_CREATED_FROM_OFFER";
    orderModel.isConverted = true;
    orderModel.orderDocumentId = null;
    orderModel.docContent.subHeader = null;
    orderModel.isNew = true;
    return { data: orderModel };
  };

const cloneOffer = async (event, proceedType, dialogRef) => {
    const copyEvent = JSON.parse(JSON.stringify(event));
    const angebotsDatenCopy = copyEvent.data;
    let docNameFrom = angebotsDatenCopy.docName;
    let offerDocumentIdFrom = angebotsDatenCopy.offerDocumentId;
    event.data.docContent.documentDatum = new Date().toISOString().slice(0, 10);
    event.data.docName = null;
    event.data.offerDocumentId = null;
    event.data.storno = true;
    ///TODO open paid status setzen
    switch (proceedType) {
      case "Cancellation":
        {
          event.data.docContent.offerTitel = "Angebotskorrektur/ Gutschrift";
          event.data.documentStatus = "OFFER_CANCELED";
          event.data.docContent.offerIntroduction =
            " Sie erhalten anbei die Angebotskorrektur/ Gutschrift zur Angebot " +
            docNameFrom;
          event.data.relationFrom = {
            offerCancelledFromId: offerDocumentIdFrom,
            offerCancelledDocName: docNameFrom,
          };
          const itemList = event.data.itemList;
          itemList.forEach((item) => {
            item.quantity = item.quantity * -1;
          });
        }
        break;
      case "Clone":
        {
          event.data.docContent.offerTitel = "Angebot";
          event.data.documentStatus = "OFFER_PENING";
          event.data.docContent.offerIntroduction =
            " Sie erhalten anbei unsere Angebot.";
          event.data.relationFrom = {
            duplicatedFromId: offerDocumentIdFrom,
            duplicatedFromDocName: docNameFrom,
          };
          event.data.isNew = true;
        }
        break;
    }
    return event;
  };


const openOffer = async (offerDocumentId) => {
  await werteLaden();
  console.log(
    "🚀 ~ file: OffersView.vue:427 ~ offerDocumentId:",
    offerDocumentId
  );
  offers.value.forEach((offer) => {
    if (offer.offerDocumentId == offerDocumentId) {
      openOfferDialog({ data: offer });
    }
  });
};



const getAngebotSeverity = (statusAngebot) => {
  switch (statusAngebot) {
    case "OFFER_ACCEPTED":
      return "success";

    case "OFFER_REJECTED":
      return "danger";

    case "OFFER_OPEN":
      return "info";
  }
  return "info";
};

const convertToInvoice = async (event) => {
  const copyEvent = JSON.parse(JSON.stringify(event));
  const angebotsDatenCopy = copyEvent.data;

  let docName = angebotsDatenCopy.docName;
  let offerDocumentId = angebotsDatenCopy.offerDocumentId;
  let invoiceModel = angebotsDatenCopy;

  //Rechnung elemente hinzufügen
  invoiceModel.documentStatus = "INVOICE_OPEN";
  invoiceModel.eventAction = "INVOICE_DRAFT";
  invoiceModel.eventText = "INVOICE_CREATED_FROM_ORDER";
  invoiceModel.isConverted = true;
  invoiceModel.invoiceFooterNotes = null;

  invoiceModel.docContent.documentDatum = new Date().toISOString().slice(0, 10);
  invoiceModel.relationFrom = {
    generatedFromOfferId: offerDocumentId,
    generatedFromOfferDocName: docName,
  };
  invoiceModel.docContent.invoiceTitel = "Rechnung";
  invoiceModel.salutationText = store.companySettings.salutationText;
  invoiceModel.docContent.invoiceIntroduction =
    "Sie erhalten anbei die Rechnung zum Angebot " + docName;
  invoiceModel.invoiceFinalText = store.companySettings.invoiceFinalText;
  invoiceModel.documentStatus = "INVOICE_DRAFT_OPEN";
  invoiceModel.eventAction = "INVOICE_DRAFT";
  invoiceModel.eventText = "INVOICE_CREATED";
  invoiceModel.invoiceDocumentId = null;
  invoiceModel.docContent.subHeader = null;
  invoiceModel.isNew = true;

  console.log("🚀 ~ file: OffersView.vue:514 ~ invoiceModel:", invoiceModel);

  openInvoiceDialog({ data: invoiceModel }, "fromOffer", true);
};



const openInvoiceDialog = async (event, proceedType,isDraft) => {
  console.log(
    "🚀 ~ file: OrdersView.vue:741 ~ openReadOnlyInvoice ~ event:",
    event
  );
  const isFromOffer = proceedType == "fromOffer";

  const dialogRef = dialog.open(InvoicesEditComponent, {
    props: {
      header: "Rechnung",
      subHeader: isDraft
        ? "(Entwurf)"
        : isFromOffer
        ? "(Entwurf Rechnung erstellt von Angebot " + event.data.docName + ")"
        : event.data.docName,
      style: {
        width: "",
      },
      maximizable: true,
      breakpoints: {
        // breakpoints so lassen! bei fragen an abdullah
        "2135px": "48vw",
        "1800px": "50vw",
        "1600px": "60vw",
        "1460px": "70vw",
        "1336px": "75vw",
        "1000px": "90vw",
        "831px": "100vw",
      },
      modal: true,
    },
    templates: {
      footer: markRaw(InvoiceEditFooter),
      header: markRaw(InvoiceEditHeader),
    },
    data: {
      invoice: {
        isLoading: loading.value,
        isDraft: isDraft,
        invoiceDocumentId: null,
        docName: null,
        readOnly: !isDraft,
      },
      rechnungsDaten: event.data,
    },
    onClose: async (options) => {
      const buttonType = options?.data?.buttonType;
      if (options.data) {
        if (buttonType == "Save" && isDraft) {
          refreshReRenderTable();
        } else if (buttonType == "Delete") {
          //von virtalInvoices löschen mit invoiceDocumentId
          refreshReRenderTable();
        }
        //refreshReRenderTable();
        else if (buttonType == "Cancellation") {
          cloneInvoice(event, "Cancellation", dialogRef);
        } else if (buttonType == "Clone") {
          cloneInvoice(event, "Clone", dialogRef);
        } else if (buttonType == "GoToInvoice") {
          openInvoice(options.data.invoiceDocumentId,"fromOffer",options.data.isDraft);
        } else if (buttonType == "GoToOrder") {
          openOrder(options.data.orderDocumentId);
        } else confirmChangeWindow("Rechnungen");
      }
    },
  });
};
const openOrderDialog = async (event, proceedType) => {

console.log("🚀 ~ file: OffersView.vue:1020 ~ proceedType:", proceedType)


  console.log("🚀 ~ file: OffersView.vue:1020 ~ event:", event)

  console.log("openOrderDialog");
  const isCloned = proceedType == "Clone";
  const isFromOffer = proceedType == "fromOffer";
  const isDraft = event.data.eventAction == "ORDER_DRAFT" || isCloned;
  const isCancelledDone = event.data?.relationFrom?.orderCancelledFromId;
  const isCancelled = proceedType == "Cancellation" || isCancelledDone;
  const isDuplicated = isCloned || isCancelled;

  //event.data.responsed.orderDocumentId = event.data.orderDocumentId || event.data.orderDocumentId;
  // event.data.responsed.documentName = event.data.docName;
  const dialogRef = dialog.open(OrdersEditComponent, {
    props: {
      header: "Auftrag",
      maximizable: true,
      subHeader: isDraft
        ? "(Entwurf)"
        : isFromOffer
        ? "(Entwurf Auftrag erstellt von Angebot " + event.data.docName + ")"
        : event.data.docName,
      style: {
        width: "",
      },
      breakpoints: {
        // breakpoints so lassen! bei fragen an abdullah
        "2135px": "48vw",
        "1800px": "50vw",
        "1600px": "60vw",
        "1460px": "70vw",
        "1336px": "75vw",
        "1000px": "90vw",
        "831px": "100vw",
      },
      modal: true,
    },
    templates: {
      footer: markRaw(OrderEditFooter),
      header: markRaw(OrderEditHeader),
    },
    data: {
      order: {
        isLoading: loading.value,
        isEditable: isDuplicated || isDraft,
        isCancelled: isCancelled,
        isCloned: isCloned,
        isFromOffer: true,
        isDraft: isDraft,
        orderDocumentId: event.data.orderDocumentId
          ? event.data.orderDocumentId
          : event.data.orderDocumentId,
        docName: !isDuplicated ? event.data.docName : null,
        readOnly: (event.data.orderDocumentId != null) & !isDraft,
        storno: isCancelled,
      },
      auftragsDaten: event.data,
    },
    onClose: async (options) => {
      console.log("🚀 ~ file: OffersView.vue:550 ~ options:", options);

      const buttonType = options?.data?.buttonType;
      if (options.data && isDraft) {
        if (buttonType == "Save") {
        } else if (buttonType == "Delete") {
          console.log("order deleted1");
          refreshReRenderTable();
        }
        refreshReRenderTable();
      } else if (buttonType == "Cancellation") {
        cloneOrder(event, "Cancellation", dialogRef);
      } else if (buttonType == "Clone") {
        cloneOrder(event, "Clone", dialogRef);
      } else if (buttonType == "GoToOffer") {
        openOffer(options.data.offerDocumentId);
      } else if (buttonType == "GoToOrder") {
        openOrder(options.data.orderDocumentId);
      } else confirmChangeWindow("Aufträge");
    },
  });
};

const confirmChangeWindow = (proceedType) => {
  let url="";
  switch (proceedType) {
    case "Rechnungen":
      url="/InvoicesView";
    break;
    case "Aufträge":
      url="/OrdersView"
    break;
  }

  confirm.require({
    message:
      "Möchten Sie zum Bereich "+proceedType+" wechseln, Sie befinden sich in Angebote?",
    header: "Angebot verlassen",
    icon: "pi pi-exclamation-triangle",
    acceptClass: "p-button-danger",
    acceptLabel: "Ja",
    rejectLabel: "Nein",
    defaultFocus: "reject",
    accept: () => {
      store.headerTitel = proceedType.toUpperCase();
      router.push(url);
    },
    reject: () => {},
  });
};


const openInvoice = (invoiceDocumentId, proceedType,isDraft) => {
  console.log("openinvoice");
  let url = "/company/the-invoice/" + invoiceDocumentId;
  if (isDraft) url = "/company/the-invoice-draft/" + invoiceDocumentId;
  iAxios.get(url).then((response) => {
    console.log("🚀 ~ file: OrdersView.vue:1012 ~ response:", response);

    if (response.data) {
      openInvoiceDialog(
        { data: response.data },proceedType,
        response.data.eventAction == "INVOICE"
      );
    }
  });
};


const openOrder = (orderDocumentId) => {
  console.log(
    "🚀 ~ file: OffersView.vue:599 ~ orderDocumentId:",
    orderDocumentId
  );

  console.log("openorder");
  iAxios.get("/company/the-order/" + orderDocumentId).then((response) => {
    if (response.data) {
      openOrderDialog({ data: response.data });
    }
  });
};

const cloneInvoice = async (event, proceedType, dialogRef) => {
  const copyEvent = JSON.parse(JSON.stringify(event));
  const rechnungsDatenCopy = copyEvent.data;
  let docNameFrom = rechnungsDatenCopy.docName;
  let invoiceDocumentIdFrom = rechnungsDatenCopy.invoiceDocumentId;
  event.data.docContent.documentDatum = new Date().toISOString().slice(0, 10);
  event.data.docName = null;
  event.data.invoiceDocumentId = null;
  event.data.invoiceDocumentId = null;
  event.data.storno = true;
  ///TODO open paid status setzen
  switch (proceedType) {
    case "Cancellation":
      {
        event.data.docContent.invoiceTitel = "Rechnungskorrektur/ Gutschrift";
        event.data.documentStatus = "INVOICE_CANCELED";
        event.data.docContent.invoiceIntroduction =
          " Sie erhalten anbei die Rechnungskorrektur/ Gutschrift zur Rechnung " +
          docNameFrom;
        event.data.relationFrom = {
          invoiceCancelledFromId: dinvoiceDocumentIdFrom,
          invoiceCancelledDocName: docNameFrom,
        };
        const itemList = event.data.itemList;
        itemList.forEach((item) => {
          item.quantity = item.quantity * -1;
        });
      }
      break;
    case "Clone":
      {
        event.data.docContent.invoiceTitel = "Rechnung";
        event.data.documentStatus = "INVOICE_OPEN";
        event.data.docContent.invoiceIntroduction =
          " Sie erhalten anbei unsere Rechnung.";
        event.data.relationFrom = {
          duplicatedFromId: invoiceDocumentIdFrom,
          duplicatedFromDocName: docNameFrom,
        };
        event.data.isNew = true;
      }
      break;
  }
  openInvoiceDialog(event, proceedType,true);
};


const cloneOrder = async (event, proceedType, dialogRef) => {
  const copyEvent = JSON.parse(JSON.stringify(event));
  const auftragsDatenCopy = copyEvent.data;
  let docNameFrom = auftragsDatenCopy.docName;
  let orderDocumentIdFrom = auftragsDatenCopy.orderDocumentId;
  event.data.docContent.documentDatum = new Date().toISOString().slice(0, 10);
  event.data.docName = null;
  event.data.orderDocumentId = null;
  event.data.orderDocumentId = null;
  event.data.storno = true;
  ///TODO open paid status setzen
  switch (proceedType) {
    case "Cancellation":
      {
        event.data.docContent.orderTitel = "Auftragskorrektur/ Gutschrift";
        event.data.documentStatus = "ORDER_CANCELED";
        event.data.docContent.orderIntroduction =
          " Sie erhalten anbei die Auftragskorrektur/ Gutschrift zur Auftrag " +
          docNameFrom;
        event.data.relationFrom = {
          orderCancelledFromId: dorderDocumentIdFrom,
          orderCancelledDocName: docNameFrom,
        };
        const itemList = event.data.itemList;
        itemList.forEach((item) => {
          item.quantity = item.quantity * -1;
        });
      }
      break;
    case "Clone":
      {
        event.data.docContent.orderTitel = "Auftrag";
        event.data.documentStatus = "ORDER_OPEN";
        event.data.docContent.orderIntroduction =
          " Sie erhalten anbei unsere Auftrag.";
        event.data.relationFrom = {
          duplicatedFromId: orderDocumentIdFrom,
          duplicatedFromDocName: docNameFrom,
        };
        event.data.isNew = true;
      }
      break;
  }
  openOrderDialog(event, proceedType);
};

const getActionLabel = (label) => {
  let status = statuses.find((o) => o.value == label);
  if (!status) {
    return label;
  }
  return status.label;
};

const formatDate = (value) => {
  const date = new Date(value);
  return date.toLocaleDateString("de-DE", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
};

async function zeitMessen(funktion) {
  const start = performance.now();
  await funktion();
  const end = performance.now();
  const dauer = end - start;
  console.log(`OffersView Komplett geladen in ${dauer / 1000} Sekunden.`);
}

const MenuItems = ref([
  {
    label: "Exportieren",
    icon: "pi pi-download",
    command: () => {
      dataTableRef.value.exportCSV();
      toast.add({
        severity: "success",
        summary: "Exportieren",
        detail: "Exportierte Daten herunterladen",
        life: 3000,
      });
    },
  },
  {
    label: "Neues Angebot erstellen",
    icon: "pi pi-file",
    command: () => {
      handleNewRow({ data: {} });
    },
  },
]);

let allOffers = null;
async function werteLaden() {
  loading.value = true;
  await OffersService.getOffers().then((data) => {
    if (data.offers) {
      allOffers = data.offers.map((offer) => ({
        ...offer,
        name1: offer.receiver.name1,
        postCode: offer.receiver.plz,
        documentValueBrutto: formatCurrencyWithZero(
          offer.docContent.documentValueBrutto
        ),
        documentValueNetto: formatCurrencyWithZero(offer.docContent.documentValueNetto),
      }));
    }
  });
  // Gefilterte Angebote (ohne OFFER_DRAFT_OPEN)
  //offers.value = allOffers.value.filter(offer => offer.documentStatus !== 'OFFER_DRAFT_OPEN');
  //vielleicht später je nachdem ob entwürfe beim anfang ausgefiltert werden sollen

  if (customerUid && allOffers) {
    offers.value = await allOffers.filter(
      (d) => d.customerUid === customerUid
    );
  } else offers.value = allOffers;

  loading.value = false;
}

function resetFilter() {
  // Alle Angebote anzeigen, einschließlich OFFER_DRAFT_OPEN
  offers.value = allOffers;
}

const checkMobile = () => {
  isMobile.value = window.innerWidth <= 768; // Anpassen der Bildschirmbreite nach Bedarf
};

onMounted(() => {
  console.log("Filter for created_de:", filters.value.created_de); // Überprüfen Sie, ob die filterFunction korrekt gesetzt ist

  checkMobile();
  window.addEventListener("resize", checkMobile);
});

onBeforeMount(async () => {
  await zeitMessen(werteLaden);
  //await werteLaden();
});

function closePreview() {
  pdfFile.value = null;
}
</script>

<style scoped>
:deep(.editable-cells-table td.p-cell-editing) {
  padding-top: 0;
  padding-bottom: 0;
}

:deep(.titelline) {
  border-color: #8085a1;
  border-right-width: 0 !important;
  border-bottom-width: 0 !important;
  border-style: solid;
}

:deep(.titel) {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.5rem;
  padding: 40px 0px 0px 50px;
  text-align: left;
  line-height: 0;
}

:deep(.p-card .p-card-title) {
  font-size: 1.2rem;
  font-weight: 400;
}

@media (max-width: 768px) {
  :deep(.p-fluid .p-inputtext) {
    width: 100%;
    min-width: 52px;
  }

  :deep(.p-inputtext) {
    padding: 0.75rem 0.25rem 0.75rem 0.25rem;
  }
}

:deep(.p-datatable .p-datatable-tbody) {
  padding: 0;
}

:deep(.p-inputswitch) {
  width: 3rem;
  height: 1.45rem;
  margin-top: 2px;
  top: -2px;
}

.no-outline:focus {
  outline: none !important;
  box-shadow: none !important;
}
</style>

<style>
/* nicht löschen als beispiel zum nachsehen wegen zeilenzwischenruam */
/* .custom-datatable table {
  border-spacing: 0 0px; 
}
.custom-datatable .p-datatable-tbody {
  border-spacing: 0; 
}
.custom-datatable .p-datatable-tbody > tr {
  margin: 0; 
}
.custom-datatable .p-datatable-tbody > tr > td {
  border-bottom: 1px solid #e0e0e0; 
}
.custom-datatable .p-datatable-tbody > tr:last-child > td {
  border-bottom: none;
} */
.custom-datatable .p-datatable-tbody > tr > td {
  border-bottom: 0px solid #e0e0e0; 
}
.custom-datatable table {
  border-spacing: 0 4px; 
}
</style>

