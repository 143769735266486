import iAxios from "@/store/axiosInterface";
import download from 'downloadjs'
import { userStore } from "@/store/userStore";
import { storeToRefs } from 'pinia';



//formatiert das Datum nach dem Schema: dd.mm.jjjj
const formatDatum = (datum) => {
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    const date = new Date(datum);
    return date.toLocaleDateString(undefined, options);
};

function createAddressString(deliverTo) {
    let addressParts = [
        deliverTo.name1,
        deliverTo.name2,
        deliverTo.address1,
        deliverTo.address2,
        deliverTo.postCode,
        deliverTo.city
    ];
    addressParts = addressParts.filter(part => part != null);
    return addressParts.join(" ");
}


/**
 * Speichert die Gutschriftsdaten und erhält die PDF-Datei.
 * @param {Object} injectedValue - Die injizierten Werte, die die Gutschriftsdaten enthalten.
 * @param {string} proceedType - Der Vorgangstyp, der angibt, wie die Funktion ausgeführt werden soll.
 *                   Falls Entwurf vorhanden, wird der Entwurf überschrieben, falls nicht wird ein neuer Entwurf erstellt.
 * Mögliche Werte:  "save" => default bei null speichert und lädt direkt runter. Erstellt eine Gutschrift.
 *                  "print"=> direkt Ausdruck, ohne speichern,
 *                  "saveDraftAndPreview"=>Vorschau mit speichern,  
 *                  "previewOnly"=>Vorschau ohne speichern als Entwurf, 
 *                  "download=>nur download ohne speichern".
 * @param {boolean} preview - Gibt an, ob eine Vorschau angezeigt werden soll.
 * @returns {Promise} - Ein Promise-Objekt, das die Antwort der Serveranfrage enthält.
 */
export const saveAndGetPDF = async (injectedValue, proceedType, preview, setDownload) => {
    const store = userStore();
    injectedValue.data.loading = true;
    console.log("🚀 ~ file: creditnoteUtils.js:69 ~ saveAndGetPDF ~ setDownload:", setDownload)


    console.log("🚀 ~ file: creditnoteUtils.js:69 ~ saveAndGetPDF ~ preview:", preview)


    console.log("🚀 ~ file: creditnoteUtils.js:69 ~ saveAndGetPDF ~ proceedType:", proceedType)


    console.log("🚀 ~ file: creditnoteUtils.js:69 ~ saveAndGetPDF ~ injectedValue:", injectedValue)

    //console.clear();
    const isDraftToCreditNote = (proceedType === "draftToCreditNote");
    const isCreditNote = (proceedType === "saveCreditNote");
    const saveCreditNote = isCreditNote || isDraftToCreditNote;
    const saveDraft = (proceedType === "saveDraft");
    const readOnly = injectedValue.data.creditnote.readOnly;
    const print = (proceedType === "print");
    const printCreditNote = (print && readOnly);
    const previewOnly = (proceedType === "previewOnly");
    const downloadOnly = (proceedType === "download");
    const downloadDraft = (proceedType === "downloadDraft");
    const isDownload = (proceedType === "download") || setDownload;
    const deleteDraft = (proceedType === "deleteDraft");
    const gutschriftsDaten = injectedValue.data.gutschriftsDaten;
    const creditNoteDocumentId = injectedValue.data.creditnote.creditNoteDocumentId;
    const iscreditnoteDocumentId = creditNoteDocumentId != null;
    const overwriteDraft = iscreditnoteDocumentId && saveDraft;
    const documentDownlaod = creditNoteDocumentId ? creditNoteDocumentId + "/download" : "";
    const isCancelled = injectedValue.data.creditnote.isCancelled;
    const isDraft = injectedValue.data.creditnote.isDraft;
    const isCloned = injectedValue.data.creditnote.isCloned;

    console.log("🚀 ~ file: creditnoteUtils.js:104 ~ njectedValue.data.creditnote?:", injectedValue.data)
    const deliverToAddresse = ((gutschriftsDaten?.deliverTo?.name1)
        ? createAddressString(gutschriftsDaten.deliverTo)
        : '=Rechnungsanschrift');
    if (true) {
        gutschriftsDaten.docContent.creditnoteFooterNotes = "Lieferanschrift: " + deliverToAddresse;

    };

    if (store.companySettings.showEanInDocuments) {
        console.log("showean dingens")
        gutschriftsDaten.itemList?.forEach((item) => {
            if (item?.EAN) {
                item.itemDescription = item?.itemDescription?.replaceAll("EAN: " + item.EAN + " / ", "");
                item.itemDescription = "EAN: " + item?.EAN + " / " + item?.itemDescription;
            }
        });
    }
    const docId = creditNoteDocumentId != null ? "/" + creditNoteDocumentId : '';

    console.log("🚀 ~ file: creditnoteUtils.js:102 ~ saveAndGetPDF ~ creditNoteDocumentId:", creditNoteDocumentId)


    console.log("🚀 ~ file: creditnoteUtils.js:102 ~ saveAndGetPDF ~ isDraftToCreditNote:", isDraftToCreditNote)


    console.log("🚀 ~ file: creditnoteUtils.js:102 ~ saveAndGetPDF ~ saveCreditNote:", saveCreditNote)
/* 
    gutschriftsDaten.docContent.documentTitel = gutschriftsDaten.docContent.creditnoteTitel;
    gutschriftsDaten.docContent.documentIntroduction = gutschriftsDaten.docContent.creditNoteIntroduction;
    gutschriftsDaten.docContent.documentFinalText = gutschriftsDaten.docContent.creditNoteFinalText; */
    gutschriftsDaten.as_attachment = isDownload;
    //delete flatRechnung.productItemList;
    let url = "/company";
    switch (proceedType) {
        case "previewOnly": url += isDraft?"/download-creditnote-draft" + docId:"/download-creditnote" + docId; break;
        case "saveCreditNote": url += "/create-creditnote"; break;
        case "draftToCreditNote": url += "/draft-to-creditnote" + docId; break;
        case "saveDraft": url += "/create-creditnote-draft" + docId; break;
        case "downloadDraft": url += "/create-creditnote-draft" + docId; break;
        case "saveDraftAndPreview": url += "/create-creditnote-draft"; break;
        case "print": (readOnly ? url += "/download-creditnote" + docId : url += "/create-creditnote-draft" + docId); break;
        case "download": (readOnly ? url += "/download-creditnote/" + documentDownlaod : url += "/create-creditnote-draft/") + documentDownlaod; break;
        case "deleteDraft": break;
        default: console.log("Unbehandelter Proceed Type!"); break;
    }
    console.log("🚀 ~ file: creditnoteUtils.js:132 ~ saveAndGetPDF ~ url:", url)
    console.log("🚀 ~ file: creditnoteUtils.js:133 ~ saveAndGetPDF ~ docId:", docId)

    //patch
    if ((docId && !isCreditNote && !isDraftToCreditNote && !printCreditNote && !previewOnly && !downloadOnly) || downloadDraft || overwriteDraft) {
        await iAxios.patch(url, gutschriftsDaten, {
            responseType: 'blob',
        })
            .then((response) => {

                console.log("🚀 ~ file: creditnoteUtils.js:142 ~ .then ~ response:", response)

                setDocNameFromHeader(response, injectedValue);

                const blob = new Blob([response.data], { type: 'application/pdf' });
                const fileURL = URL.createObjectURL(blob);
                switch (proceedType) {
                    case "saveDraft": if (preview) {
                        injectedValue.data.pdfFile = fileURL; break;
                    } else {
                        break;
                    }
                    case "download": download(response.data, "Gutschrift_" + gutschriftsDaten.receiver.name1 + "_" + formatDatum(gutschriftsDaten.docContent.documentDatum) + ".pdf", "application/pdf"); break;
                    case "previewOnly": injectedValue.data.pdfFile = fileURL; break;
                    case "saveDraftAndPreview": injectedValue.data.pdfFile = fileURL; break;
                    case "print": { printPDF(fileURL); break; }

                    default:
                        {
                            downloadPdf(blob, gutschriftsDaten, injectedValue); break;
                        }
                }
            })

    }
    //get
    else if (isDraftToCreditNote || printCreditNote || previewOnly || downloadOnly) {
        await iAxios.get(url, {


            responseType: 'blob',
        })
            .then((response) => {
                console.log("🚀 ~ file: creditnoteUtils.js:153 ~ saveAndGetPDF ~ url:", url)

                console.log("🚀 ~ file: creditnoteUtils.js:156 ~ .then ~ response:", response)

                const blob = new Blob([response.data], { type: 'application/pdf' });
                const fileURL = URL.createObjectURL(blob);
                switch (proceedType) {
                    case "draftToCreditNote":
                        setDocNameFromHeader(response, injectedValue);
                        if (isDownload) {
                            download(response.data, "Gutschrift_" + gutschriftsDaten.receiver.name1 + "_" + formatDatum(gutschriftsDaten.docContent.documentDatum) + ".pdf", "application/pdf"); break;
                        } else if (preview) {
                            injectedValue.data.pdfFile = fileURL; break;
                        } else {
                            break;
                        }
                    case "print": { console.log("print"); printPDF(fileURL); break; }
                    case "previewOnly": injectedValue.data.pdfFile = fileURL; break;
                    case "downloadOnly": download(response.data, "Gutschrift_" + gutschriftsDaten.receiver.name1 + "_" + formatDatum(gutschriftsDaten.docContent.documentDatum) + ".pdf", "application/pdf"); break;

                    default:
                        {
                            downloadPdf(blob, gutschriftsDaten, injectedValue); break;
                        }
                }
            })
    }
    else {
        //post
        console.log("gutschriftsDaten", gutschriftsDaten);
        await iAxios.post(url, gutschriftsDaten, {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/pdf'
            }
        }).then((response) => {
            console.log("🚀 ~ file: creditnoteUtils.js:211 ~ gutschriftsDaten:", gutschriftsDaten);
            console.log("🚀 ~ file: creditnoteUtils.js:212 ~ saveAndGetPDF ~ response:", response);

            setDocNameFromHeader(response, injectedValue);
            const blob = new Blob([response.data], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(blob);
            switch (proceedType) {
                case "print": printPDF(fileURL); break;
                case "saveCreditNote":
                    if (gutschriftsDaten.forShipping) {
                        sendToShippingList(injectedValue.data.gutschriftsDaten);
                    }
                    if (preview) {
                        injectedValue.data.pdfFile = fileURL;
                        injectedValue.data.creditnote.isDraft = false;
                        break;
                    } else {
                        injectedValue.data.creditnote.isDraft = false;
                        break;
                    } case "saveDraft": if (preview) {
                        injectedValue.data.pdfFile = fileURL; break;
                    } else {
                        break;
                    }
                case "download": downloadPdf(blob, gutschriftsDaten, injectedValue); break;
                case "previewOnly": injectedValue.data.pdfFile = fileURL; break;
                case "saveDraftAndPreview": injectedValue.data.pdfFile = fileURL; break;
                default:
                    {
                        downloadPdf(blob, gutschriftsDaten, injectedValue); break;
                    }
            }
            if (isCancelled && isCreditNote) {
                console.log("🚀 ~ file: creditnoteUtils.js:210 ~ saveAndGetPDF ~ injectedValue.data:", injectedValue.data)

                setDocumentStatus(injectedValue.data.gutschriftsDaten.relationFrom.creditnoteCancelledFromId, "CREDITNOTE_CANCELED");
                console.log("🚀 ~ file: creditNoteUtils.js:243 ~ injectedValue.data.gutschriftsDaten.relationFrom.creditnoteCancelledFromId:", injectedValue.data.gutschriftsDaten.relationFrom.creditnoteCancelledFromId)

                setDocumentStatus(injectedValue.data.gutschriftsDaten.creditNoteDocumentId, "CREDITNOTE_CORRECTION");
                console.log("🚀 ~ file: creditNoteUtils.js:245 ~ injectedValue.data.gutschriftsDaten.creditNoteDocumentId:", injectedValue.data.gutschriftsDaten.creditNoteDocumentId)

                setRelationToCreditNote(injectedValue.data.gutschriftsDaten);
            }





        })
            .catch((error) => {
                console.error(error);
            });
    }
    injectedValue.data.loading = false;

    return injectedValue;
};


const setRelationToCreditNote = async (gutschriftsDaten) => {
    console.log("setRelationToCreditNote", gutschriftsDaten);

    const creditNoteDocumentId = gutschriftsDaten.relationFrom.creditnoteCancelledFromId;
    const relationToId = gutschriftsDaten.creditNoteDocumentId;
    const relationToDocName = gutschriftsDaten.relationFrom.docName;
    let url = process.env.VUE_APP_BASE_API_URL + "/company/creditnote-edit";
    const bodyData =
    {
        "creditNoteDocumentId": parseInt(creditNoteDocumentId),
        "relationTo": { creditnoteCorrectionId: relationToId, creditnoteCorrectionDocName: relationToDocName },
        "createShipping": false,
    };
    let response = await iAxios.patch(url, bodyData)
        .then((response) => {
        })
        .catch((error) => {
            console.error(error);
        });
    return true;
};

/* const setRelationToCreditNote = async (creditNoteDocumentId, relationToId, relationToDocName) => {
    let url = process.env.VUE_APP_BASE_API_URL + "/company/creditnote-edit";
    const bodyData =
    {
        "creditNoteDocumentId": parseInt(creditNoteDocumentId),
        "relationTo": { creditnoteCorrectionId: relationToId, creditnoteCorrectionDocName: relationToDocName },
        "createShipping": false,
    };
    let response = await iAxios.patch(url, bodyData)
        .then((response) => {
        })
        .catch((error) => {
            console.error(error);
        });
    return true;
}; */


const setDocNameFromHeader = (response, injectedValue) => {
    console.log('Alle Header:');
    Object.entries(response.headers).forEach(([name, value]) => {
        console.log(`${name}: ${value}`);
    });

    // Header auslesen
    if (typeof response.headers === 'object' && response.headers !== null) {

        console.log("🚀 ~ file: creditNoteUtils.js:311 ~ response.headers:", response.headers)

        injectedValue.data.gutschriftsDaten.creditNoteDocumentId = response.headers.creditnotedocumentid;
        injectedValue.data.creditnote.creditNoteDocumentId = response.headers.creditnotedocumentid;
        injectedValue.data.gutschriftsDaten.docName = response.headers.creditnotedocname;
    } else {
        console.log('Die Header-Informationen sind nicht verfügbar.');
    }
}

const downloadPdf = (blob, gutschriftsDaten, injectedValue) => {
    console.log("🚀 ~ file: creditnoteUtils.js:285 ~ downloadPdf ~ injectedValue:", injectedValue)

    download(blob, "Gutschrift_" + gutschriftsDaten.receiver.name1 + "_" + formatDatum(gutschriftsDaten.docContent.documentDatum) + "_" + gutschriftsDaten.docName + ".pdf", "application/pdf");
};



/**Vorraussetzung eines iframe mit der id=pdfFrame setzen in der Komponente von der aus der Druck aufgerufen wird.
 * <iframe id="pdfFrame" style="display:none"></iframe>
 * @param {*} pdfFile -url der PDF-Datei.
 * @returns
 */
export const printPDF = async (pdfFile) => {
    const iframe = document.getElementById('pdfFrame');
    iframe.src = pdfFile;

    // Warten, bis die PDF-Datei geladen wurde
    await new Promise((resolve) => {
        iframe.onload = resolve;
    });
    iframe.contentWindow.print();
};

export const setDocumentStatus = async (creditNoteDocumentId, status) => {
    console.log("🚀 ~ file: creditnoteUtils.js:294 ~ setDocumentStatus ~ status:", status)
    console.log("🚀 ~ file: creditnoteUtils.js:295 ~ setDocumentStatus ~ creditNoteDocumentId:", creditNoteDocumentId)
    if (isNaN(creditNoteDocumentId)) {
        console.error("creditNoteDocumentId ist keine Zahl!: " + creditNoteDocumentId);
        return false;
    }
    let url = process.env.VUE_APP_BASE_API_URL + "/company/creditnote-status";
    const formData = new FormData();
    formData.append("creditNoteDocumentId", parseInt(creditNoteDocumentId));
    formData.append("documentStatus", status);
    let response = await iAxios.post(url, formData)
        .then((response) => {
            //  console.log("🚀 ~ file: creditnoteUtils.js:18 ~ .then ~ response:", response)
        })
        .catch((error) => {
            console.error(error);
        });
    return true;
};

export const setCreditNotePayStatus = (gutschriftsDaten) => {
    const payProgress = gutschriftsDaten?.payProgress;
    const payInput = payProgress?.paymentInput;
    let totalAmount = gutschriftsDaten?.docContent?.documentValueBrutto;
    if (!payProgress || !payInput) return { totalPaid: 0, status: "OFFEN" };
    let totalPaid = 0.0;
    payInput.forEach((payment) => {
        if (payment.event != "PAYMENT_DELETED") {
            totalPaid += payment.paymentAmount;
            }
    });
    totalPaid = parseFloat(totalPaid.toFixed(2)); // ist nötig wegen Rundungsfehler in javascript
    totalAmount = parseFloat(totalAmount.toFixed(2));
    if (totalPaid >= totalAmount) {
        setDocumentStatus(gutschriftsDaten.creditNoteDocumentId, "CREDITNOTE_PAID");
        return { totalPaid: totalPaid, status: "BEZAHLT" };
    } if (totalPaid < totalAmount && totalPaid > 0) {
        setDocumentStatus(gutschriftsDaten.creditNoteDocumentId, "CREDITNOTE_PARTIAL");
        return { totalPaid: totalPaid, status: "TEILZAHLUNG" };
    }
    setDocumentStatus(gutschriftsDaten.creditNoteDocumentId, "CREDITNOTE_OPEN");
    return { totalPaid: totalPaid, status: "OFFEN" };
};