<template>
  <div class="container">
    <div class="banner">
      <h2>OVIsync UG</h2>
    </div>
    <div class="content">
      <h2 v-if="!connectedAccountId">Get ready for take off</h2>
      <p v-if="!connectedAccountId">OVIsync UG is the world's leading air travel platform: join our team of pilots to help people travel faster.</p>
      <h2 v-if="connectedAccountId">Add information to start accepting money</h2>
      <p v-if="connectedAccountId">OVIsync UG partners with Stripe to help you receive payments while keeping your personal and bank details secure.</p>
      <div v-if="!accountCreatePending && !connectedAccountId">
        <button @click="createAccount">Sign up</button>
      </div>
      <div>
        <button @click="createAccountLink">Add information</button>
      </div>
      <p v-if="error" class="error">Something went wrong!</p>
      <div v-if="connectedAccountId || accountCreatePending || accountLinkCreatePending" class="dev-callout">
        <p v-if="connectedAccountId">Your connected account ID is: <code class="bold">{{ connectedAccountId }}</code></p>
        <p v-if="accountCreatePending">Creating a connected account...</p>
        <p v-if="accountLinkCreatePending">Creating a new Account Link...</p>
      </div>
      <div class="info-callout">
        <p>
          This is a sample app for Stripe-hosted Connect onboarding. 
          <a href="https://docs.stripe.com/connect/onboarding/quickstart?connect-onboarding-surface=hosted" 
             target="_blank" rel="noopener noreferrer">View docs</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import iAxios from '@/store/axiosInterface';
import { ref } from 'vue';

const accountCreatePending = ref(false);
const accountLinkCreatePending = ref(false);
const error = ref(false);
const connectedAccountId = ref(null);

const createAccount = async () => {
  accountCreatePending.value = true;
  error.value = false;
  
  try {
    const response = await iAxios.post("/company/stripe/account");
    accountCreatePending.value = false;
    connectedAccountId.value = response.data.account;
  } catch (err) {
    accountCreatePending.value = false;
    error.value = true;
  }
};

const createAccountLink = async () => {
  accountLinkCreatePending.value = true;
  error.value = false;
  
  try {
    const response = await iAxios.get("/company/stripe/account_link", {
      account: connectedAccountId.value,
    });
    
    accountLinkCreatePending.value = false;
    
    const { url, error: fetchError } = response.data;
    if (url) {
      window.location.href = url;
    }
    
    if (fetchError) {
      error.value = true;
    }
  } catch (err) {
    accountLinkCreatePending.value = false;
    error.value = true;
  }
};
</script>
