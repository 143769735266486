<template>
  <template v-if="!store.rules.activatedModules.settings">
    <UpgradeLicence />
  </template>
  <template v-else>
    <div>
      <!--  <InputSwitch v-model="debug" @update:model-value="store.setDebug(debug)" /> Debug -->
      <Dialog
        maximizeicon
        v-model:visible="pdfPreview"
        modal
        header="Vorschau"
        :breakpoints="{ '475px': '95vw' }">
        <iframe
          :style="{ width: '50rem', height: '75rem' }"
          :breakpoints="{ '475px': '95vw' }"
          :src="pdfPreview" />
      </Dialog>

      <div>
        <Message :closable="false" v-show="!changedNotSaved" severity="success"
          >Stand: Aktuell.</Message
        >
        <Message :closable="false" v-show="changedNotSaved" severity="info"
          >Stand: Nicht gespeichert</Message
        >
      </div>
      <div class="flex justify-content-end">
        <div>
          <Button
            icon="pi pi-eye"
            outlined
            size="small"
            severity="info"
            label="Vorschau"
            :loading="loading"
            @click="previewForm" />
          <Button
            icon="pi pi-save"
            size="small"
            label="Speichern"
            @click="saveForm" />
        </div>
      </div>
      <Fieldset
        v-if="isMainUser"
        ref="companyLogoRef"
        legend="Firmenlogo & Absender"
        :toggleable="true"
        class="field col-12"
        :collapsed="false"
        :pt="{
          legend: {
            class: 'p-0 m-0 bg-white border-none',
            style: { left: '-25px!important', position: 'relative' },
          },
          togglerIcon: { class: 'p-0 m-0 hidden' },
          toggleableContent: { class: 'p-0 m-0 bg-gray-100' },
          toggler: { class: 'py-0 my-0' },
          root: { class: 'p-0 my-0' },
        }">
        <template #legend>
          <div class="flex align-items-center gap-0 px-0">
            <i
              class="text-xl mx-3"
              :class="
                companyLogoRef?.d_collapsed
                  ? 'pi pi-chevron-down'
                  : 'pi pi-chevron-up'
              "></i>
            <span class="text-lg">Firmenlogo & Absender</span>
          </div>
        </template>
        <p>
          Hier können Sie Ihr Firmenlogo hochladen, das auf Dokumenten wie
          Rechnungen und Angeboten angezeigt wird.
        </p>

        <div class="grid">
          <div class="col">
            <div class="card">
              <FileUpload
                mode="basic"
                class="text-xs w-11rem"
                name="logo[]"
                url="/api/upload"
                accept="image/*"
                :maxFileSize="500000"
                customUpload
                @uploader="customBase64Uploader($event, 'logo', false)"
                :auto="true"
                chooseLabel="Logo Hochladen" />
              <input type="file" @change="uploadLogo" />
            </div>
            <div>
              <Button
                class="text-xs w-11rem"
                icon="pi pi-trash"
                severity="danger"
                outlined
                label="entfernen"
                @click="removeLogo" />
            </div>
            <p></p>
            <div class="grid flex align-items-center">
              <div class="col-4">
                <span class="">Position</span>
              </div>
              <div class="col-8 md:col-5">
                <Dropdown
                  v-model="formData.companyLogoPosition"
                  :options="logoPositionOptions"
                  optionLabel="label"
                  class="w-full"
                  inputClass="text-xs"
                  optionValue="value"
                  @change="changeLogoImageClass" />
              </div>
              <div class="col-4">
                <span class="">Größe</span>
              </div>
              <div class="col-8 md:col-5">
                <Dropdown
                  v-model="formData.companyLogoSize"
                  :options="logoSizeOptions"
                  class="w-full"
                  inputClass="text-xs"
                  optionLabel="label"
                  optionValue="value"
                  @change="changeLogoImageClass" />
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="container">
              <!-- 
                        <Image v-if="showLogo" :src="companyLogoUrl || require('@/assets/beispielLogo.png')"
                            :class="imagePositionClass" :imageClass="imageSizeClass" alt="Image" @load="onImageLogoLoad" />

                        <img ref="svgImage" :src="formData.showSenderLine ? require('@/assets/BriefbogenOben-min.svg') : require('@/assets/noSenderLine.svg')
                            " imageClass="surface-card shadow-4 w-auto" alt="Image" class="w-full"
                            @load="onImageBriefbogenLoad" /> -->

              <img
                ref="logoImageRef"
                v-if="formData.showCompanyLogo"
                :src="companyLogoUrl || require('@/assets/beispielLogo.png')"
                :class="[imagePositionClass, imageSizeClass]"
                alt="Image"
                @load="onImageLogoLoad" />

              <img
                ref="svgImage"
                :src="
                  formData.showSenderLine
                    ? require('@/assets/BriefbogenOben-min.svg')
                    : require('@/assets/noSenderLine-min.svg')
                "
                class="surface-card shadow-4"
                alt="Image"
                @load="onImageBriefbogenLoad" />
            </div>
          </div>
          <div class="col-12 grid">
            <div class="col-10 md:col-5">Firmen-Logo in Dokumeten anzeigen</div>
            <div class="col-1 md:col-3">
              <InputSwitch v-model="formData.showCompanyLogo" />
            </div>
            <div class="col-10 md:col-5">Firmen-Logo in Emails anzeigen</div>
            <div class="col-1 md:col-3">
              <InputSwitch v-model="formData.showCompanyLogoInEmails" />
            </div>
            <div class="col-10 md:col-5">Absenderzeile anzeigen</div>
            <div class="col-1 md:col-3">
              <InputSwitch v-model="formData.showSenderLine" />
            </div>
          </div>
        </div>
      </Fieldset>
      <p></p>
      <Fieldset
        v-if="isMainUser"
        ref="tipsRef"
        legend="Tips/Schnellstart"
        :toggleable="true"
        class="field col-12"
        :collapsed="true"
        :pt="{
          legend: {
            class: 'p-0 m-0 bg-white border-none',
            style: { left: '-25px!important', position: 'relative' },
          },
          togglerIcon: { class: 'p-0 m-0 hidden' },
          toggleableContent: { class: 'p-0 m-0 bg-gray-100' },
          toggler: { class: 'py-0 my-0' },
          root: { class: 'p-0 my-0' },
        }">
        <template #legend>
          <div class="flex align-items-center gap-0 px-0">
            <i
              class="text-xl mx-3"
              :class="
                tipsRef?.d_collapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up'
              "></i>
            <span class="text-lg">Tips/Schnellstart</span>
          </div>
        </template>
        <TipsSettings />
      </Fieldset>
      <p></p>
      <Fieldset
        v-if="isMainUser"
        ref="taxRef"
        legend="Steuersätze"
        :toggleable="true"
        class="field col-12"
        :collapsed="true"
        :pt="{
          legend: {
            class: 'p-0 m-0 bg-white border-none',
            style: { left: '-25px!important', position: 'relative' },
          },
          togglerIcon: { class: 'p-0 m-0 hidden' },
          toggleableContent: { class: 'p-0 m-0 bg-gray-100' },
          toggler: { class: 'py-0 my-0' },
          root: { class: 'p-0 my-0' },
        }">
        <template #legend>
          <div class="flex align-items-center gap-0 px-0">
            <i
              class="text-xl mx-3"
              :class="
                taxRef?.d_collapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up'
              "></i>
            <span class="text-lg">Steuersätze</span>
          </div>
        </template>
        <div>
          <div>
            <Dropdown
              v-model="formData.defaultTaxId"
              :options="formData.taxOptions.taxOptionsData"
              optionValue="id"
              optionLabel="optionLabel"></Dropdown>
            <span>
              ist als Standard ausgewählt.<i
                class="pi pi-info-circle"
                v-tooltip="
                  'Standardmäßig ausgewählter Steuersatz, beim erstellen eines Dokuments(Angebot, Auftrag, Rechnung oder Gutschrift).'
                "
                style="cursor: pointer"></i>
            </span>
          </div>
          <p></p>
          <div class="card">
            <DataTable
              :value="formData.taxOptions.taxOptionsData"
              editMode="cell"
              class="text-xs"
              size="small"
              sortable="false"
              @cell-edit-complete="onCellEditComplete"
              :pt="{
                column: {
                  bodycell: ({ state }) => ({
                    class: [{ 'pt-0 pb-0': state['d_editing'] }],
                  }),
                },
              }">
              <Column
                v-for="col of taxColumns"
                :key="col.field"
                :header="col.header"
                :field="col.field">
                <template #body="{ data, field }">
                  <template v-if="field === 'showInPrints'">
                    <InputSwitch :binary="true" v-model="data[field]" />
                  </template>
                  <span v-if="field !== 'showInPrints'">
                    {{ data[field] }}</span
                  >
                </template>
                <template #editor="{ data, field }">
                  <template v-if="field !== 'showInPrints'">
                    <InputText v-model="data[field]" autofocus />
                  </template>
                  <template v-else>
                    <InputSwitch
                      v-model="data[field]"
                      :value="data[field]"
                      autofocus
                      inputClass="text-xs"
                      :inputId="data[field]"
                      :trueValue="true"
                      :falseValue="false" />
                  </template>
                </template>
              </Column>
              <Column
                field="action"
                header="Löschen"
                :pt="{
                  header: { class: 'text-center' },
                  bodycell: { class: 'text-center' },
                }">
                <template #body="{ data }">
                  <div class="flex justify-content-left">
                    <Button
                      icon="pi pi-trash"
                      class="p-button-rounded p-button-danger p-button-text"
                      @click="deleteTax(data)" />
                  </div>
                </template>
              </Column>
              <template #footer>
                <div class="flex">
                  <div>
                    <Button
                      icon="pi pi-plus"
                      size="small"
                      text
                      label="Eintrag Hinzufügen"
                      @click="addTaxEntry" />
                  </div>
                  <div>
                    <Button
                      icon="pi pi-info-circle"
                      severity="info"
                      size="small"
                      text
                      label="info"
                      @click="infoToggleTax" />
                  </div>
                </div>
              </template>
            </DataTable>
          </div>
        </div>
      </Fieldset>
      <p></p>

      <Fieldset
        v-if="isMainUser"
        ref="stampRef"
        legend="Stempelbild"
        :toggleable="true"
        class="field col-12"
        :collapsed="true"
        :pt="{
          legend: {
            class: 'p-0 m-0 bg-white border-none',
            style: { left: '-25px!important', position: 'relative' },
          },
          togglerIcon: { class: 'p-0 m-0 hidden' },
          toggleableContent: { class: 'p-0 m-0 bg-gray-100' },
          toggler: { class: 'py-0 my-0' },
          root: { class: 'p-0 my-0' },
        }">
        <template #legend>
          <div class="flex align-items-center gap-0 px-0">
            <i
              class="text-xl mx-3"
              :class="
                stampRef?.d_collapsed
                  ? 'pi pi-chevron-down'
                  : 'pi pi-chevron-up'
              "></i>
            <span class="text-lg">Stempelbild</span>
          </div>
        </template>
        <p>Hier können Sie ein Stempelbild hochladen.</p>
        <div class="grid">
          <div class="col-5 md:col-3">
            <div>
              <FileUpload
                mode="basic"
                name="stamp[]"
                class="w-full text-xs"
                url="/api/upload"
                accept="image/*"
                :maxFileSize="1000000"
                customUpload
                @uploader="
                  customBase64Uploader(
                    $event,
                    'stamp',
                    removeBgStamp ? 'removebg_stamp' : null
                  )
                "
                :auto="true"
                chooseLabel="Stempel Hochladen" />
            </div>
            <div>
              <Button
                class="w-full text-xs"
                icon="pi pi-trash"
                severity="danger"
                outlined
                label="entfernen"
                @click="removeStamp" />
            </div>
          </div>
          <div
            class="col-7 flex pt-0 justify-content-end md:justify-content-start align-items-center">
            <div class="p-card w-14rem">
              <Image
                v-if="Boolean(stampImage)"
                imageClass="w-14rem"
                :src="stampImage"
                alt="Image" />
              <Image
                v-else
                imageClass="w-14rem"
                :src="require('@/assets/BeispielStempel.png')"
                alt="Image" />
            </div>
          </div>
          <div class="col-12">
            <div class="py-2">
              <div class="flex align-content-center">
                <span>
                  <InputSwitch v-model="removeBgStamp" />
                </span>
                <span class="pl-3"> Hintergrund säubern </span>
              </div>
            </div>
            <div class="py-2">
              <div class="flex align-content-center">
                <span>
                  <InputSwitch
                    v-model="formData.inputStampInLetters"
                    @update:model-value="checkStampAvailable()" />
                </span>
                <span class="pl-3">Stempel in Dokumente einsetzen </span>
              </div>
            </div>
            <div class="flex align-content-center">
              <span>
                <InputSwitch v-model="formData.stamp_sign_overlay" />
              </span>
              <span class="pl-3"> Stempel und Unterschrift überlappen </span>
            </div>
          </div>
        </div>
      </Fieldset>
      <p></p>

      <Fieldset
        ref="signRef"
        legend="Unterschrift"
        :toggleable="true"
        class="field col-12"
        :collapsed="true"
        :pt="{
          legend: {
            class: 'p-0 m-0 bg-white border-none',
            style: { left: '-25px!important', position: 'relative' },
          },
          togglerIcon: { class: 'p-0 m-0 hidden' },
          toggleableContent: { class: 'p-0 m-0 bg-gray-100' },
          toggler: { class: 'py-0 my-0' },
          root: { class: 'p-0 my-0' },
        }">
        <template #legend>
          <div class="flex align-items-center gap-0 px-0">
            <i
              class="text-xl mx-3"
              :class="
                signRef?.d_collapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up'
              "></i>
            <span class="text-lg">Unterschrift</span>
          </div>
        </template>
        <p>
          Hier können Sie Ihre Unterschrift hochladen oder direkt auf dem
          Display unterschreiben und einstellen ob die Unterschrift
          Standardmäßig eingeschaltet sein soll.
        </p>

        <div class="grid">
          <div class="col-5 md:col-3">
            <handsign @signature-updated="updateSignature" /> oder
          </div>
          <div class="col-7">
            <div class="p-card w-14rem">
              <Image
                imageClass="w-14rem"
                :src="
                  signatureImage || require('@/assets/BeispielUnterschrift.png')
                "
                alt="Image" />
            </div>
          </div>
          <div class="col-5 md:col-3">
            <FileUpload
              mode="basic"
              class="text-xs"
              name="signature[]"
              url="/api/upload"
              accept="image/*"
              :maxFileSize="1000000"
              customUpload
              @uploader="
                customBase64Uploader(
                  $event,
                  'signature',
                  removeBgSign ? 'removebg_signature' : null
                )
              "
              :auto="true"
              chooseLabel="Unterschrift Hochladen" />
          </div>
          <div></div>
          <div class="col-7">
            <Button
              class="w-14rem text-xs"
              icon="pi pi-trash"
              severity="danger"
              outlined
              label="entfernen"
              @click="removeSignature" />
          </div>

          <div class="col-12">
            <div class="py-2">
              <div class="flex align-content-center">
                <span>
                  <InputSwitch v-model="removeBgSign" />
                </span>
                <span class="pl-3"> Hintergrund säubern </span>
              </div>
            </div>
            <div class="flex align-content-center">
              <span>
                <InputSwitch
                  v-model="formData.inputHandSignLetters"
                  @update:model-value="checkSignAvailable()" />
              </span>
              <span class="pl-3"> Unterschrift in Dokumente einsetzen </span>
            </div>
          </div>
        </div>
      </Fieldset>
      <p></p>

      <Fieldset
        v-if="isMainUser"
        ref="textRef"
        legend="Dokumenten-Texte"
        :toggleable="true"
        class="field col-12"
        :collapsed="true"
        :pt="{
          legend: {
            class: 'p-0 m-0 bg-white border-none',
            style: { left: '-25px!important', position: 'relative' },
          },
          togglerIcon: { class: 'p-0 m-0 hidden' },
          toggleableContent: { class: 'p-0 m-0 bg-gray-100' },
          toggler: { class: 'py-0 my-0' },
          root: { class: 'p-0 my-0' },
        }">
        <template #legend>
          <div class="flex align-items-center gap-0 px-0">
            <i
              class="text-xl mx-3"
              :class="
                textRef?.d_collapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up'
              "></i>
            <span class="text-lg">Dokumenten-Texte</span>
          </div>
        </template>
        <div class="grid">
          <div class="col-11">
            <InputText v-model="formData.salutationText" class="w-full" />
          </div>
          <div class="col-11">
            <span
              >Hier können Sie den Einleitungstext für Angebote, Aufträge,
              Rechnungen und Gutschriften festlegen.</span
            >
            <TabView
              v-model:activeIndex="activeTexte"
              :pt="{
                nav: { class: 'hidden' },
                panelContainer: { class: 'p-0 m-0' },
              }">
              <TabPanel>
                <Textarea
                  v-model="formData.offerIntroduction"
                  class="w-full"
                  rows="4" />
              </TabPanel>
              <TabPanel>
                <Textarea
                  v-model="formData.orderIntroduction"
                  class="w-full"
                  rows="4" />
              </TabPanel>
              <TabPanel>
                <Textarea
                  v-model="formData.invoiceIntroduction"
                  class="w-full"
                  rows="4" />
              </TabPanel>
              <TabPanel>
                <Textarea
                  v-model="formData.creditNoteIntroduction"
                  class="w-full"
                  rows="4" />
              </TabPanel>
              <TabPanel>
                <Textarea
                  v-model="formData.deliveryIntroduction"
                  class="w-full"
                  v-tooltip="
                    '[DOCNR] wird mit der Rechnungsnummer oder Auftragsnummer ersetzt'
                  "
                  rows="4" />
              </TabPanel>
              <TabPanel>
                <Textarea
                  v-model="formData.paymentReminderIntroduction"
                  class="w-full"
                  v-tooltip="
                    '[DOCNR] wird mit der Rechnungsnummer oder Auftragsnummer ersetzt'
                  "
                  rows="4" />
              </TabPanel>
              <TabPanel>
                <Textarea
                  v-model="formData.firstDunningNoticeIntroduction"
                  class="w-full"
                  v-tooltip="
                    '[DOCNR] wird mit der Rechnungsnummer oder Auftragsnummer ersetzt'
                  "
                  rows="4" />
              </TabPanel>
              <TabPanel>
                <Textarea
                  v-model="formData.secondDunningNoticeIntroduction"
                  class="w-full"
                  v-tooltip="
                    '[DOCNR] wird mit der Rechnungsnummer oder Auftragsnummer ersetzt'
                  "
                  rows="4" />
              </TabPanel>
              <TabPanel>
                <Textarea
                  v-model="formData.thirdDunningNoticeIntroduction"
                  class="w-full"
                  v-tooltip="
                    '[DOCNR] wird mit der Rechnungsnummer oder Auftragsnummer ersetzt'
                  "
                  rows="4" />
              </TabPanel>
            </TabView>
            <div class="flex align-content-center">
              <span class="pr-1">
                <InputSwitch v-model="formData.showIntroduction"
              /></span>
              <span>Einleitungstext anzeigen</span>
            </div>
          </div>
          <div class="grid w-full justify-content-center">
            <div
              v-for="(button, index) in tabButtons"
              :key="index"
              :class="['col-4 md:col-1 p-0']">
              <Button
                @click="activeTexte = index"
                :label="button.label"
                size="small"
                class="h-2rem text-xs w-full p-0"
                :outlined="activeTexte !== index" />
            </div>
          </div>

          <div class="col-11">
            <span
              >Hier können Sie den Schlusstext für Angebote, Aufträge,
              Rechnungen und Gutschriften festlegen.</span
            >

            <TabView
              v-model:activeIndex="activeTexte"
              :pt="{
                nav: { class: 'hidden' },
                panelContainer: { class: 'p-0 m-0' },
              }">
              <TabPanel>
                <Textarea
                  v-model="formData.offerFinalText"
                  class="w-full"
                  rows="4" />
                <div class="pl-0 ml-0 flex align-items-center col-12 md:col-6">
                  <InputSwitch
                    v-model="formData.showItemDescriptionInDocsDefault.offer"
                    :binary="true" /><span class="text-xs pl-1">
                    Artikelsbeschr./EAN in Angebot drucken</span
                  >
                </div>
              </TabPanel>
              <TabPanel>
                <Textarea
                  v-model="formData.orderFinalText"
                  class="w-full"
                  rows="4" />
                <div class="pl-0 ml-0 flex align-items-center col-12 md:col-6">
                  <InputSwitch
                    v-model="formData.showItemDescriptionInDocsDefault.order"
                    :binary="true" /><span class="text-xs pl-1">
                    Artikelsbeschr./EAN in Auftrag drucken</span
                  >
                </div>
              </TabPanel>
              <TabPanel>
                <Textarea
                  v-model="formData.invoiceFinalText"
                  class="w-full"
                  rows="4" />
                <div class="pl-0 ml-0 flex align-items-center col-12 md:col-6">
                  <InputSwitch
                    v-model="formData.showItemDescriptionInDocsDefault.invoice"
                    :binary="true" /><span class="text-xs pl-1">
                    Artikelsbeschr./EAN in Rechnung drucken</span
                  >
                </div>
              </TabPanel>
              <TabPanel>
                <Textarea
                  v-model="formData.creditNoteFinalText"
                  class="w-full"
                  rows="4" />
                <div class="pl-0 ml-0 flex align-items-center col-12 md:col-6">
                  <InputSwitch
                    v-model="
                      formData.showItemDescriptionInDocsDefault.creditnote
                    "
                    :binary="true" /><span class="text-xs pl-1">
                    Artikelsbeschr./EAN in Gutschrift drucken</span
                  >
                </div>
              </TabPanel>
              <TabPanel>
                <Textarea
                  v-model="formData.deliveryFinalText"
                  class="w-full"
                  rows="4" />
                <div class="grid">
                  <div class="flex align-items-center col-12 md:col-6">
                    <InputSwitch
                      v-model="
                        formData.showItemDescriptionInDocsDefault.delivery
                      "
                      :binary="true" /><span class="text-xs pl-1">
                      Artikelsbeschr./EAN in Lieferschein drucken</span
                    >
                  </div>
                  <div class="flex align-items-center col-12 md:col-6">
                    <InputSwitch v-model="formData.showVkInDeliverySlip" />
                    <span class="text-xs pl-1"
                      >Verkaufspreise in Lieferscheinen anzeigen</span
                    >
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <Textarea
                  v-model="formData.paymentReminderFinalText"
                  class="w-full"
                  rows="4" />
              </TabPanel>
              <TabPanel>
                <Textarea
                  v-model="formData.firstDunningNoticeFinalText"
                  class="w-full"
                  rows="4" />
              </TabPanel>
              <TabPanel>
                <Textarea
                  v-model="formData.secondDunningNoticeFinalText"
                  class="w-full"
                  rows="4" />
              </TabPanel>
              <TabPanel>
                <Textarea
                  v-model="formData.thirdDunningNoticeFinalText"
                  class="w-full"
                  rows="4" />
              </TabPanel>
            </TabView>
            <div class="flex align-content-center flex align-items-center">
              <span>
                <InputSwitch v-model="formData.showFinalText" />
              </span>
              <span class="pl-1 text-xs">Schlusstext anzeigen</span>
            </div>
          </div>
          <div class="col-11">
            <div>
              Hinweis § 14b Abs. 1 für Privatkunden anzeigen
              <InputSwitch v-model="formData.showLegalDisclaimer" />
            </div>
            <small>{{ formData.legalDisclaimer }}</small>
          </div>

          <div class="col-11">
            <Image
              :src="
                formData.showFooter
                  ? require('@/assets/footerShow.svg')
                  : require('@/assets/footerNotShow.svg')
              "
              imageClass="surface-card shadow-4 max-h-10rem w-full md:w-auto "
              alt="Image" />
            <div class="flex align-items-center">
              <span class="pr-1"
                ><InputSwitch v-model="formData.showFooter"
              /></span>
              <span class="">Fußzeile anzeigen</span>
            </div>
          </div>
        </div>
      </Fieldset>
      <p></p>
      <Fieldset
        v-if="isMainUser"
        ref="qrRef"
        legend="Dokumenten-QR Codes"
        :toggleable="true"
        class="field col-12"
        :collapsed="true"
        :pt="{
          legend: {
            class: 'p-0 m-0 bg-white border-none',
            style: { left: '-25px!important', position: 'relative' },
          },
          togglerIcon: { class: 'p-0 m-0 hidden' },
          toggleableContent: { class: 'p-0 m-0 bg-gray-100' },
          toggler: { class: 'py-0 my-0' },
          root: { class: 'p-0 my-0' },
        }">
        <template #legend>
          <div class="flex align-items-center gap-0 px-0">
            <i
              class="text-xl mx-3"
              :class="
                qrRef?.d_collapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up'
              "></i>
            <span class="text-lg">Dokumenteneinstellungen und -QR Codes</span>
          </div>
        </template>
        <p>
          Hier können Sie das anzeigen der QR-Codes auf Rechnung, Auftrag und
          Lieferschein die QR-Codes festlegen.
        </p>
        <div>
          <Image
            :src="
              formData.showPaymentQRCode
                ? require('@/assets/qrShow.svg')
                : require('@/assets/qrNotShow.svg')
            "
            imageClass="surface-card shadow-4 w-full md:w-auto"
            alt="Image" />
          <div class="col-6"></div>
        </div>

        <div class="grid">
          <div class="col-12">
            <div class="flex align-content-center">
              <span>
                <InputSwitch
                  v-model="formData.showPaymentQRCode"
                  :binary="true" />
              </span>
              <span class="pl-3"> Bezahl-QR-Code in Rechnungen anzeigen? </span>
            </div>
            <div class="flex align-items-center py-2">
              <span>
                <InputSwitch v-model="formData.showOrderQRCode" />
              </span>
              <span class="pl-3"> Bezahl-QR-Code in Aufträgen anzeigen? </span>
            </div>
            <div class="flex align-items-center py-2">
              <span>
                <InputSwitch v-model="formData.showDeliveryQRCode" />
              </span>
              <span class="pl-3">
                QR-Code zum Navigieren in Lieferscheinen anzeigen?
              </span>
            </div>
            <div class="flex align-items-center py-2">
              <span>
                <InputSwitch v-model="formData.showVkInDeliverySlip" />
              </span>
              <span class="pl-3">
                Verkaufspreise in Lieferscheinen anzeigen?
              </span>
            </div>
            <div class="flex align-items-center py-2">
              <span
                ><InputSwitch
                  v-model="formData.delieveryNotesWithAlias"
                  :binary="true"
              /></span>
              <span class="pl-3"
                >Alias anstelle von Artikelbezeichnung auf Lieferschein
                drucken</span
              >
            </div>
            <div class="flex align-items-center py-2">
              <span>
                <InputSwitch v-model="formData.showEanInDocuments" />
              </span>
              <span class="pl-3"> EAN in den Dokumenten anzeigen? </span>
            </div>
            <div class="flex align-items-center py-2">
              <span>
                <InputSwitch v-model="formData.defaultPrintPreview" />
              </span>
              <span class="pl-3">
                Vorschau der Dokumente Standardmäßig eingeschaltet?
              </span>
            </div>
            <div class="flex align-items-center py-2">
              <span>
                <InputSwitch v-model="formData.collectSameItems" />
              </span>
              <span class="pl-3">
                Gleiche Artikel beim dokument generieren zusammenführen.
              </span>
            </div>

            <div class="flex align-items-center py-2">
              <div
                v-tooltip="
                  'Feld auf der rechten Seite im Bereich wo Datum, Bearbeiter usw. steht, es werden maximal inkls. Feldbezeichnung 23 zeichen angezeigt.'
                ">
                Bezeichnung des benutzerdefinierten Feldes
              </div>
              <div class="pl-2">
                <InputText v-model="formData.companyEditorField" />
              </div>
            </div>
          </div>
        </div>
      </Fieldset>
      <p></p>

      <Fieldset
        v-if="isMainUser"
        ref="docNamesRef"
        legend="Nummernschablone"
        :toggleable="true"
        class="field col-12"
        :collapsed="true"
        :pt="{
          legend: {
            class: 'p-0 m-0 bg-white border-none',
            style: { left: '-25px!important', position: 'relative' },
          },
          togglerIcon: { class: 'p-0 m-0 hidden' },
          toggleableContent: { class: 'p-0 m-0 bg-gray-100' },
          toggler: { class: 'py-0 my-0' },
          root: { class: 'p-0 my-0' },
        }">
        <template #legend>
          <div class="flex align-items-center gap-0 px-0">
            <i
              class="text-xl mx-3"
              :class="
                docNamesRef?.d_collapsed
                  ? 'pi pi-chevron-down'
                  : 'pi pi-chevron-up'
              "></i>
            <span class="text-lg">Nummernschablone</span>
          </div>
        </template>
        <div v-if="formData.docIdentSettings">
          <!--                
                    TODO: Jeweils für Angebot, Auftrag/Bestellung und Rechnung eine eigene Nummernschablone mit Prefix, Suffix und Zähler mit jeweiligen Feldern für die Nummerierung erstellen
                    schritt 1.) get von Server und prüfen ob die id die gespeichert werden soll mind. gleich groß oder größer ist
                    schritt 2.) wenn nein, dann Toast-Meldung ausgeben mit Fehler, das die Nummerierung nicht kleiner sein darf
                    schritt 3.) wenn ja, dann speichern
                -->
          <p>
            Hier können Sie die Nummernschablone für Angebote, Aufträge,
            Rechnungen und Lieferscheine festlegen.
          </p>

          <h5>Angebote</h5>
          <div class="flex grid">
            <div class="col-2 font-semibold text-center">Prefix</div>
            <div class="col-2 font-semibold text-center">Zähler</div>
            <div class="col-2 font-semibold text-center">Suffix</div>
            <div
              class="col-2 font-semibold text-center"
              @dblclick="getDocIdentSettings()">
              Vorschau
            </div>
            <div class="col-4"></div>
            <div class="col-2">
              <InputText
                class="w-full font-semibold"
                v-model="formData.docIdentSettings.OFFER.prefix" />
            </div>
            <div class="col-2">
              <InputNumber
                :useGrouping="false"
                :min="0"
                :max="90000000"
                v-tooltip="'Maximal bis 90000000'"
                class="w-full"
                inputClass="w-full font-semibold"
                v-model="formData.docIdentSettings.OFFER.ctr" />
            </div>
            <div class="col-2">
              <InputText
                class="w-full font-semibold"
                v-model="formData.docIdentSettings.OFFER.suffix" />
            </div>
            <div class="col-2">
              <div>Angebotsnummer:</div>
              <span class="font-semibold"
                >{{ formData.docIdentSettings.OFFER.prefix
                }}{{ formData.docIdentSettings.OFFER.ctr
                }}{{ formData.docIdentSettings.OFFER.suffix }}</span
              >
            </div>
          </div>
          <Divider></Divider>
          <h5>Aufträge/Bestellungen</h5>
          <div class="flex grid">
            <div class="col-2">
              <InputText
                class="w-full font-semibold"
                v-model="formData.docIdentSettings.ORDER.prefix" />
            </div>
            <div class="col-2">
              <InputNumber
                :useGrouping="false"
                :min="0"
                :max="90000000"
                v-tooltip="'Maximal bis 90000000'"
                inputClass="w-full font-semibold"
                v-model="formData.docIdentSettings.ORDER.ctr" />
            </div>
            <div class="col-2">
              <InputText
                class="w-full font-semibold"
                v-model="formData.docIdentSettings.ORDER.suffix" />
            </div>
            <div class="col-2">
              <div>Auftragsnummer:</div>
              <span class="font-semibold"
                >{{ formData.docIdentSettings.ORDER.prefix
                }}{{ formData.docIdentSettings.ORDER.ctr
                }}{{ formData.docIdentSettings.ORDER.suffix }}</span
              >
            </div>
          </div>
          <Divider></Divider>

          <h5>Rechnungen</h5>
          <div class="flex grid">
            <div class="col-2">
              <InputText
                class="w-full font-semibold"
                v-model="formData.docIdentSettings.INVOICE.prefix" />
            </div>
            <div class="col-2">
              <InputNumber
                :useGrouping="false"
                :min="0"
                :max="90000000"
                v-tooltip="'Maximal bis 90000000'"
                inputClass="w-full font-semibold"
                v-model="formData.docIdentSettings.INVOICE.ctr" />
            </div>
            <div class="col-2">
              <InputText
                class="w-full font-semibold"
                v-model="formData.docIdentSettings.INVOICE.suffix" />
            </div>
            <div class="col-2">
              <div>Rechnungsnummer:</div>
              <span class="font-semibold"
                >{{ formData.docIdentSettings.INVOICE.prefix
                }}{{ formData.docIdentSettings.INVOICE.ctr
                }}{{ formData.docIdentSettings.INVOICE.suffix }}</span
              >
            </div>
          </div>
          <Divider></Divider>

          <h5>Gutschriften</h5>
          <div class="flex grid">
            <div class="col-2">
              <InputText
                class="w-full font-semibold"
                v-model="formData.docIdentSettings.CREDITNOTE.prefix" />
            </div>
            <div class="col-2">
              <InputNumber
                :useGrouping="false"
                :min="0"
                :max="90000000"
                v-tooltip="'Maximal bis 90000000'"
                inputClass="w-full font-semibold"
                v-model="formData.docIdentSettings.CREDITNOTE.ctr" />
            </div>
            <div class="col-2">
              <InputText
                class="w-full font-semibold"
                v-model="formData.docIdentSettings.CREDITNOTE.suffix" />
            </div>
            <div class="col-2">
              <div>Gutschriftssnummer:</div>
              <span class="font-semibold"
                >{{ formData.docIdentSettings.CREDITNOTE.prefix
                }}{{ formData.docIdentSettings.CREDITNOTE.ctr
                }}{{ formData.docIdentSettings.CREDITNOTE.suffix }}</span
              >
            </div>
          </div>
          <Divider></Divider>

          <h5>Lieferscheine</h5>
          <div class="flex grid">
            <div class="col-2">
              <InputText
                class="w-full font-semibold"
                v-model="formData.docIdentSettings.SHIPPING.prefix" />
            </div>
            <div class="col-2">
              <InputNumber
                :useGrouping="false"
                :min="0"
                :max="90000000"
                v-tooltip="'Maximal bis 90000000'"
                inputClass="w-full font-semibold"
                v-model="formData.docIdentSettings.SHIPPING.ctr" />
            </div>
            <div class="col-2">
              <InputText
                class="w-full font-semibold"
                v-model="formData.docIdentSettings.SHIPPING.suffix" />
            </div>
            <div class="col-2">
              <div>Lieferscheinnummer:</div>
              <span class="font-semibold">
                {{ formData.docIdentSettings.SHIPPING.prefix
                }}{{ formData.docIdentSettings.SHIPPING.ctr
                }}{{ formData.docIdentSettings.SHIPPING.suffix }}</span
              >
            </div>
          </div>
          <div class="flex justify-content-center">
            <Button
              label="Nummernschablone speichern"
              outlined
              size="small"
              icon="pi pi-save"
              @click="setNummernKreise" />
          </div>
        </div>
      </Fieldset>
      <p></p>

      <Fieldset
        v-if="isMainUser"
        ref="paymentTermsRef"
        legend="Zahlungsmodalitäten"
        :toggleable="true"
        class="field col-12"
        :collapsed="true"
        :pt="{
          legend: {
            class: 'p-0 m-0 bg-white border-none',
            style: { left: '-25px!important', position: 'relative' },
          },
          togglerIcon: { class: 'p-0 m-0 hidden' },
          toggleableContent: { class: 'p-0 m-0 bg-gray-100' },
          toggler: { class: 'py-0 my-0' },
          root: { class: 'p-0 my-0' },
        }">
        <template #legend>
          <div class="flex align-items-center gap-0 px-0">
            <i
              class="text-xl mx-3"
              :class="
                paymentTermsRef?.d_collapsed
                  ? 'pi pi-chevron-down'
                  : 'pi pi-chevron-up'
              "></i>
            <span class="text-lg">Zahlungsmodalitäten</span>
          </div>
        </template>
        <div class="py-2">
          <div class="flex align-content-center">
            <span>
              <InputSwitch v-model="formData.showPaymentTerms" />
            </span>
            <span class="pl-3"> Zahlungsbedingungen anzeigen </span>
          </div>
        </div>
        <div>
          <Dropdown
            v-model="formData.defaultPaymentKind"
            :options="formData.paymentKinds.paymentData"
            optionValue="value"
            optionLabel="value"></Dropdown>
          <span>
            ist als Standard ausgewählt.<i
              class="pi pi-info-circle"
              v-tooltip="
                'Standardmäßig ausgewählte Methode, beim erstellen eines Dokuments(Angebot, Auftrag oder Rechnung).'
              "
              style="cursor: pointer"></i>
          </span>
        </div>
        <p></p>
        <InlineMessage severity="info">
          _DAYS wird später automatich durch die Zahl der Tage ersetzt.
        </InlineMessage>
        <InlineMessage severity="info">
          _WALLET_ADDRESS wird durch die Wallet-Adresse ersetzt.
        </InlineMessage>
        <ScrollPanel style="width: 80vw; height: 100%">
          <div class="card p-fluid">
            <DataTable
              :value="formData.paymentKinds.paymentData"
              editMode="cell"
              sortable="false"
              class="text-xs small"
              @cell-edit-complete="onCellEditComplete"
              :pt="{
                column: {
                  bodycell: ({ state }) => ({
                    class: [{ 'pt-0 pb-0': state['d_editing'] }],
                  }),
                },
              }">
              <Column
                v-for="col of paymentColumns"
                :key="col.field"
                :header="col.header"
                :field="col.field">
                <template #body="{ data, field }">
                  <template v-if="field === 'details'">
                    Zahlungsziel:
                    <span class="font-semibold">{{ data[field].termDays }}</span
                    >, Skontoziel:
                    <span class="font-semibold">{{
                      data[field].skontoDays
                    }}</span
                    >, Skonto:
                    <span class="font-semibold">{{ data[field].skonto }}</span>
                    <div v-if="data[field].acc">
                      Wallet:<span class="font-semibold">{{
                        data[field].acc
                      }}</span>
                    </div>
                  </template>
                  <template v-else-if="field === 'showInPrints'">
                    <InputSwitch :binary="true" v-model="data[field]" />
                  </template>
                  <span v-else-if="field !== 'showInPrints'">
                    {{ data[field] }}</span
                  >
                </template>
                <template #editor="{ data, field }">
                  <template v-if="field === 'details'">
                    Zahlungsziel:<InputText
                      v-model="data[field].termDays"
                      autofocus
                      class="text-xs" />
                    Skontoziel:<InputText
                      v-model="data[field].skontoDays"
                      autofocus
                      class="text-xs" />
                    Skonto:<InputText
                      v-model="data[field].skonto"
                      autofocus
                      class="text-xs" />
                  </template>
                  <template v-else-if="field !== 'showInPrints'">
                    <InputText v-model="data[field]" autofocus />
                  </template>
                  <template v-else>
                    <InputSwitch
                      v-model="data[field]"
                      :value="data[field]"
                      autofocus
                      :inputId="data[field]"
                      :trueValue="true"
                      :falseValue="false" />
                  </template>
                  <template
                    v-if="
                      data.notice && data.notice.includes('_WALLET_ADDRESS')
                    ">
                    Wallet:<InputText
                      v-model="data.acc"
                      autofocus
                      class="text-xs" />
                  </template>
                </template>
              </Column>
              <Column
                field="available"
                header="Verfügbar"
                :pt="{
                  header: { class: 'text-center' },
                  bodycell: { class: 'text-center' },
                }">
                <template #body="{ data }">
                  <InputSwitch :binary="true" v-model="data.available" />
                </template>
              </Column>

              <Column
                field="action"
                header="Löschen"
                :pt="{
                  header: { class: 'text-center' },
                  bodycell: { class: 'text-center' },
                }">
                <template #body="{ data }">
                  <Button
                    v-if="!data?.details?.deletable"
                    icon="pi pi-trash"
                    class="p-button-rounded p-button-danger p-button-text"
                    @click="deletePaymentEntry(data)" />
                </template>
              </Column>
              <template #footer>
                <div class="flex">
                  <div>
                    <Button
                      icon="pi pi-plus"
                      size="small"
                      text
                      label="Eintrag Hinzufügen"
                      @click="addPaymentEntry" />
                  </div>
                  <div>
                    <Button
                      icon="pi pi-info-circle"
                      severity="info"
                      size="small"
                      text
                      label="info"
                      @click="infoTogglePayment" />
                  </div>
                </div>
              </template>
            </DataTable>
          </div>
        </ScrollPanel>
      </Fieldset>
      <p></p>

      <Fieldset
        v-if="isMainUser"
        ref="communcationRef"
        legend="Kommunikation"
        :toggleable="true"
        class="fieldbla"
        :collapsed="true"
        :pt="{
          legend: {
            class: 'p-0 m-0 bg-white border-none',
            style: { left: '-25px!important', position: 'relative' },
          },
          togglerIcon: { class: 'p-0 m-0 hidden' },
          toggleableContent: { class: 'p-0 m-0 bg-gray-100' },
          toggler: { class: 'py-0 my-0' },
          root: { class: 'p-0 my-0' },
        }">
        <template #legend>
          <div class="flex align-items-center gap-0 px-0">
            <i
              class="text-xl mx-3"
              :class="
                communcationRef?.d_collapsed
                  ? 'pi pi-chevron-down'
                  : 'pi pi-chevron-up'
              "></i>
            <span class="text-lg">Kommunikation</span>
          </div>
        </template>
        <div class="grid">
          <div class="col-12 flex align-items-center">
            <span class="pr-1"
              ><InputSwitch v-model="formData.sendCopyToAnswerEmailAddress"
            /></span>
            <span>Kopie der E-mails senden</span>
          </div>
          <div class="col-12 flex align-items-center">
            <span class="pr-1"
              ><InputSwitch v-model="formData.showCompanyLogoInEmails"
            /></span>
            <span>Firmenlogo in E-Mails anzeigen</span>
          </div>

          <div class="col-12">
            <span
              >Hier können Sie den Einleitungstext für Angebote, Aufträge und
              Rechnungen festlegen.</span
            >
          </div>
          <div class="col-12 grid">
            <div
              class="col-12 md:col-5 flex pb-0 gap-2 justify-content-center md:justify-content-end">
              <Button
                @click="activeEmails = 0"
                label="Angebot"
                size="small"
                class="h-2rem px-1 text-xs md:text-sm"
                :outlined="activeEmails !== 0" />
              <Button
                @click="activeEmails = 1"
                label="Auftrag"
                size="small"
                class="h-2rem px-1 text-xs md:text-sm"
                :outlined="activeEmails !== 1" />
              <Button
                @click="activeEmails = 2"
                label="Rechnung"
                size="small"
                class="h-2rem px-1 text-xs md:text-sm"
                :outlined="activeEmails !== 2" />
            </div>
            <div
              class="col-12 md:col-6 flex mb-2 gap-2 justify-content-start pl-0">
              <Button
                @click="activeEmails = 3"
                label="Rechnungskorrektur"
                size="small"
                class="h-2rem px-1 text-xs md:text-sm"
                :outlined="activeEmails !== 3" />
              <Button
                @click="activeEmails = 4"
                label="Lieferschein"
                size="small"
                class="h-2rem px-1 text-xs md:text-sm"
                :outlined="activeEmails !== 4" />
              <!--                         <Button @click="activeEmails = 5" label="Versand" size="small" class="h-2rem px-1"
                            :outlined="activeEmails !== 5" /> -->
              <Button
                @click="activeEmails = 5"
                label="Geschäftsschreiben"
                size="small"
                class="h-2rem px-1 text-xs md:text-sm"
                :outlined="activeEmails !== 5" />
            </div>
            <div class="col-12 p-0 m-0">
              <TabView
                v-model:activeIndex="activeEmails"
                :pt="{
                  nav: { class: 'hidden' },
                  panelContainer: { class: 'p-0 m-0' },
                }">
                <TabPanel>
                  <div>
                    <div class="flex justify-content-end align-items-center">
                      <span class="pr-2">Antwort E-Mail Adresse</span>
                      <InputText
                        v-model="formData.docEmailSettings.OFFER.replyTo"
                        class="pl-2 w-12rem md:w-20rem text-xs py-2" />
                    </div>
                    <div>
                      <div class="flex justify-content-end align-items-center">
                        <span class="pr-2">Kopie-Empfang E-Mail Adresse</span>
                        <InputText
                          v-model="formData.docEmailSettings.OFFER.bcc"
                          class="pl-2 w-12rem md:w-20rem text-xs py-2" />
                      </div>
                      <div>
                        <span class="pr-2">Betreff</span>
                        <InputText
                          v-model="formData.docEmailSettings.OFFER.subject"
                          class="pl-2 w-10" />
                      </div>
                    </div>
                  </div>
                  <Textarea
                    v-model="formData.docEmailSettings.OFFER.message"
                    class="w-full"
                    rows="10" />
                </TabPanel>
                <TabPanel>
                  <div>
                    <div class="flex justify-content-end align-items-center">
                      <span class="pr-2">Antwort E-Mail Adresse</span>
                      <InputText
                        v-model="formData.docEmailSettings.ORDER.replyTo"
                        class="pl-2 w-3" />
                    </div>
                    <div>
                      <span class="pr-2">Betreff</span>
                      <InputText
                        v-model="formData.docEmailSettings.ORDER.subject"
                        class="pl-2 w-5" />
                      <div class="flex justify-content-end align-items-center">
                        <span class="pr-2">Kopie-Empfang E-Mail Adresse</span>
                        <InputText
                          v-model="formData.docEmailSettings.ORDER.bcc"
                          class="pl-2 w-3" />
                      </div>
                    </div>
                  </div>
                  <Textarea
                    v-model="formData.docEmailSettings.ORDER.message"
                    class="w-full"
                    rows="10" />
                </TabPanel>
                <TabPanel>
                  <div>
                    <div class="flex justify-content-end align-items-center">
                      <span class="pr-2">Antwort E-Mail Adresse</span>
                      <InputText
                        v-model="formData.docEmailSettings.INVOICE.replyTo"
                        class="pl-2 w-3" />
                    </div>
                    <div>
                      <span class="pr-2">Betreff</span>
                      <InputText
                        v-model="formData.docEmailSettings.INVOICE.subject"
                        class="pl-2 w-5" />
                      <div class="flex justify-content-end align-items-center">
                        <span class="pr-2">Kopie-Empfang E-Mail Adresse</span>
                        <InputText
                          v-model="formData.docEmailSettings.INVOICE.bcc"
                          class="pl-2 w-3" />
                      </div>
                    </div>
                  </div>
                  <Textarea
                    v-model="formData.docEmailSettings.INVOICE.message"
                    class="w-full"
                    rows="10" />
                </TabPanel>
                <TabPanel>
                  <div>
                    <div class="flex justify-content-end align-items-center">
                      <span class="pr-2">Antwort E-Mail Adresse</span>
                      <InputText
                        v-model="
                          formData.docEmailSettings.INVOICE_CORRECTION.replyTo
                        "
                        class="pl-2 w-3" />
                    </div>
                    <div>
                      <span class="pr-2">Betreff</span>
                      <InputText
                        v-model="
                          formData.docEmailSettings.INVOICE_CORRECTION.subject
                        "
                        class="pl-2 w-5" />
                      <div class="flex justify-content-end align-items-center">
                        <span class="pr-2">Kopie-Empfang E-Mail Adresse</span>
                        <InputText
                          v-model="
                            formData.docEmailSettings.INVOICE_CORRECTION.bcc
                          "
                          class="pl-2 w-3" />
                      </div>
                    </div>
                  </div>
                  <Textarea
                    v-model="
                      formData.docEmailSettings.INVOICE_CORRECTION.message
                    "
                    class="w-full"
                    rows="10" />
                </TabPanel>
                <TabPanel>
                  <div>
                    <div class="flex justify-content-end align-items-center">
                      <span class="pr-2">Antwort E-Mail Adresse</span>
                      <InputText
                        v-model="formData.docEmailSettings.SHIPPING.replyTo"
                        class="pl-2 w-3" />
                    </div>
                    <div>
                      <span class="pr-2">Betreff</span>
                      <InputText
                        v-model="formData.docEmailSettings.SHIPPING.subject"
                        class="pl-2 w-5" />
                      <div class="flex justify-content-end align-items-center">
                        <span class="pr-2">Kopie-Empfang E-Mail Adresse</span>
                        <InputText
                          v-model="formData.docEmailSettings.SHIPPING.bcc"
                          class="pl-2 w-3" />
                      </div>
                    </div>
                  </div>
                  <Textarea
                    v-model="formData.docEmailSettings.SHIPPING.message"
                    class="w-full"
                    rows="10" />
                </TabPanel>
                <TabPanel>
                  <div>
                    <div class="flex justify-content-end align-items-center">
                      <span class="pr-2">Antwort E-Mail Adresse</span>
                      <InputText
                        v-model="formData.docEmailSettings.SHIPPING.replyTo"
                        class="pl-2 w-3" />
                    </div>
                    <div>
                      <div class="flex justify-content-end align-items-center">
                        <span class="pr-2">Kopie-Empfang E-Mail Adresse</span>
                        <InputText
                          v-model="formData.docEmailSettings.SHIPPING.bcc"
                          class="pl-2 w-3" />
                      </div>
                    </div>
                  </div>
                </TabPanel>
              </TabView>
            </div>
            <div v-if="activeEmails !== 5">
              <InlineMessage severity="info">
                _DOC_TITEL wird später automatich durch den Dokumententitel(z.B.
                Rechnung) ersetzt.
              </InlineMessage>
              <InlineMessage severity="info">
                _DOC_NR wird durch die jeweilige Dokumenten-Nr (z.B.
                Rechnungsnr) ersetzt.
              </InlineMessage>
              <InlineMessage severity="info">
                _COMPANY_NAME wird durch den Firmennamen ersetzt.
              </InlineMessage>
            </div>
          </div>
        </div>
      </Fieldset>
      <p></p>

      <Fieldset
        v-if="isMainUser"
        ref="reminderRef"
        legend="Zahlungserinnerung/Mahnung"
        :toggleable="true"
        class="field col-12"
        :collapsed="true"
        :pt="{
          legend: {
            class: 'p-0 m-0 bg-white border-none',
            style: { left: '-25px!important', position: 'relative' },
          },
          togglerIcon: { class: 'p-0 m-0 hidden' },
          toggleableContent: { class: 'p-0 m-0 bg-gray-100' },
          toggler: { class: 'py-0 my-0' },
          root: { class: 'p-0 my-0' },
        }">
        <template #legend>
          <div class="flex align-items-center gap-0 px-0">
            <i
              class="text-xl mx-3"
              :class="
                reminderRef?.d_collapsed
                  ? 'pi pi-chevron-down'
                  : 'pi pi-chevron-up'
              "></i>
            <span class="text-lg">Zahlungserinnerung/Mahnung</span>
          </div>
        </template>
        <div class="grid flex align-items-center">
          <div class="col-12 flex align-items-center">
            <span
              ><InputSwitch
                v-model="formData.reminderSettings.is_auto_scheduled"
            /></span>
            <span class="pl-1"
              >Automatischer Versand von Zahlungserinnerung und Mahnung</span
            >
          </div>
          <div class="col-12 flex align-items-center">
            <span
              ><InputSwitch
                disabled
                v-model="formData.reminderSettings.send_payment_history"
            /></span>
            <span class="pl-1"
              >Automatischer Versand von der Zahlungshistorie zusammen mit dem
              jeweiligen Dokument</span
            >
          </div>

          <div class="col-12 flex align-items-center">
            <span><InputSwitch v-model="formData.showReminderQRCode" /></span>
            <span class="pl-1"
              >Bezahl-QR-Code anzeigen auf Zahlungserinnerung/Mahnung</span
            >
          </div>

          <div class="col-12">
            Zeitangaben in Tagen zum automatichen Versand von ...
          </div>
          <div class="col-8 md:col-3">Zahlungserinnerung</div>
          <div class="col-3 md:col-1">
            <InputNumber
              v-model="formData.reminderSettings.schedule_days_lvl_0"
              :useGrouping="false"
              suffix=" Tag/e"
              :highlightOnFocus="true"
              type="number"
              inputClass="w-full"
              :disabled="!formData.reminderSettings.is_auto_scheduled" />
          </div>
          <div class="md:col-8"></div>
          <div class="col-8 md:col-3">1. Mahnung</div>
          <div class="col-3 md:col-1">
            <InputNumber
              v-model="formData.reminderSettings.schedule_days_lvl_1"
              :useGrouping="false"
              suffix=" Tag/e"
              :highlightOnFocus="true"
              type="number"
              inputClass="w-full"
              :disabled="!formData.reminderSettings.is_auto_scheduled" />
          </div>
          <div class="md:col-8"></div>
          <div class="col-8 md:col-3">2. Mahnung</div>
          <div class="col-3 md:col-1">
            <InputNumber
              v-model="formData.reminderSettings.schedule_days_lvl_2"
              :useGrouping="false"
              suffix=" Tag/e"
              :highlightOnFocus="true"
              type="number"
              inputClass="w-full"
              :disabled="!formData.reminderSettings.is_auto_scheduled" />
          </div>
          <div class="md:col-8"></div>
          <div class="col-8 md:col-3">3. Mahnung</div>
          <div class="col-3 md:col-1">
            <InputNumber
              v-model="formData.reminderSettings.schedule_days_lvl_3"
              :useGrouping="false"
              suffix=" Tag/e"
              :highlightOnFocus="true"
              type="number"
              inputClass="w-full"
              :disabled="!formData.reminderSettings.is_auto_scheduled" />
          </div>
          <div class="md:col-8"></div>
          <div
            class="col-8 md:col-3"
            v-tooltip="
              'Nach Ablauf der Frist, die Uhrzeit an dem die Zahlungserinnerung/Mahnung versendet werden soll.'
            ">
            Uhrzeit für den Versand <span class="pi pi-info-circle"></span>
          </div>
          <div class="col-3 md:col-1">
            <Dropdown
              v-model="formData.reminderSettings.schedule_time"
              :options="calcUtils.volleUhrzeiten.value"
              inputClass="w-full"
              optionLabel="optionLabel"
              optionValue="optionValue"
              :disabled="!formData.reminderSettings.is_auto_scheduled" />
          </div>
          <div class="md:col-8"></div>
          <div class="col-12">
            <span class="pi pi-info-circle text-blue-300">
              Die Einstellungen für die Texte finden Sie unter Dokumenten-Texte
            </span>
          </div>
        </div>
      </Fieldset>
      <p></p>

      <Fieldset
        v-if="isMainUser"
        ref="customerRef"
        legend="Kunden"
        :toggleable="true"
        class="field col-12"
        :collapsed="true"
        :pt="{
          legend: {
            class: 'p-0 m-0 bg-white border-none',
            style: { left: '-25px!important', position: 'relative' },
          },
          togglerIcon: { class: 'p-0 m-0 hidden' },
          toggleableContent: { class: 'p-0 m-0 bg-gray-100' },
          toggler: { class: 'py-0 my-0' },
          root: { class: 'p-0 my-0' },
        }">
        <template #legend>
          <div class="flex align-items-center gap-0 px-0">
            <i
              class="text-xl mx-3"
              :class="
                customerRef?.d_collapsed
                  ? 'pi pi-chevron-down'
                  : 'pi pi-chevron-up'
              "></i>
            <span class="text-lg">Kunden</span>
          </div>
        </template>
        <div class="grid flex align-items-center">
          <div class="col-12 flex align-items-center">
            <span
              ><InputSwitch v-model="formData.showCustomerActivityWarning"
            /></span>
            <span class="pl-1">Warnungen bei inaktiven Kunden zulassen</span>
          </div>

          <div class="col-8 md:col-3">
            Anzahl vergangene Tage bis zur Warnung
          </div>
          <div class="col-3 md:col-1">
            <InputNumber
              v-model="formData.lastCustomerActivityWarningDays"
              :useGrouping="false"
              type="number"
              inputClass="w-full"
              :disabled="!formData.showCustomerActivityWarning" />
          </div>
          <div class="md:col-8"></div>
          <div class="col-8 md:col-3">Kreditlimit für Neue Kunden</div>
          <div>
            <InputSwitch
              v-model="formData.defaultCustomerCreditLimit.activateCreditLimit"
              :binary="true" />
          </div>
          <div class="md:col-8"></div>

          <div
            class="col-8 md:col-3"
            v-if="formData?.defaultCustomerCreditLimit?.activateCreditLimit">
            Maximaler Kreditlimit für Neue Kunden
          </div>
          <div
            class="col-3 md:col-2"
            v-if="formData?.defaultCustomerCreditLimit?.activateCreditLimit">
            <InputNumber
              v-model="formData.defaultCustomerCreditLimit.maxLimit"
              :useGrouping="false"
              highlightOnFocus
              mode="currency"
              currency="EUR"
              locale="de-DE"
              type="number"
              inputClass="w-full"
              :disabled="!formData.showCustomerActivityWarning" />
          </div>
        </div>
      </Fieldset>
      <p></p>

      <Fieldset
        v-if="isMainUser"
        ref="productsRef"
        legend="Artikel"
        :toggleable="true"
        class="field col-12"
        :collapsed="true"
        :pt="{
          legend: {
            class: 'p-0 m-0 bg-white border-none',
            style: { left: '-25px!important', position: 'relative' },
          },
          togglerIcon: { class: 'p-0 m-0 hidden' },
          toggleableContent: { class: 'p-0 m-0 bg-gray-100' },
          toggler: { class: 'py-0 my-0' },
          root: { class: 'p-0 my-0' },
        }">
        <template #legend>
          <div class="flex align-items-center gap-0 px-0">
            <i
              class="text-xl mx-3"
              :class="
                productsRef?.d_collapsed
                  ? 'pi pi-chevron-down'
                  : 'pi pi-chevron-up'
              "></i>
            <span class="text-lg">Artikel</span>
          </div>
        </template>
        <div>
          <span class="text-sm font-semibold">Bestand:</span>
          <div class="flex align-items-center">
            <span> <InputSwitch v-model="formData.stockControl" /> </span>
            <span class="pl-1">Negativen Bestand <b>nicht</b> zulassen</span>
          </div>
        </div>
        <div>
          <span class="text-sm font-semibold">Mindeshaltbarkeitsdatum:</span>
          <div class="flex align-items-center">
            <span> <InputSwitch v-model="formData.mhdDefault" /> </span>
            <span class="pl-1"
              >(MHD-Pflicht) Standard-Wert bei neuen Artikel</span
            >
          </div>
        </div>
        <p></p>
        <span class="text-sm font-semibold">Einheiten:</span>
        <p></p>
        <div class="card p-fluid">
          <DataTable
            :value="formData.itemUnits.itemUnitsData"
            editMode="cell"
            sortable="false"
            @cell-edit-complete="onCellEditComplete"
            :pt="{
              column: {
                bodycell: ({ state }) => ({
                  class: [{ 'pt-0 pb-0': state['d_editing'] }],
                }),
              },
            }">
            <Column
              v-for="col of itemUnitsColumns"
              :key="col.field"
              :header="col.header"
              :field="col.field">
              <template #body="{ data, field }">
                <span> {{ data[field] }}</span>
              </template>
              <template #editor="{ data, field }">
                <InputText v-model="data[field]" autofocus />
              </template>
            </Column>
            <Column
              field="action"
              header="Löschen"
              :pt="{
                header: { class: 'text-center' },
                bodycell: { class: 'text-center' },
              }">
              <template #body="{ data }">
                <div class="flex justify-content-left">
                  <Button
                    icon="pi pi-trash"
                    class="p-button-rounded p-button-danger p-button-text"
                    @click="deleteItemUnitEntry(data)" />
                </div>
              </template>
            </Column>
            <template #footer>
              <div class="flex">
                <div>
                  <Button
                    icon="pi pi-plus"
                    size="small"
                    text
                    label="Eintrag Hinzufügen"
                    @click="addItemUnitEntry" />
                </div>
                <div>
                  <Button
                    icon="pi pi-info-circle"
                    severity="info"
                    size="small"
                    text
                    label="info"
                    @click="infoToggleItemUnit" />
                </div>
              </div>
            </template>
          </DataTable>
        </div>
      </Fieldset>
      <p></p>
      <Fieldset
        v-if="isMainUser"
        ref="shippingRef"
        legend="Versand"
        :toggleable="true"
        class="field col-12"
        :collapsed="true"
        :pt="{
          legend: {
            class: 'p-0 m-0 bg-white border-none',
            style: { left: '-25px!important', position: 'relative' },
          },
          togglerIcon: { class: 'p-0 m-0 hidden' },
          toggleableContent: { class: 'p-0 m-0 bg-gray-100' },
          toggler: { class: 'py-0 my-0' },
          root: { class: 'p-0 my-0' },
        }">
        <template #legend>
          <div class="flex align-items-center gap-0 px-0">
            <i
              class="text-xl mx-3"
              :class="
                shippingRef?.d_collapsed
                  ? 'pi pi-chevron-down'
                  : 'pi pi-chevron-up'
              "></i>
            <span class="text-lg">Versand</span>
          </div>
        </template>
        <div class="grid">
          <div class="col-12 flex align-items-center">
            <span
              ><InputSwitch v-model="formData.defaultShipFromInvoice"
            /></span>
            <span class="pl-1"
              >Rechnungen in den Versand aufnehmen, Standardauswahl</span
            >
          </div>
          <div class="col-12 flex align-items-center">
            <span><InputSwitch v-model="formData.defaultShipFromOrder" /></span>
            <span class="pl-1"
              >Aufträge in den Versand aufnehmen, Standardauswahl</span
            >
          </div>
        </div>
      </Fieldset>
      <p></p>
    </div>
  </template>
</template>

<script setup>
///TODO Stemel hochladen mit häckchen Stemple freistellen, muss noch eine InputSwitch machen di eje nachdem
///ob das häckchen gesetzt ist, das bild freistellt oder nicht
///TODO Unterschrift hochladen mit häckchen Unterschrift freistellen, muss noch eine InputSwitch machen di eje nachdem
///ob das häckchen gesetzt ist, das bild freistellt oder nicht

import {
  ref,
  onMounted,
  computed,
  watch,
  onBeforeUnmount,
  onBeforeMount,
} from "vue";

import { useToast } from "primevue/usetoast";
import { userStore } from "@/store/userStore";
import { storeToRefs } from "pinia";
import iAxios from "@/store/axiosInterface";
import fAxios from "@/store/faxiosInterface";
import { reactive,inject } from "vue";
import handsign from "@/components/HandSign.vue";
import { compareJSONObjects } from "@/utils/debugCompareJsons";
import download from "downloadjs";
import { debugResponse, errorToast } from "@/components/useDebugToast";
import InputNumber from "primevue/inputnumber";
import * as calcUtils from "@/utils/calculationUtils.js";
import { validate } from "vee-validate";
import TipsSettings from "@/components/TipsSettings.vue";
import { SettingsExtended } from "@/service/SettingsExtended";

const extendedShopSettings = inject('extendedShopSettings');
const opTaxes = ref();
const opInfo = ref();
const changedNotSaved = ref(false);
const store = userStore();
const { isMainUser } = storeToRefs(store);
const toast = useToast();
const imagePositionClass = ref("");
const imageSizeClass = ref("");
const showOverlayPanel = (event) => {
  opTaxes.value.toggle(event);
};
const pdfPreview = ref(null);
const loading = ref(false);

const onUpload = () => {
  toast.add({
    severity: "info",
    summary: "Success",
    detail: "Logo hochgeladen",
    life: 3000,
  });
};
const logoPositionOptions = ref([
  { label: "Links", value: "LEFT" },
  { label: "Zentriert", value: "CENTER" },
  { label: "Rechts", value: "RIGHT" },
]);

const logoSizeOptions = ref([
  { label: "Klein", value: "SMALL" },
  { label: "Mittel", value: "MEDIUM" },
  { label: "Groß", value: "LARGE" },
]);

const maxPossibleBriefbogenHeight = ref(0);
const maxPossibleBriefbogenWidth = ref(0);
const maxImageLogoWidth = computed(() => {
  return imageBriefbogenWidth.value * 0.9;
});
//const maxImageLogoHeight = ref(0);
const maxImageLogoHeight = computed(() => {
  return imageBriefbogenHeight.value * 0.3;
});

const debug = ref(store.debug);
const currentDomain = window.location.hostname;
const isAppDomain = ref(currentDomain.startsWith("app."));

const activeTexte = ref(0);
const tabButtons = [
  { label: "Angebote" },
  { label: "Aufträge" },
  { label: "Rechnungen" },
  { label: "Gutschriften" },
  { label: "Lieferschein" },
  { label: "Zahlungserinnerung" },
  { label: "1. Mahnung" },
  { label: "2. Mahnung" },
  { label: "3. Mahnung" },
];
const activeEmails = ref(0);
const taxRef = ref();
const stampRef = ref();
const signRef = ref();
const companyLogoRef = ref();
const tipsRef = ref();
const textRef = ref();
const qrRef = ref();
const docNamesRef = ref();
const paymentTermsRef = ref();
const shippingRef = ref();
const communcationRef = ref();
const productsRef = ref();
const customerRef = ref();
const reminderRef = ref();
const removeBgStamp = ref(true);
const removeBgSign = ref(true);
const logoPreview = ref("");
const companyLogoUrl = ref();
const stampImage = ref();
const signatureImage = ref();
const imageBriefbogenWidth = ref();
const imageBriefbogenHeight = ref();
const imageLogoWidth = ref();
const imageLogoHeight = ref();
const taxColumns = ref([
  { field: "optionLabel", header: "Name" },
  { field: "taxValue", header: "Wert" },
  { field: "description", header: "Notiz" },
  { field: "legalNotice", header: "Hinweis Text" },
  { field: "showInPrints", header: "Ausdruck" },
]);
const paymentColumns = ref([
  { field: "value", header: "Bezeichnung" },
  { field: "notice", header: "Hinweis Text" },
  { field: "details", header: "Standard-Wert" },
]);
const itemUnitsColumns = ref([
  { field: "optionLabel", header: "Bezeichnung" },
  { field: "optionValue", header: "Kurzform" },
]);
const onCellEditComplete = (event) => {
  let { data, newValue, field } = event;
  data[field] = newValue;
};

const deleteTax = (data) => {
  const index = formData.taxOptions.taxOptionsData.indexOf(data);
  if (index > -1) {
    formData.taxOptions.taxOptionsData.splice(index, 1);
  }
};

const getDocIdentSettings = async () => {
  const url = "/company/settings";

  let response = await iAxios.get(url);
  debugResponse(toast, response);

  if (response.status == 200) {
    const data = response.data.docIdentSettings;

    console.log("🚀 ~ DOCIDENTSETTINGS BE  ~ 🚀:", data);
    return data;
  }
};

/*
Jeweils für Angebot, Auftrag/Bestellung und Rechnung eine eigene Nummernschablone mit Prefix, Suffix und Zähler mit jeweiligen Feldern für die Nummerierung erstellen
                    schritt 1.) get von Server und prüfen ob die id die gespeichert werden soll mind. gleich groß oder größer ist
                    schritt 2.) wenn nein, dann Toast-Meldung ausgeben mit Fehler, das die Nummerierung nicht kleiner sein darf
                    schritt 3.) wenn ja, dann speichern */
const setNummernKreise = async () => {
  const url = "/company/settings";

  let response = await iAxios.get(url);
  debugResponse(toast, response);

  if (response.status == 200) {
    const data = response.data;

    console.log("🚀 Nummernkreise vom BE:", data);

    const docIdentSettings = data.docIdentSettings;
    const offer = docIdentSettings.OFFER;
    const order = docIdentSettings.ORDER;
    const invoice = docIdentSettings.INVOICE;
    const creditnote = docIdentSettings.CREDITNOTE;
    const shipping = docIdentSettings.SHIPPING;
    let validate = true;

    if (formData.docIdentSettings.OFFER.ctr < offer.ctr) {
      formData.docIdentSettings.OFFER.ctr = offer.ctr;
      toast.add({
        severity: "error",
        summary: "Error",
        detail: "Angebotsnummerierung darf nicht kleiner sein",
        life: 3000,
      });
      validate = false;
    }
    if (formData.docIdentSettings.ORDER.ctr < order.ctr) {
      formData.docIdentSettings.ORDER.ctr = order.ctr;
      toast.add({
        severity: "error",
        summary: "Error",
        detail: "Auftragsnummerierung darf nicht kleiner sein",
        life: 3000,
      });
      validate = false;
    }
    if (formData.docIdentSettings.INVOICE.ctr < invoice.ctr) {
      formData.docIdentSettings.INVOICE.ctr = invoice.ctr;
      toast.add({
        severity: "error",
        summary: "Error",
        detail: "Rechnungsnummerierung darf nicht kleiner sein",
        life: 3000,
      });
      validate = false;
    }
    if (formData.docIdentSettings.CREDITNOTE.ctr < creditnote.ctr) {
      formData.docIdentSettings.CREDITNOTE.ctr = creditnote.ctr;
      toast.add({
        severity: "error",
        summary: "Error",
        detail: "Gutschriftsnummerierung darf nicht kleiner sein",
        life: 3000,
      });
      validate = false;
    }
    if (formData.docIdentSettings.SHIPPING.ctr < shipping.ctr) {
      formData.docIdentSettings.SHIPPING.ctr = shipping.ctr;
      toast.add({
        severity: "error",
        summary: "Error",
        detail: "Lieferscheinnummerierung darf nicht kleiner sein",
        life: 3000,
      });
      validate = false;
    }
  }

  if (!validate) return;

  response = await iAxios.put(url, {
    docIdentSettings: formData.docIdentSettings,
  });
  debugResponse(toast, response);

  if (response.status == 200) {
    toast.add({
      severity: "success",
      summary: "Success",
      detail: "Nummernschablone gespeichert",
      life: 3000,
    });
  } else {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: "Nummernschablone nicht gespeichert",
      life: 3000,
    });
  }
};

const removeStamp = () => {
  stampImage.value = "";
  processedStampImage.value = "";
  iAxios.delete("/company/stamp");
  formData.inputStampInLetters = false;
};
const removeSignature = () => {
  signatureImage.value = require("@/assets/BeispielUnterschrift.png");
  processedStampImage.value = "";
  iAxios.delete("/company/signature");
  formData.inputHandSignLetters = false;
};
const removeLogo = () => {
  formData.logoUrl = "";
  processedStampImage.value = "";
  iAxios.delete("/company/logo");
  companyLogoUrl.value = require("@/assets/beispielLogo.png");
};

const infoToggleTax = () => {
  toast.add({
    severity: "info",
    summary: "Info",
    detail:
      "Sie können belieblig Steuersätze hinzufügen, der HinweisText wird auf den Dokumenten angezeigt. Der Steuersatz wird in Prozent angegeben. Der Wert wird als ganze Zahl eingegeben.",
    life: 3000,
  });
};

const infoToggleItemUnit = () => {
  toast.add({
    severity: "info",
    summary: "Info",
    detail:
      "Hier geben Sie die Einheiten ein, die für die Artikel jeweils ausgewählt werden können. Auf den Dokumenten wird die Einheit in Kurzform angezeigt.",
    life: 3000,
  });
};

const infoTogglePayment = () => {
  toast.add({
    severity: "info",
    summary: "Info",
    detail:
      "Hier geben Sie die Zahlungsbedingungen ein, die auf Ihren Dokumenten erscheinen sollen. Verfügbar bedeutet das die Bedingungen im Onlineshop als Standard für Kunden ausgewählt sind. Die Bedingungen können für den Onlineshop je Kunde seperat von Menü Kontakte geändert werden. ",
    life: 5000,
  });
};

// Funktion zum Entfernen der Farbe Schwarz
const processedStampImage = ref("");
const processedSignImage = ref("");

const formData = reactive({
  companyEditorField: "Ihr Nummer",
  defaultCustomerCreditLimit: {
    activateCreditLimit: false,
    maxLimit: 0,
  },
/*   extendedShopSettings: {
    isOnlineShopActive: false,
    homeSlider: [
      {
        homePageTitel: "BOAHH!",
        homePageSubTitel: "Ein Erlebnis das Niemand vergisst!",
        homeImages: null,
      },
    ],
    sliderItems: [],
    hideItemsWithoutStock: false,
    laufText: "",
    laufTextDauer: 0,
    partnerLogos: [
      {
        name: "",
        image: null,
      },
    ],
  }, */
  //cid: nur Server
  showDiscountProduct: true,
  showNewProducts: true,
  showCustomerActivityWarning: true,
  delieveryNotesWithAlias: false,
  lastCustomerActivityWarningDays: 14,
  maxDaysLeftForNewProduct: 5,
  allowRegister: false,
  logoUrl: "",
  paymentModuleApiKey: "",
  collectSameItems: false,
  defaultOfferValidUntil: 14,
  defaultLowInStockNotification: 1,
  defaultInputVPE: true,
  showItemDescriptionInDocsDefault: {
    offer: true,
    order: true,
    invoice: true,
    creditnote: true,
    shipping: true,
  },
  guiSettings: { companyLogoWidth: 0, companyLogoHeight: 0 },
  companyLogoPosition: "RIGHT", //LEFT,center,RIGHT
  companyLogoSize: "MEDIUM", //small,MEDIUM,LARGE
  defaultItemUnit: "Stk.",
  defaultPaymentKind: ref("Banküberweisung"),
  defaultPrintPreview: false,
  defaultTaxId: "0-2",
  defaultTaxValue: 19,
  defaultShipFromInvoice: false,
  defaultShipFromOrder: true,
  deliveryFinalText:
    "Bei Fragen und Wünschen stehen wir Ihnen gerne zur Verfügung.",
  deliveryIntroduction: "Sie erhalten anbei unseren Lieferschein für [DOCNR].",
  docIdentSettings: {
    ///TODO ctr überprüfung wenn kleiner größer darf nicht gesetzt weren

    DRAFT: {
      ctr: 0,
      prefix: "EN-",
      suffix: "",
    },
    INVOICE: {
      ctr: 0,
      prefix: "RE-",
      suffix: "",
    },
    CREDITNOTE: {
      ctr: 0,
      prefix: "GU-",
      suffix: "",
    },
    OFFER: {
      ctr: 0,
      prefix: "AN-",
      suffix: "",
    },
    ORDER: {
      ctr: 0,
      prefix: "BE-",
      suffix: "",
    },
    SHIPPING: {
      ctr: 0,
      prefix: "LI-",
      suffix: "",
    },
  },
  docEmailSettings: {
    INVOICE: {
      replyTo: null,
      cc: null,
      bcc: null,
      subject: "Rechnung _DOC_NR",
      message: `Sehr geehrte Damen und Herren,


 anbei erhalten Sie das _DOC_TITEL _DOC_NR als Anlage, mit der bitte um zeitnahe Begleichung, falls nicht bereits geschehen.

 Mit freundlichen Grüßen

 _COMPANY_NAME`,
    },
    INVOICE_CORRECTION: {
      replyTo: null,
      cc: null,
      bcc: null,
      subject: "Rechnungskorrektur _DOC_NR",
      message: "Sie erhalten anbei unsere Rechnungskorrektur.",
    },
    CREDITENOTE: {
      replyTo: null,
      cc: null,
      bcc: null,
      subject: "Gutschrift _DOC_NR",
      message: "Sie erhalten anbei unsere Gutschrift.",
    },
    OFFER: {
      replyTo: null,
      cc: null,
      bcc: null,
      subject: "Angebot _DOC_NR",
      message: `Sehr geehrte Damen und Herren,


 anbei erhalten Sie das _DOC_TITEL _DOC_NR als Anlage, mit der bitte um zeitnahe und positive Antwort, falls nicht bereits geschehen.

 Mit freundlichen Grüßen

 _COMPANY_NAME`,
    },
    ORDER: {
      replyTo: null,
      cc: null,
      bcc: null,
      subject: "Auftragsbestätigung _DOC_NR",
      message: "Sie erhalten anbei unsere Auftragsbestätigung.",
    },
    SHIPPING: {
      replyTo: null,
      cc: null,
      bcc: null,
      subject: "Lieferschein _DOC_NR",
      message: "Sie erhalten anbei unseren Lieferschein.",
    },
    /*         PARCEL: {
                    replyTo: null,
                    cc: null,
                    bcc: null,
                    subject: "Versand/Lieferbestätigung _DOC_NR",
                    text: "Sie erhalten anbei unsere Versand/Lieferbestätigung."
                }, */
    LETTER: {
      replyTo: null,
      cc: null,
      bcc: null,
    },
  },
  finalTextTemplate: { templateName: "", templateText: "" }, //Json
  finalTextTemplates: { templateName: "", templateText: "" }, //Json
  headerTemplate: { templateName: "", templateText: "" }, //Json
  //id: 4, nur Server
  inputHandSignLetters: true,
  inputStampInLetters: true,
  stamp_sign_overlay: true,
  introductionTemplates: { templateName: "", templateText: "" }, //Json
  invoiceFinalText:
    "Vielen Dank für Ihren Auftrag. Bei Fragen und Wünschen stehen wir Ihnen gerne zur Verfügung.",
  invoiceIntroduction: " Sie erhalten anbei unsere Rechnung.",
  creditNoteFinalText:
    "Vielen Dank für Ihren Auftrag. Bei Fragen und Wünschen stehen wir Ihnen gerne zur Verfügung.",
  creditNoteIntroduction: " Sie erhalten anbei unsere Gutschrift.",
  stockControl: true,
  mhdDefault: true,
  showCompanyLogo: true,
  showCompanyLogoInEmails: true,
  showEanInDocuments: true,
  showFinalText: true,
  showFooter: true,
  showIntroduction: true,
  showVkInDeliverySlip: false,
  showLegalDisclaimer: true,
  legalDisclaimer:
    "Als Privatperson sind Sie gesetzlich dazu verpflichtet, gemäß § 14b Abs. 1 UStG diese Rechnung für einen Zeitraum von zwei Jahren ab dem Ende des Kalenderjahres aufzubewahren.",
  showSenderLine: true,
  salutationText: "Sehr geehrte Damen und Herren,",
  offerFinalText:
    "Vielen Dank für Ihr Interesse an unseren Produkten. Wir freuen uns auf Ihre Bestellung.",
  orderFinalText:
    "Vielen Dank für Ihren Auftrag. Bei Fragen und Wünschen stehen wir Ihnen gerne zur Verfügung.",
  letterFinalText: "Vielen Dank für Ihren Auftrag.",
  offerIntroduction: "Anbei erhalten Sie unser Angebot.",
  orderIntroduction: " Sie erhalten anbei unsere Auftragsbestätigung.",

  paymentReminderIntroduction: `Es kann jedem einmal passieren, dass eine Rechnung übersehen wird. Daher möchten wir Sie ganz höflich daran erinnern, dass der Betrag noch nicht beglichen wurde.

Wir wären Ihnen äußerst dankbar, wenn Sie die Zahlung so bald wie möglich vornehmen könnten. Falls es hierbei Schwierigkeiten gibt, zögern Sie bitte nicht, uns zu kontaktieren.`,

  paymentReminderFinalText: `Dieses Schreiben wurde automatisch von unserem System erstellt. Bitte verstehen Sie, dass es sich um eine routinemäßige Erinnerung handelt und keine individuelle Bewertung Ihrer Situation stattgefunden hat. Wir danken Ihnen für Ihr Verständnis.`,

  firstDunningNoticeIntroduction: `Wir möchten Sie höflich daran erinnern, dass der Betrag der Rechnung leider noch nicht beglichen wurde.

Bitte überweisen Sie den offenen Betrag innerhalb der nächsten Tage, um weitere Mahnungen zu vermeiden. Sollten Sie Fragen zur Rechnung haben, kontaktieren Sie uns bitte umgehend.`,

  firstDunningNoticeFinalText: `Dieses Schreiben wurde automatisch von unserem System erstellt. Es handelt sich um eine höfliche Erinnerung, die keine individuelle Prüfung Ihrer Situation darstellt. Wir danken Ihnen für Ihr Verständnis und Ihre rechtzeitige Bearbeitung.`,

  secondDunningNoticeIntroduction: `Leider müssen wir Sie erneut darauf hinweisen, dass die Rechnung noch unbezahlt ist. Bitte nehmen Sie die Zahlung in den nächsten Tagen vor.

Wir bitten Sie, die Zahlung unverzüglich vorzunehmen, um weitere Maßnahmen zu vermeiden. Falls Sie Schwierigkeiten haben, kontaktieren Sie uns bitte, damit wir gemeinsam eine Lösung finden können.`,

  secondDunningNoticeFinalText: `Auch diese Erinnerung wurde automatisch von unserem System erstellt, da wir bisher keinen Zahlungseingang feststellen konnten. Bitte beachten Sie, dass es sich hierbei um eine routinemäßige Mahnung handelt.

Wir danken Ihnen für Ihr Verständnis und bitten Sie, die Zahlung so bald wie möglich vorzunehmen.`,

  thirdDunningNoticeIntroduction: `Wir möchten Sie letztmalig daran erinnern, dass der ausstehende Betrag noch nicht beglichen wurde. Um unangenehme Konsequenzen zu vermeiden, bitten wir Sie höflich um Ihre sofortige Zahlung.

Bitte überweisen Sie den Betrag umgehend, da wir andernfalls gezwungen sind, rechtliche Schritte zu prüfen. Wir hoffen weiterhin auf Ihre Kooperation.`,

  thirdDunningNoticeFinalText: `Dieses Mahnschreiben wurde automatisch erstellt, da wir bisher noch keinen Zahlungseingang verzeichnen konnten. Wir verstehen, dass es immer mal zu Verzögerungen kommen kann, und hoffen weiterhin auf Ihr Verständnis. Sollte das System in den nächsten Tagen jedoch keinen Zahlungseingang feststellen, werden automatisch weitere Schritte wie die Einschaltung eines Inkassobüros oder die Beantragung eines Mahnbescheids eingeleitet. Bitte kümmern Sie sich daher um die Begleichung der offenen Forderung, um diese zusätzlichen Maßnahmen zu vermeiden.`,
  taxOptions: {
    taxOptionsData: [
      {
        id: "0-1",
        description: "",
        legalNotice: "",
        optionLabel: "7%",
        taxValue: 7,
        showInPrints: true,
      },
      {
        id: "0-2",
        description: "",
        legalNotice: "",
        optionLabel: "19%",
        taxValue: 19,
        showInPrints: true,
      },
      {
        id: "0-3",
        description: "",
        legalNotice: "",
        optionLabel: "0%",
        taxValue: 0,
        showInPrints: true,
      },
      {
        id: "0-4",
        description: "",
        legalNotice:
          "Gemäß § 19 UStG enthält der Rechnungsbetrag keine Umsatzsteuer.",
        optionLabel: "0% gemäß § 19 UStG",
        taxValue: 0,
        showInPrints: true,
      },
    ],
  },
  showPaymentTerms: true,
  paymentKinds: ref({
    paymentData: [
      {
        value: "Bar",
        notice: "Barzahlung bei Abholung",
        details: {
          termDays: 0,
          skontoDays: 0,
          skonto: 0,
          fee: 0,
          deletable: true,
        },
        available: true,
        public: true,
      },
      {
        value: "Banküberweisung",
        notice:
          "Wir bitten um Überweisung des Rechnungsbetrages innerhalb von _DAYS Tagen auf das unten angegebene Konto.",
        details: {
          termDays: 14,
          skontoDays: 14,
          skonto: 3,
          fee: 0,
          deletable: false,
        },
        available: true,
        public: false,
      },
      {
        value: "Vorkasse",
        notice:
          "Wir bitten um Vorkasse des Rechnungsbetrages auf das unten angegebene Konto oder in Bar.",
        details: {
          termDays: 0,
          skontoDays: 0,
          skonto: 0,
          fee: 0,
          deletable: false,
        },
        available: true,
        public: true,
      },
      {
        value: "Kartenzahlung",
        notice:
          "Sie können den Rechnungsbetrag bequem per Kartenzahlung begleichen.",
        details: {
          termDays: 0,
          skontoDays: 0,
          skonto: 0,
          fee: 0,
          deletable: true,
        },
        available: false,
        public: false,
      },
      {
        value: "Nachnahme",
        notice:
          "Bitte halten Sie den Rechnungsbetrag passend bereit, da die Zahlung bei Lieferung erfolgt.",
        details: {
          termDays: 0,
          skontoDays: 0,
          skonto: 0,
          fee: 0,
          deletable: true,
        },
        available: false,
        public: false,
      },
      {
        value: "Lastschrift",
        notice:
          "Der Rechnungsbetrag wird von Ihrem angegebenen Bankkonto abgebucht.",
        details: {
          termDays: 0,
          skontoDays: 0,
          skonto: 0,
          fee: 0,
          deletable: true,
        },
        available: false,
        public: false,
      },
      {
        value: "Kryptowährungen",
        notice:
          "Wir akzeptieren Zahlungen in Kryptowährungen. Bitte überweisen Sie den entsprechenden Betrag an die angegebene Wallet-Adresse: _WALLET_ADDRESS",
        details: {
          termDays: 0,
          skontoDays: 0,
          skonto: 0,
          fee: 0,
          acc: "1A1zP1eP5QGefi2DMPTfTL5SLmv7DivfNa",
          deletable: true,
        },
        notice:
          "Wir akzeptieren Zahlungen in Kryptowährungen. Bitte überweisen Sie den entsprechenden Betrag an die angegebene Wallet-Adresse: _WALLET_ADDRESS",
        value: "Kryptowährungen",
        available: false,
        public: false,
      },
      {
        value: "Ratenzahlung",
        notice:
          "Sie haben die Möglichkeit, den Rechnungsbetrag in Raten zu zahlen. Bitte beachten Sie die vereinbarten Zahlungsbedingungen.",
        details: {
          termDays: 14,
          skontoDays: 0,
          skonto: 0,
          fee: 0,
          deletable: true,
        },
        available: false,
        public: false,
      },
      {
        value: "Kundenkonto",
        notice:
          "Die Verrechnung erfolgt über Ihr Kundenkonto. Bitte beachten Sie die vereinbarten Zahlungsbedingungen.",
        details: {
          termDays: 0,
          skontoDays: 0,
          skonto: 0,
          fee: 0,
          deletable: false,
        },
        available: false,
        public: false,
      },
    ],
  }),
  itemUnits: {
    itemUnitsData: [
      { optionLabel: "Liter", optionValue: "l" },
      { optionLabel: "kg", optionValue: "kg" },
      { optionLabel: "Stk.", optionValue: "Stk." },
    ],
  },
  usePositionPricekindNet: true,
  useIndividualHeaderTemplate: false,
  useIndividualFinalTextTemplate: false,

  showPaymentQRCode: true,
  showOrderQRCode: true,
  showDeliveryQRCode: true,
  sendCopyToAnswerEmailAddress: true,
  answerEmailAddress: "",
  showCompanyLogoInEmails: true,
  reminderSettings: {
    is_auto_scheduled: true,
    send_payment_history: false,
    schedule_days_lvl_0: 1,
    schedule_days_lvl_1: 1,
    schedule_days_lvl_2: 1,
    schedule_days_lvl_3: 1,
    schedule_time: "19:00",
  },
  showReminderQRCode: true,
});
const taxShema = {
  id: generateUniqueId(),
  description: "",
  legalNotice: "",
  optionLabel: "%",
  taxValue: 0,
  showInPrints: true,
};

const paymentShema = {
  value: "Bar",
  notice: "Barzahlung bei Abholung",
  details: "",
  available: true,
};

const checkSignAvailable = () => {
  setTimeout(() => {
    if (
      signatureImage.value == require("@/assets/BeispielUnterschrift.png") ||
      signatureImage.value == "" ||
      signatureImage.value == null
    ) {
      formData.inputHandSignLetters = false;
      toast.add({
        severity: "warn",
        summary: "Unterschrift",
        detail:
          "Keine Unterschrift hinterlegt. Bitte vorerst Unterschrift hinterlegen oder hochladen.",
        life: 3000,
      });
    }
  }, 100);
};

const checkStampAvailable = () => {
  setTimeout(() => {
    if (
      stampImage.value == require("@/assets/BeispielStempel.png") ||
      stampImage.value == "" ||
      stampImage.value == null
    ) {
      formData.inputStampInLetters = false;
      toast.add({
        severity: "warn",
        summary: "Stempel",
        detail:
          "Kein Stempel hinterlegt. Bitte vorerst Stempel hinterlegen oder hochladen.",
        life: 3000,
      });
    }
  }, 100);
};

const addPaymentEntry = () => {
  const paymentOption = JSON.parse(JSON.stringify(paymentShema));
  formData.paymentKinds.paymentData.push({ ...paymentOption });
};

const deletePaymentEntry = (data) => {
  const index = formData.paymentKinds.paymentData.indexOf(data);
  if (index > -1) {
    formData.paymentKinds.paymentData.splice(index, 1);
  }
};

const addTaxEntry = () => {
  const taxOption = JSON.parse(JSON.stringify(taxShema));
  formData.taxOptions.taxOptionsData.push({ ...taxOption });
};

const itemUnitShema = {
  optionLabel: "(Einheit)",
  optionValue: "(Kurzform)",
};

const addItemUnitEntry = () => {
  const itemUnit = JSON.parse(JSON.stringify(itemUnitShema));
  formData.itemUnits.itemUnitsData.push({ ...itemUnit });
};

const deleteItemUnitEntry = (data) => {
  const index = formData.itemUnits.itemUnitsData.indexOf(data);
  if (index > -1) {
    formData.itemUnits.itemUnitsData.splice(index, 1);
  }
};

function generateUniqueId() {
  const timestamp = Date.now();
  const random = Math.floor(Math.random() * 10000);
  return `${timestamp}-${random}`;
}

watch(
  formData,
  () => {
    console.log("formData changed");
    changedNotSaved.value = true;
  },
  { deep: true }
);

const loadImage = async (type) => {
  console.log("loadImage", type);
  type = type ?? "logo";
  const stamp = type === "stamp";
  const signature = type === "signature";
  let response = null;
  try {
    let url = process.env.VUE_APP_BASE_API_URL + "/company/" + type;
    response = await iAxios
      .get(url, {
        responseType: "arraybuffer",
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const blob = new Blob([response.data]);
        const fileURL = URL.createObjectURL(blob);
        if (response.status == 204) {
          fileURL = null;
        }
        if (stamp) {
          stampImage.value = fileURL;
        } else if (signature) {
          signatureImage.value = fileURL;
        } else {
          companyLogoUrl.value = fileURL;
        }
      })
      .catch((error) => {
        //console.error(error);
        //errorToast(toast, error);
      });
    debugResponse(toast, response);
  } catch (error) {
    //console.error(error);
    //errorToast(toast, error);
  }
};

const changeLogoImageClass = async () => {
  const size = formData.companyLogoSize;
  const position = formData.companyLogoPosition;
  let newLogoPositionClass = "p-0 overlayLogo"; // Standardklasse
  let newLogoSizeClass = "";
  if (size == "LARGE") {
    newLogoSizeClass += " logoSizeLarge";
  } else if (size == "MEDIUM") {
    newLogoSizeClass += " logoSizeMedium";
  } else if (size == "SMALL") {
    newLogoSizeClass += " logoSizeSmall";
  }
  // Positionsbehandlung
  switch (position) {
    case "LEFT":
      newLogoPositionClass += " logoPosition-LEFT";
      break;
    case "RIGHT":
      newLogoPositionClass += " logoPosition-RIGHT";
      break;
    case "CENTER":
      newLogoPositionClass += " logoPosition-CENTER";
      break;
    default:
      break;
  }

  const style = document.createElement("style");
  let existingStyle = document.querySelector('style[data-id="custom-style"]');

  setTimeout(async () => {
    getCompanyLogoSize();
    if (!existingStyle) {
      style.setAttribute("data-id", "custom-style");
      document.head.appendChild(style);
      existingStyle = style;
    }

    let logoHeightLarge = "";

    let logoWidthLarge = "";
    let logoWidthMedium = "";
    let logoHeightMedium = "";
    let logoWidthSmall = "";
    let logoHeightSmall = "";
    if (adjustToHeight.value == true) {
      logoWidthLarge = "auto";
      logoHeightLarge = `${maxImageLogoHeight.value}px`;
      logoWidthMedium = "auto";
      logoHeightMedium = `${maxImageLogoHeight.value * (2 / 3)}px`;
      logoWidthSmall = "auto";
      logoHeightSmall = `${maxImageLogoHeight.value * (1 / 3)}px`;
    } else {
      logoWidthLarge = `${maxImageLogoWidth.value}px`;
      logoHeightLarge = "auto";
      logoWidthMedium = `${maxImageLogoWidth.value * (2 / 3)}px`;
      logoHeightMedium = "auto";
      logoWidthSmall = `${maxImageLogoWidth.value * (1 / 3)}px`;
      logoHeightSmall = "auto";
    }

    existingStyle.innerHTML = `
        .logoSizeLarge {
            width: ${logoWidthLarge};
            height: ${logoHeightLarge};
        }

        .logoSizeMedium {
            width: ${logoWidthMedium};
            height: ${logoHeightMedium};
        }

        .logoSizeSmall {
            width: ${logoWidthSmall};
            height: ${logoHeightSmall};
        }
        .logoPosition-LEFT {
            left: 3.85%;
        }
        .logoPosition-RIGHT {
            right:  3.85%;
        }

        .logoPosition-CENTER {
            left: 50%;
            transform: translateX(-50%);}`;
  }, 10);

  imagePositionClass.value = newLogoPositionClass;
  imageSizeClass.value = newLogoSizeClass;
};

const svgImage = ref(null);
const logoImageRef = ref(null);

const onImageBriefbogenLoad = async () => {
  getSvgSize();
};

const onImageLogoLoad = async () => {
  getCompanyLogoSize();
  maxPossibleBriefbogenHeight.value = Math.floor(
    imageBriefbogenHeight.value / 4
  );

  maxPossibleBriefbogenWidth.value = Math.floor(
    imageBriefbogenWidth.value - 40
  );

  /*   maxImageLogoWidth.value =
    (await imageLogoWidth.value) > maxPossibleBriefbogenWidth.value
      ? maxPossibleBriefbogenWidth.value
      : imageLogoWidth.value; */
  /*   maxImageLogoHeight.value =
    (await imageLogoHeight.value) > maxPossibleBriefbogenHeight.value
      ? maxPossibleBriefbogenHeight.value
      : imageLogoHeight.value; */
  //checkAdjustToHeight();
  changeLogoImageClass();
};

const getCompanyLogoSize = () => {
  if (logoImageRef.value?.offsetWidth) {
    const width = logoImageRef.value.offsetWidth;
    const height = logoImageRef.value.offsetHeight;
    imageLogoWidth.value = width;
    imageLogoHeight.value = height;
    //console.log(`-companyLogo Größe: ${width}x${height}`);
  }
};
const getSvgSize = () => {
  if (svgImage.value?.offsetWidth) {
    const width = svgImage.value.offsetWidth;
    const height = svgImage.value.offsetHeight;
    imageBriefbogenWidth.value = width;
    imageBriefbogenHeight.value = height;
    // console.log(`-SVG Größe: ${width}x${height}`);
  }
};
const adjustLogoSize = () => {
  if (svgImage.value && logoImageRef.value) {
    const svgWidth = parseInt(maxPossibleBriefbogenWidth.value);
    const svgHeight = parseInt(maxPossibleBriefbogenHeight.value);
    const logoWidth = parseInt(imageLogoWidth.value);
    const logoHeight = parseInt(imageLogoHeight.value);

    // Berechnen des Verhältnis zwischen den beiden Bildern
    const ratioWidth = svgWidth / logoWidth;
    const ratioHeight = svgHeight / logoHeight;
    imageLogoWidth.value = svgWidth * ratioWidth;
    imageLogoHeight.value = svgHeight * ratioHeight;
  }
};

const onResize = () => {
  adjustLogoSize();
  getCompanyLogoSize();
  getSvgSize();
  changeLogoImageClass();
};

const adjustToHeight = computed(() => {
  return imageLogoWidth.value >= imageLogoHeight.value;
});
/* const checkAdjustToHeight = () => {
  if (logoImageRef.value) {
    const logoWidth = imageLogoWidth.value;
    const logoHeight = imageLogoHeight.value;
    adjustToHeight.value = logoWidth > logoHeight;
  }
}; */

onBeforeMount(async () => {
  await werteLaden();
});

onBeforeUnmount(() => {
  window.removeEventListener("resize", onResize);
});

onMounted(async () => {
  window.addEventListener("resize", onResize);
  await loadImage();
  await loadImage("stamp");
  await loadImage("signature");
  changedNotSaved.value = false;
});

const customBase64Uploader = async (event, type, removebg) => {
  console.log("customBase64Uploader", type);
  const isLogo = type === "logo";
  const isStamp = type === "stamp";
  const isSignature = type === "signature";
  const isHomeImage = type === "homeImages";
  const file = event.files[0];

  //const file2 = event.files[0];
  const reader = new FileReader();
  reader.onloadend = async function () {
    const base64Data = reader.result;
    logoPreview.value = base64Data;

    if (isLogo) {
      // Bildauflösung auslesen
      const img = new Image();
      img.onload = function () {
        const width = img.naturalWidth;
        const height = img.naturalHeight;
        formData.guiSettings.companyLogoWidth = width;
        formData.guiSettings.companyLogoHeight = height;
        imageLogoHeight.value = height;
        imageLogoWidth.value = width;
        console.log(`Bildauflösung: ${width} x ${height}`);
      };
      img.src = base64Data;
    }

    const fileData = new FormData();
    //const fileData2 = new FormData();
    fileData.append("filedata", file);
    //fileData2.append('filedata', file);
    if (removebg == "removebg_stamp") fileData.append(removebg, true);
    if (removebg == "removebg_signature") fileData.append(removebg, true);
    try {
      const response = await fAxios.post("/company/" + type, fileData);
      let url2 =
        "https://storage.famtura.de/api/auth/files/upload/" +
        store.apiKeyStorage +
        "/" +
        store.cid;
      if (store.debug)
        console.log("🚀 ~ file: DocumentSettings.vue:3259 ~ url2:", url2);

      const response2 = await fAxios.post(url2, fileData);

      if (isStamp && response.status == 201) {
        formData.inputStampInLetters = true;
      }
      if (isSignature && response.status == 201) {
        formData.inputHandSignLetters = true;
      }
      console.log("islogo", isLogo);

      const parts = await response2.data.fileUrl.split("files/");
      const resultURL = parts[1];
      const publicUrl = "https://storage.famtura.de/api/files/" + resultURL;
      if (store.debug)
        console.log(
          "🚀 ~ file: DocumentSettings.vue:1695 ~ publicLogoUrl:",
          publicUrl,isLogo
        );

      if (isLogo) formData.logoUrl = publicUrl;

      if (isHomeImage) {
        if (
          extendedShopSettings?.homeSlider &&
          extendedShopSettings?.homeSlider?.length > 0 &&
          extendedShopSettings?.homeSlider?.[0].homeImages != null
        ) {
          extendedShopSettings.homeSlider.push({
            homeImages: publicUrl,
            homePageTitel: "",
            homePageSubTitel: "",
          });
        } else {
          extendedShopSettings.homeSlider[0].homeImages = publicUrl;
        }
      }
      if (isStamp) stampImage.value = publicUrl;

      saveForm();
      onUpload();
      setTimeout(async () => {
        await loadImage();
        await loadImage("stamp");
        await loadImage("signature");
      }, 100);

      // Wenn du weitere Aktionen mit der Antwort durchführen möchtest, füge den Code hier hinzu.
    } catch (error) {
      console.error(error);
      //errorToast(toast, error);
    }

    werteLaden(); // Dies sollte außerhalb des try-catch-Blocks sein, wenn es nach dem Upload ausgeführt werden soll.
  };

  // Lade die Blob-Daten und starte den Konvertierungsprozess
  let blob = await fetch(file.objectURL).then((r) => r.blob());
  reader.readAsDataURL(blob);
  setTimeout(() => {
    adjustLogoSize();
    //checkAdjustToHeight();
    changeLogoImageClass();
    saveForm();
  }, 300);
};

function uploadStamp(event) {
  // Hochgeladenes Stempelbild verarbeiten
}

function updateSignature(signature) {
  formData.inputHandSignLetters = true;
  loadImage("signature");
}

const werteLaden = async () => {
  try {
    const res = await iAxios.get("/company/settings");
    debugResponse(toast, res);

    if (debug.value) {
      console.log(
        "🚀 ~ file: DocumentSettings.vue:490 ~ werteLaden ~ res.data:",
        res.data
      );
      console.log(
        compareJSONObjects(
          formData,
          res.data,
          false,
          "lokal",
          "Daten von Server"
        )
      );
    }
    Object.assign(formData, res.data);
    //formData.value = res.data;
    if (debug.value) {
      console.log("Daten aus EP geladen");
      //formData.value = res.data;
      // formData.taxOptions = res.data.taxOptions; // extra eingefügt
      console.log(
        "🚀 ~ file: DocumentSettings.vue:493 ~ werteLaden ~ formData:",
        formData
      );
    }
  } catch (error) {
    //console.error(error);
    //errorToast(toast, error);
  }
};

const previewForm = async () => {
  loading.value = true;

  const docContent = {
    invoiceTitel: "Rechnung",
    salutationText: store.companySettings.salutationText,
    invoiceIntroduction: store.companySettings.invoiceIntroduction,
    invoiceFooterNotes: null,
    invoiceFinalText: store.companySettings.invoiceFinalText,
    deliveryTitel: "Lieferschein",
    deliveryIntroduction: store.companySettings.deliveryIntroduction,
    deliveryFinalText: store.companySettings.deliveryFinalText,
    documentTitel: "Beispiel Dokument",
    documentIntroduction: "",
    documentFinalText: "Schlußtext",
    paymentTerms: [],
    paymentKind: store.companySettings.defaultPaymentKind,
    skontoText: null,
    skonto: 0,
    skontoZiel: 0,
    skontoValue: null,
    leistungsdatum: new Date(),
    documentDatum: new Date(),
    lohnAnteil: 0,
    zahlungsZiel: -1,
    faelligkeitsDatum: null,
    skontoBisDatum: null,
    rabatt: 0, // ist der gesamtRabatt
    documentValueNetto: 100.0,
    taxValue: { taxSum: 19.0, 19: 19.0 },
    documentValueBrutto: 119.0,
    shipping: { shippingPrice: 10.0 },
    storno: false,
    otherLegalNotices: [], //Array mit den Steuerrechtlichen Hinweisen
  };
  const itemListShema = {
    posNr: 1,
    itemDescription: "Beispielbeschreibung",
    rabatt: 0,
    quantity: 1,
    inputKind: null,
    itemActive: true,
    itemAlias: "BeispielAlias",
    itemCustomId: "123",
    itemImageUrl: null,
    itemInPrice: 2.0,
    itemName: "Beispiel-Artikel",
    itemOutPrice: 123.0,
    itemType: "goods",
    itemUnit: store.companySettings.defaultItemUnit,
    item_id_company: 1,
    options: null,
    itemTaxId: 1,
    itemTaxValue: 19,
    itemTaxValueFloat: 0.19,
    updated: null,
    createdUser: null,
    updatedUser: null,
    //aufschlag: 0,
    VkPreisBrutto: 119.0,
    summe: 119.0,
    selectedItem: null,
  };
  switch (activeTexte.value) {
    case 0:
      docContent.documentIntroduction = formData.offerIntroduction;
      docContent.documentFinalText = formData.offerFinalText;
      docContent.documentTitel = formData.offerTitel;
      break;
    case 1:
      docContent.documentIntroduction = formData.orderIntroduction;
      docContent.documentFinalText = formData.orderFinalText;
      docContent.documentTitel = formData.orderTitel;
      break;
    case 2:
      docContent.documentIntroduction = formData.invoiceIntroduction;
      docContent.documentFinalText = formData.invoiceFinalText;
      docContent.documentTitel = formData.invoiceTitel;
      break;
    case 3:
      docContent.documentIntroduction = formData.creditNoteIntroduction;
      docContent.documentFinalText = formData.creditNoteFinalText;
      docContent.documentTitel = formData.creditnoteTitel;
      break;
    default:
      break;
  }

  const itemList = [JSON.parse(JSON.stringify(itemListShema))];
  const companySettings = JSON.parse(JSON.stringify(store.companySettings));
  await Object.assign(companySettings, formData);
  const url = process.env.VUE_APP_BASE_API_URL + "/company/create-offer-draft";
  const data = {
    docContent: JSON.parse(JSON.stringify(docContent)),
    docSettings: JSON.parse(JSON.stringify(formData)),
    companySettings: companySettings,
    dontSave: true,
    itemList: itemList,
    relationFrom: {},
    as_attachment: true,
    documentId: 12345,
    receiver: {
      name1: "Max Mustermann",
      address1: "Musterstraße 1",
      postCode: "12345",
      city: "Musterstadt",
    },
  };

  console.log(
    "🚀 ~ file: DocumentSettings.vue:1432 ~ previewForm ~ data:",
    data
  );

  iAxios
    .post(url, data, {
      responseType: "blob",
    })
    .then((response) => {
      const blob = new Blob([response.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(blob);
      pdfPreview.value = fileURL;
      debugResponse(toast, response);

      //downloadPdf(res.data);
      console.log(response);
    })
    .catch((err) => {
      console.log(err);
    });
  setTimeout(() => {
    loading.value = false;
  }, 2000);
};

const saveForm = () => {
  const url = process.env.VUE_APP_BASE_API_URL + "/company/settings";
  console.log(
    "🚀 ~ file: DocumentSettings.vue:507 ~ saveForm ~ formData send to EP:",
    formData
  );
  console.log(
    "🚀 ~ file: DocumentSettings.vue:1466 ~ saveForm ~ changedNotSaved.value:",
    changedNotSaved.value
  );

  const rollbackDocIdentSettings = JSON.parse(
    JSON.stringify(formData.docIdentSettings)
  );
  formData.docIdentSettings = null;
  const res = iAxios
    .put(url, formData)
    .then(async (res) => {
      formData.docIdentSettings = await rollbackDocIdentSettings;
      setTimeout(() => {
        changedNotSaved.value = false;
        toast.add({
          severity: "success",
          summary: "Success",
          detail: "Daten gespeichert",
          life: 3000,
        });
        store.getCompanySettings;
      }, 100);
      // console.log(res);
    })
    .catch((err) => {
      console.log(err);
    })
    .then(() => {});
    store.getCompanySettings();
};

const downloadPdf = (blob) => {
  download(blob, "Beispiel-Vorschau.pdf", "application/pdf");
};


</script>
<style scoped>
.container {
  position: relative;
}

.overlayLogo {
  position: absolute;
  top: 6.25%;
}
</style>
