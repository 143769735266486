<template>
    <div class="block-content">
      <div class="surface-ground px-4 py-8 md:px-6 lg:px-8 flex align-items-center justify-content-center">
        <div class="surface-card p-4 shadow-2 border-round w-full lg:w-6">
          <div class="text-center mb-5">
            <div v-if="changeFirstTime" class="text-900 text-3xl font-medium mb-3">
              Änderung des Passwort vor Erstbenutzung notwending!
            </div>
            <div v-else class="text-900 text-3xl font-medium mb-3">
              Passwort speichern
            </div>
          </div>
          <div class="p-grid p-gap-2">
            <div class="p-col">
              <form @submit="savePassword">
                <div class="p-field">
                  <Password
                    v-model="password"
                    id="password"
                    :class="{ 'p-invalid': errors.password }"
                  />
                  <div class="p-invalid">{{ errors.password }}</div>
                </div><p></p>
  
                <div class="p-field">
                  <Password
                    v-model="confirmPassword"
                    id="confirmPassword"
                    :class="{ 'p-invalid': errors.confirmPassword }"
                  />
                  <div class="p-invalid">{{ errors.confirmPassword }}</div>
                </div><p></p>
                <Button label="Passwort speichern" @click="savePassword()"/>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>

<script setup>
import { ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useForm } from "vee-validate";
import { useToast } from "primevue/usetoast";
import iAxios from "@/store/axiosInterface";
import * as yup from "yup";
import { userStore } from "@/store/userStore";
import { storeToRefs } from 'pinia';

const store = userStore();
const toast = useToast();
const router = useRouter();
const route = useRoute();


const { changeFirstTime } = defineProps({
  changeFirstTime: Boolean,
  default: false,
});


// Formularfelder
const password = ref("");
const confirmPassword = ref("");
const emit = defineEmits(['hide']);

// Yup-Schema für die Validierung
const validationSchema = yup.object().shape({
  password: yup.string().required("Passwort ist erforderlich"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwörter müssen übereinstimmen")
    .required("Passwortbestätigung ist erforderlich"),
});

// VeeValidate-Formularhaken
const { handleSubmit, resetForm, errors } = useForm({
  validationSchema,
});

// Methode zum Zurücksetzen des Passworts
async function savePassword() {
  console.log("🚀 ~ file: ChangePasswordForm.vue:57 ~ changeFirstTime:", changeFirstTime)

  let response = null;
  try {
     handleSubmit();
    // Führe den API-Aufruf zum Zurücksetzen des Passworts mit iAxios durch
    const url=process.env.VUE_APP_BASE_API_URL+"/pass-reset";

    response = await iAxios.patch(url, {
      password: password.value,
    });
    console.log("🚀 ~ file: ChangePasswordForm.vue:83 ~ savePassword ~ response:", response)
    // Zeige eine Erfolgsmeldung an
    store.firstLogin = false;
    toast.add({
      severity: "success",
      summary: "Passwort erfolgreich gespeichert",
      detail: "Ihr Passwort wurde erfolgreich zurückgesetzt.",
      life: 2000,
    });
    setTimeout(() => {
      emit('hide');
    }, 10);
    // Formular zurücksetzen
    resetForm();
    router.push({ name: "login" });
  } catch (error) {
    console.log("🚀 ~ file: ChangePasswordForm.vue:94 ~ savePassword ~ error:", error)
    // Zeige eine Fehlermeldung an
    if (response.status==403){toast.add({
      severity: "error",
      summary: "Fehler beim Speichern des Passworts",
      detail: "Das Password wurde bereits zurückgesetzt, im Zweifelsfall starten Sie eine erneute Anfrage zum Zurücksetzeten des Passworts.",
    });}else
    {toast.add({
      severity: "error",
      summary: "Fehler beim Speichern des Passworts",
      detail: "Beim Zurücksetzen des Passworts ist ein Fehler aufgetreten.",
    });}
  }
}
</script>
