<template>
  <div class="card">
    <div class="flex-grow-1 flex justify-content-end">
      <Button
        class="shadow-7"
        label="Speichern"
        icon="pi pi-check"
        size="small"
        @click="saveForm()" />
    </div>
    <TabView>
      <TabPanel header="Impressum">
        <div class="card">
          <!--                     <InputSwitch name="showImpressum" textValue v-model="formData.showImpressum" />
                    <label for="showImpressum" class="ml-2">
                        Link Impressum anzeigen
                    </label> -->
          <Button
            label="Impressum generieren"
            outlined
            icon="pi pi-refresh"
            class="p-button-success text-xs p-1 m-0"
            v-tooltip="
              'Die Rechtssicherheit vom Impressum bitte selber überprüfen. Keine Gewähr auf Rechtsicherheit. Durch anklicken, wird der aktuelle Inhalt überschrieben!'
            "
            @click="generateImpressum()" />
          <small class="text-red-300 text-xs"
            >Die Rechtssicherheit vom Impressum bitte selber überprüfen. Keine
            Gewähr auf Rechtsicherheit.</small
          >
          <Editor
            v-model="formData.impressum"
            contentType="html"
            editorStyle="height: 640px" />
        </div>
      </TabPanel>
      <TabPanel header="AGB">
        <Button
          outlined
          icon="pi pi-refresh"
          class="p-button-success text-xs p-1 m-0 w-8rem"
          v-tooltip="
            'Die Rechtssicherheit von AGB bitte selber überprüfen. Keine Gewähr auf Rechtsicherheit. Durch anklicken, wird der aktuelle Inhalt überschrieben!'
          "
          @click="gaiText('AGB')">
          <img
            :src="require('@/assets/AI_10_percent.png')"
            :class="kiResponseLoading?'pi pi-spin':''"
            class="ml-3 object-contain w-2rem bg-gray-900 border-round-xl shadow-5 border-2" />
          <span>KI Generator</span>
        </Button>
        <small class="text-red-300 text-xs"
          >Die Rechtssicherheit vom AGB bitte selber überprüfen. Keine Gewähr
          auf Rechtsicherheit.</small
        >
        <div class="card">
          <Editor
            v-model="formData.agb"
            editorStyle="height: 640px"
            contentType="html" />
        </div>
      </TabPanel>
      <TabPanel header="Widerrufsbelehrung">
        <Button
          outlined
          icon="pi pi-refresh"
          class="p-button-success text-xs p-1 m-0 w-8rem"
          v-tooltip="
            'Die Rechtssicherheit von Widerrufsbelehrung bitte selber überprüfen. Keine Gewähr auf Rechtsicherheit. Durch anklicken, wird der aktuelle Inhalt überschrieben!'
          "
          @click="gaiText('Widerrufsbelehrung')">
          <img
            :src="require('@/assets/AI_10_percent.png')"
            :class="kiResponseLoading?'pi pi-spin':''"
            class="ml-3 object-contain w-2rem bg-gray-900 border-round-xl shadow-5 border-2" />
          <span>KI Generator</span>
        </Button>
        <small class="text-red-300 text-xs"
          >Die Rechtssicherheit vom Widerrufsbelehrung bitte selber überprüfen. Keine Gewähr
          auf Rechtsicherheit.</small
        >
        <div class="card">
          <Editor v-model="formData.widerruf" editorStyle="height: 640px" />
        </div>
      </TabPanel>
      <TabPanel header="Datenschutzerklaerung">
        <Button
          outlined
          icon="pi pi-refresh"
          class="p-button-success text-xs p-1 m-0 w-8rem"
          v-tooltip="
            'Die Rechtssicherheit von Datenschutzerklärung bitte selber überprüfen. Keine Gewähr auf Rechtsicherheit. Durch anklicken, wird der aktuelle Inhalt überschrieben!'
          "
          @click="gaiText('Datenschutz')">
          <img
            :src="require('@/assets/AI_10_percent.png')"
            :class="kiResponseLoading?'pi pi-spin':''"
            class="ml-3 object-contain w-2rem bg-gray-900 border-round-xl shadow-5 border-2" />
          <span>KI Generator</span>
        </Button>
        <small class="text-red-300 text-xs"
          >Die Rechtssicherheit vom Datenschutzerklärung bitte selber überprüfen. Keine Gewähr
          auf Rechtsicherheit.</small
        >
        <div class="card">
          <Editor v-model="formData.datenschutz" editorStyle="height: 640px" />
        </div>
      </TabPanel>
      <TabPanel header="Versandbedingungen">
        <div class="card">
          <Button
            label="Versandbedingungen generieren"
            outlined
            icon="pi pi-refresh"
            class="p-button-success text-xs p-1 m-0"
            v-tooltip="
              'Die Rechtssicherheit vom Versandbedingungen bitte selber überprüfen. Keine Gewähr auf Rechtsicherheit. Durch anklicken, wird der aktuelle Inhalt überschrieben!'
            "
            @click="generateVersandBedingungen()" />
          <small class="text-red-300 text-xs"
            >Die Rechtssicherheit vom Versandbedingungen bitte selber
            überprüfen. Keine Gewähr auf Rechtsicherheit.</small
          >
          <small class="text-xs"
            >Die Versandbedingungen, werden je nach Aktiv und Öffentlich
            gesetzten Bedingungen ohne Gewähr generiert</small
          >

          <Editor
            v-model="formData.versandBedingungen"
            textValue
            editorStyle="height: 640px" />
        </div>
      </TabPanel>
    </TabView>
  </div>
</template>

<script setup>
import { ref, reactive, onBeforeMount } from "vue";
import { userStore } from "@/store/userStore";
import { useToast } from "primevue/usetoast";
import iAxios from "@/store/axiosInterface";
import { shippingMethods } from "@/utils/shippingUtils.js";
import { SettingsExtended } from "@/service/SettingsExtended";

const extendedShopSettings = reactive({});

const value = ref("");
const store = userStore();
const toast = useToast();

const formData = reactive({
  agb: "",
  widerruf: "",
  datenschutz: "",
  versandBedingungen: "",
  impressum: "",
  showImpressum: true,
});

const generateImpressumHTML = () => {
  const data = store.whoAmIData;
  const rechtsform = data.rechtsForm || "Einzelunternehmer"; // Standard: Einzelunternehmer
  const name = data.cname || `${data.firstName} ${data.surName}`;
  const address = `${data.streetName} ${data.houseNumber}<br>${data.postCode} ${data.city}<br>Deutschland`;
  const contact = `
    Telefon: ${data.phone || "Nicht angegeben"}<br>
    ${data.email ? `E-Mail: ${data.email}<br>` : ""}
    ${data.website ? `Internet: ${data.website}<br>` : ""}
  `;
  let html = `<h1>Impressum</h1><p><br></p>`;

  switch (rechtsform) {
    case "Einzelunternehmer":
    case "Kleinunternehmer":
      html += `
        <p>${name}<br>${address}</p>
        <p><br></p><p><strong>Kontakt:</strong><br>${contact}</p><p><br></p>
        ${
          data.steuerUmsatzId
            ? `<p>Umsatzsteueridentifikationsnummer gemäß § 27a Umsatzsteuergesetz: ${data.steuerUmsatzId}</p>`
            : ""
        }
      `;
      break;

    case "GbR":
      html += `
        <p>${name} GbR<br>${address}</p>
        <p><strong>Vertretung des Unternehmens:</strong><br>Vertreten durch ${
          data.firstName
        } ${data.surName}</p>
        <p><br></p><p><strong>Kontakt:</strong><br>${contact}</p><p><br></p>
        ${
          data.steuerUmsatzId
            ? `<p>Umsatzsteueridentifikationsnummer gemäß § 27a Umsatzsteuergesetz: ${data.steuerUmsatzId}</p>`
            : ""
        }
      `;
      break;

    case "GmbH":
    case "UG (haftungsbeschränkt)":
      html += `
        <p>${name}<br>${address}</p>
        <p><br></p><p><strong>Kontakt:</strong><br>${contact}</p><p><br></p>
        <p><strong>Vertretungsberechtigter Geschäftsführer:</strong><br>${
          data.firstName
        } ${data.surName}</p>
        ${data.hrbNummer ? `<p>Registernummer: ${data.hrbNummer}<br>` : ""}
        ${
          data.registerGericht
            ? `Registergericht: ${data.registerGericht}</p>`
            : ""
        }
        ${
          data.steuerUmsatzId
            ? `<p>Umsatzsteueridentifikationsnummer gemäß § 27a Umsatzsteuergesetz: ${data.steuerUmsatzId}</p>`
            : ""
        }
      `;
      break;

    case "AG":
    case "SE (Europäische Aktiengesellschaft)":
      html += `
        <p>${name}<br>${address}</p>
        <p><br></p><p><strong>Kontakt:</strong><br>${contact}</p><p><br></p>
        <p><strong>Vorstand:</strong><br>${data.firstName} ${data.surName}</p>
        ${data.hrbNummer ? `<p>Registernummer: ${data.hrbNummer}<br>` : ""}
        ${
          data.registerGericht
            ? `Registergericht: ${data.registerGericht}</p>`
            : ""
        }
        ${
          data.steuerUmsatzId
            ? `<p>Umsatzsteueridentifikationsnummer gemäß § 27a Umsatzsteuergesetz: ${data.steuerUmsatzId}</p>`
            : ""
        }
      `;
      break;

    case "PartG":
    case "PartGmbB":
      html += `
        <p>${name}<br>${address}</p>
        <p><br></p><p><strong>Kontakt:</strong><br>${contact}</p><p><br></p>
        <p><strong>Partner:</strong><br>${data.firstName} ${data.surName}</p>
        ${
          data.kammer
            ? `<p><strong>Angaben zur Kammer:</strong><br>${data.kammer}</p>`
            : ""
        }
        ${
          data.steuerUmsatzId
            ? `<p>Umsatzsteueridentifikationsnummer gemäß § 27a Umsatzsteuergesetz: ${data.steuerUmsatzId}</p>`
            : ""
        }
      `;
      break;

    case "Freiberufler":
      html += `
        <p>${name}<br>${address}</p>
        <p><br></p><p><strong>Kontakt:</strong><br>${contact}</p><p><br></p>
        ${
          data.kammer
            ? `<p><strong>Angaben zur Kammer:</strong><br>${data.kammer}</p>`
            : ""
        }
        ${
          data.berufsBezeichnung
            ? `<p><strong>Berufsbezeichnung:</strong><br>${data.berufsBezeichnung}</p>`
            : ""
        }
        ${
          data.steuerUmsatzId
            ? `<p>Umsatzsteueridentifikationsnummer gemäß § 27a Umsatzsteuergesetz: ${data.steuerUmsatzId}</p>`
            : ""
        }
      `;
      break;

    case "e.V.":
    case "eG":
      html += `
        <p>${name}<br>${address}</p>
        <p><br></p><p><strong>Kontakt:</strong><br>${contact}</p><p><br></p>
        ${data.hrbNummer ? `<p>Registernummer: ${data.hrbNummer}<br>` : ""}
        ${
          data.registerGericht
            ? `Registergericht: ${data.registerGericht}</p>`
            : ""
        }
        ${
          data.steuerUmsatzId
            ? `<p>Umsatzsteueridentifikationsnummer gemäß § 27a Umsatzsteuergesetz: ${data.steuerUmsatzId}</p>`
            : ""
        }
      `;
      break;

    default:
      html += `
        <p>${name}<br>${address}</p>
        <p><br></p><p><strong>Kontakt:</strong><br>${contact}</p><p><br></p>
        ${
          data.steuerUmsatzId
            ? `<p>Umsatzsteueridentifikationsnummer gemäß § 27a Umsatzsteuergesetz: ${data.steuerUmsatzId}</p>`
            : ""
        }
      `;
      break;
  }

  // Zusätzliche rechtliche Angaben
  html += `
    <h2>Streitschlichtung</h2>
    <p>
      Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit:
      <a href="https://ec.europa.eu/consumers/odr" target="_blank">https://ec.europa.eu/consumers/odr</a><br>
      Unsere E-Mail-Adresse finden Sie oben im Impressum.
    </p>
    <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>

    <h2>Haftung für Inhalte</h2>
    <p>
      Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich.
      Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen
      oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
      Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis
      einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
    </p>

    <h2>Haftung für Links</h2>
    <p>
      Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte
      auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich.
      Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung
      nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar.
      Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.
    </p>
  `;

  return html;
};

const generateShippingTermsHTML = () => {
  const data = store.whoAmIData;

  // Filtere nur die Versandmethoden, die verfügbar und öffentlich sind
  const availableShippingKinds = getAvailableShippingKinds();

  if (availableShippingKinds.length === 0) {
    return `
      <h1>Versandbedingungen</h1>
      <p>Derzeit stehen keine Versandmethoden zur Verfügung.</p>
    `;
  }

  // Einleitung
  let html = `
    <h1>Versandbedingungen</h1><br>
    <h2>Allgemeines</h2>
    <p>
      Wir versenden Ihre Bestellung über unsere Versandpartner (z. B. DHL, Hermes oder DPD).
      Die Versandkosten und Lieferzeiten variieren je nach Versandart und Bestimmungsort.
      Alle angegebenen Preise verstehen sich inklusive der gesetzlichen Mehrwertsteuer.
    </p>
  `;

  // Versandarten dynamisch generieren
  html += `
    <h2>Versandkosten und Lieferarten</h2>
    <p>Wir bieten folgende Versandarten an:</p>
    <ul>
  `;

  availableShippingKinds.forEach((method) => {
    html += `<li><strong>${method.publicName}</strong><br>`;

    // Basispreis
    if (method.details.basePrice) {
      html += `Kosten: ${method.details.basePrice?.toFixed(2)} €<br>`;
    }

    // Kostenloser Versand
    if (method.details.freeShipping?.enabled) {
      html += `Kostenloser Versand ab ${method.details.freeShipping.minAmountForFree.toFixed(
        2
      )} €<br>`;
    }

    // Gewichtsbasierte Versandkosten
    if (method.type === "weight_based" && method.details.weightRules) {
      html += `
        <p>Die Versandkosten werden basierend auf dem Gewicht berechnet:</p>
        <ul>
      `;
      method.details.weightRules.forEach((rule) => {
        html += `<li>Bis ${rule.maxWeight} kg: ${rule.price.toFixed(2)} €</li>`;
      });
      if (method.details.overWeight) {
        const maxWeight = Math.max(
          ...method.details.weightRules.map((r) => r.maxWeight)
        );
        html += `
          <li>Über ${maxWeight} kg: Basispreis ${method.details.overWeight.basePrice.toFixed(
          2
        )} € + ${method.details.overWeight.additionalPricePerKg.toFixed(
          2
        )} € pro kg Übergewicht</li>
        `;
      }
      html += `</ul>`;
    }

    // Zonenbasierte Versandkosten
    if (method.type === "zone_based" && method.details.zones) {
      html += `
        <p>Die Versandkosten richten sich nach der Entfernung:</p>
        <ul>
      `;
      method.details.zones.forEach((zone) => {
        html += `<li>Bis ${zone.distance} km: ${zone.price.toFixed(2)} €`;
        if (zone.freeShipping?.enabled) {
          html += ` (Kostenlos ab ${zone.freeShipping.minAmountForFree.toFixed(
            2
          )} €)`;
        }
        html += `</li>`;
      });
      html += `</ul>`;
    }

    // Live-Rate Versand
    if (method.type === "live_rate" && method.details.providers) {
      html += `
        Versandkosten werden in Echtzeit basierend auf den Daten der Versanddienstleister berechnet: ${method.details.providers
          .map((provider) => provider.label)
          .join(", ")}<br>
      `;
    }

    // Abholung vor Ort
    if (method.type === "pickup") {
      html += `Kostenlos bei Abholung vor Ort.<br>`;
    }

    html += `</li>`;
  });

  html += `</ul><br>`;

  // Liefergebiet
  html += `
    <h2>Liefergebiet</h2>
    <p>Wir liefern ausschließlich innerhalb Deutschlands. Eine Lieferung in andere Länder ist derzeit nicht möglich.</p>
  `;

  // Lieferzeiten
  html += getShippingTimeHTML();

  // Selbstabholung
  html += `
    <h2>Selbstabholung</h2>`;

  html += generateLocationsHTML();

  // Rücksendungen
  html += `
    <h2>Rücksendungen</h2>
    <p>
      Im Falle eines Widerrufs trägt der Kunde die unmittelbaren Kosten der Rücksendung, sofern nichts anderes vereinbart wurde.
      Weitere Informationen finden Sie in unserer Widerrufsbelehrung.
    </p>
  `;

  // Kontakt
  html += `
    <h2>Kontakt</h2>
    <p>
      Für Fragen oder weitere Informationen zu unseren Versandarten erreichen Sie uns unter:<br>
      E-Mail: ${data.email}<br>
      Telefon: ${data.phone}
    </p>
  `;

  return html;
};

const generateVersandBedingungen = () => {
  formData.versandBedingungen = generateShippingTermsHTML();
};

const generateImpressum = () => {
  formData.impressum = generateImpressumHTML();
  //return html;
};

const saveForm = async () => {
  try {
    //await validationSchema.validate(formData, { abortEarly: false });
    iAxios.patch(
      process.env.VUE_APP_BASE_API_URL + "/company/fullfill",
      formData
    );
    toast.add({
      severity: "success",
      summary: "Erfolg",
      detail: "Rechtstexte erfolgreich gespeichert",
      life: 3000,
    });
  } catch (error) {
    toast.add({
      severity: "error",
      summary: "Eingabefehler",
      detail: error.message,
      life: 3000,
    });
  }
};

async function werteLaden() {
  const companyData = await iAxios.get(
    process.env.VUE_APP_BASE_API_URL + "/company/details"
  );
  if (store.debug)
    console.log(
      "🚀 ~ file: LegalTextsSettingsForm.vue:91 ~ werteLaden ~ companyData:",
      companyData
    );
  Object.assign(formData, companyData.data);
}

const generateLocationsHTML = () => {
  const availableLocations = getAvailableLocations();

  if (availableLocations.length === 0) {
    return "<p>Derzeit sind keine Standorte verfügbar.</p>";
  }

  let html = "<h3>Unsere Standorte:</h3>";
  availableLocations.forEach((location) => {
    html += `
      <h3>${location.label}</h3>
      <p>
        ${location.street} ${location.houseNumber}<br>
        ${location.postalCode} ${location.city}, ${location.country}<br>
        ${location.phone ? `Telefon: ${location.phone}<br>` : ""}
        ${
          location.email
            ? `E-Mail: <a href="mailto:${location.email}">${location.email}</a><br>`
            : ""
        }
        ${
          location.openingHours
            ? `Öffnungszeiten: ${location.openingHours}<br>`
            : ""
        }
        ${location.notice ? `<i>${location.notice}</i><br>` : ""}
      </p><br>
    `;
  });

  return html;
};

const getAvailableShippingKinds = () => {
  const shippingKinds = extendedShopSettings.shippingKinds;
  return shippingKinds.filter((method) => method.available && method.public);
};

const getShippingTimeHTML = () => {
  const shippingKinds = getAvailableShippingKinds();
  let html = "<h2>Lieferzeiten</h2>";

  if (!shippingKinds?.length) {
    return "<p>Die Lieferzeit beträgt bei Standardversand 3–5 Werktage und bei Expressversand 1–2 Werktage. Bei gewichts- oder zonenbasiertem Versand beträgt die Lieferzeit 2–4 Werktage. Die Lieferzeiten können bei unerwarteten Ereignissen, wie Streiks oder schlechtem Wetter, abweichen.</p><br>";
  }

  html += `<p>Die Lieferzeit beträgt `;

  shippingKinds.forEach((shipping, index) => {
    const prefix =
      index === 0
        ? " bei"
        : index === shippingKinds.length - 1
        ? " und bei "
        : ", bei ";
    html += `${prefix} ${shipping.publicName} ${shipping.details.shippingTime}`;
  });

  html += `</p>`;
  return html;
};

const getAvailableLocations = () => {
  return extendedShopSettings.locations
    .filter((location) => location.available && location.public)
    .map((location) => ({
      id: location.id,
      label: location.label,
      notice: location.notice,
      phone: location.phone,
      street: location.street,
      houseNumber: location.houseNumber,
      postalCode: location.postalCode,
      city: location.city,
      country: location.country.name,
      email: location.email,
      openingHours: location.openingHours,
      shippingTime: location.shippingTime,
    }));
};

const extendedShopSettingsLaden = async () => {
  await SettingsExtended.getSettingsExtended().then((response) => {
    if (!response) {
      return;
    }
    Object.assign(extendedShopSettings, response?.extendedShopSettings);
  });
};

const promptParameter = ref(
  `Gib mir die Antwort im folgenden Format als HTML:
- Inhalt mit Zeilenumbrüchen als <br> statt \n
- kein Doctype <html><head><body>
- Native HTML mit falls nötig <h1><h2><h3><ul><li><ol> usw.
- Achte darauf, dass das HTML valide ist.
- Entferne unnötige Zeichen wie Markdown-Backticks oder Steuerzeichen.
- Nutze <br> für Zeilenumbrüche anstelle von '\n'.
- Liefere den HTML-String ohne weitere Umrahmung.
- Falls nötig verwende folgende Firmendaten: ${getNativeCompanyData()}
`
);

function getNativeCompanyData() {
  let companyData = {};
  companyData.BankKontoInhaber = store.whoAmIData.kontoInhaber;
  companyData.bankName = store.whoAmIData.bankName;
  companyData.bic = store.whoAmIData.bic;
  companyData.city = store.whoAmIData.city;
  companyData.country = store.whoAmIData.country;
  companyData.email = store.whoAmIData.email;
  companyData.owner =
    store.whoAmIData.rechtsForm +
    "Geschäftsführer bzw. Inhaber " +
    store.whoAmIData.userName;
  companyData.firmenName = store.whoAmIData.cName;
  companyData.streetName = store.whoAmIData.streetName;
  companyData.houseNumber = store.whoAmIData.houseNumber;
  companyData.PLZ = store.whoAmIData.postCode;
  companyData.registerGericht = store.whoAmIData.registerGericht;
  companyData.replyEmail = store.whoAmIData.replyEmail;
  companyData.website = store.whoAmIData.website;
  companyData.steuerNrArt = store.whoAmIData.steuerNrArt;
  companyData.steuerId = store.whoAmIData.steuerUmsatzId;

  return JSON.stringify(companyData);
}

const kiResponseLoading = ref(false);
const gaiText = (kind) => {
  console.log("promptParameter", promptParameter);
  kiResponseLoading.value = true;
  let url = "/company/gprompt";
  const body = new FormData();
  body.append(
    "prompt",
    promptParameter.value +
      "Generiere für einen OnlineShop für Waren/Digitale Medien/Dienstleistungen, nach aktueller Deutscher Gesetzteslage ein/e" +
      kind
  );

  let parsedRes = null;
  iAxios
    .post(url, body, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      const cleanedData = response.data.response
        .replace(/```html|```/g, "") // Entfernt Markdown-Backticks
        .replace(/[\r\n\t]/g, "") // Entfernt Steuerzeichen
        .trim();
      console.log("🚀 ~ cleanedData:", cleanedData);
      try {
        parsedRes = cleanedData;
      } catch (e) {
        console.error(e);
        gaiText();
      }
      switch (kind) {
        case "AGB":
          console.log("AGB!!!");
          formData.agb = parsedRes;
          break;
        case "Datenschutz":
        formData.datenschutz = parsedRes;
          break;
        case "Widerrufsbelehrung":
        formData.widerruf = parsedRes;
          break;
      }
      console.log(parsedRes);
      kiResponseLoading.value = false;
    });
};

onBeforeMount(() => {
  werteLaden();
  extendedShopSettingsLaden();
});
</script>
