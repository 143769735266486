<template>
  <div>
    <TabView>
      <TabPanel
        header="Firmen Profil"
        :pt="{ headerTitle: { class: 'text-sm ' } }">
        <template #header>
          <span class="pl-1 pi pi-cog text-sm " />
        </template>
        <CompanySettingsForm />
      </TabPanel>
      <TabPanel
        header="Standorte"
        :pt="{ headerTitle: { class: 'text-sm ' } }">
        <template #header>
          <span class="pl-1 pi pi-cog text-sm " />
        </template>
        <Locations />
      </TabPanel>
      <TabPanel
        header="Add-ins"
        v-if="store.debug"
        :pt="{ headerTitle: { class: 'text-sm ' } }">
        <template #header>
          <span class="pl-1 pi pi-microsoft text-sm " />
        </template>
      </TabPanel>
      <TabPanel
        v-if="store.debug"
        header="Tarif"
        :pt="{ headerTitle: { class: 'text-sm ' } }">
        <template #header>
          <span class="pl-1 pi pi-shopping-bag text-sm " />
        </template>
        <Tarife/>
      </TabPanel>
      <TabPanel
        v-if="store.debug"
        header="Zahlungsübersicht"
        :pt="{ headerTitle: { class: 'text-sm ' } }">
        <template #header>
          <span class="pl-1 pi pi-money-bill text-sm " />
        </template>
        <MollieZahlungen/>
      </TabPanel>
    </TabView>
  </div>
</template>

<script setup>
import CompanySettingsForm from "@/views/Account/CompanySettingsForm.vue";
import Locations from "@/views/Account/Locations.vue";
import MollieZahlungen from "@/views/Account/MollieZahlungen.vue";
import Tarife from "@/views/Account/Tarife.vue";
import { userStore } from "@/store/userStore";
const store = userStore();
</script>
