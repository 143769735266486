<template>
  <div class="container">
    <div class="banner">
      <h2>Matt's Mats</h2>
    </div>
    <div class="content">
      <h2>Details submitted</h2>
      <p>That's everything we need for now</p>
    </div>
    <div class="info-callout">
      <p> This is a sample app for Stripe-hosted Connect onboarding. <a href="https://docs.stripe.com/connect/onboarding/quickstart?connect-onboarding-surface=hosted" target="_blank" rel="noopener noreferrer">View docs</a></p>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';

const accountCreatePending = ref(false);
const accountLinkCreatePending = ref(false);
const error = ref(false);
const connectedAccountId = ref(null);
</script>