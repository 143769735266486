<template>
  <div class="p-card w-full p-3 mt-3 shadow-md rounded-2xl border border-gray-200">
    <!-- Card Header -->
    <div class="p-card-title">
        <div class="flex overflow-hidden">
          <div class="flex-grow-1 flex align-items-center font-bold text-xl">{{ title }}</div>
          <div class="flex-none flex align-items-center justify-content-center" @click="toggleCollapse" :class="['pi', isExpanded ? 'pi-chevron-up' : 'pi-chevron-down']"></div>
        </div>
    </div>
    <!-- Card Content -->
    <div
      v-show="isExpanded"
      class="pt-3"
      style="transition: max-height 0.3s ease; overflow: hidden;">
      <slot />
    </div>
  </div>
</template>
<script setup>
import { onMounted, ref } from 'vue';

// Props für die Komponente
const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  expanded: {
    type: Boolean,
    default: false,
    required: false,
  },
});

const isExpanded = ref(false);

onMounted(() => {
  isExpanded.value = props.expanded;
})

const toggleCollapse = () => {
  isExpanded.value = !isExpanded.value;
};
</script>
<style>
.card {
  transition: box-shadow 0.3s ease;
}
.card:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}
</style>
