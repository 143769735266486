<template>
    <template v-if="!store.rules.activatedModules.invoice">
    <UpgradeLicence />
  </template>
  <template v-else>
    <SpeedDial
      :model="MenuItems"
      :radius="80"
      type="semi-circle"
      direction="left"
      :style="{ zIndex: '999', top: 'calc(50% - 2rem)', right: 0 }"
      :tooltipOptions="{ position: 'left' }"
      showIcon="pi pi-bars"
      hideIcon="pi pi-times" />
    <!--  <div class="response2pdf">
    <transition name="transition-anim">
      <section class="pdf-preview" v-if="pdfFile">
        <button @click.self="closePreview()">&times;</button>
        <iframe :src="pdfFile" width="100%" height="100%" />
      </section>
    </transition>
  </div> -->
    <ConfirmDialog group="downloadInvoice">
      <template #container="{ message, acceptCallback, rejectCallback }">
        <div
          class="flex flex-column align-items-center p-5 surface-overlay border-round">
          <div
            class="border-circle bg-blue-500 inline-flex justify-content-center align-items-center h-6rem w-6rem -mt-8">
            <i class="pi pi-info text-5xl text-white"></i>
          </div>
          <span class="font-bold text-2xl block mb-2 mt-4">{{
            message.header
          }}</span>
          <div class="flex justify-content-between w-full">
            Anzahl der Rechnungen:
            <strong>{{ message.numberOfDocuments }}</strong>
          </div>
          <div class="flex justify-content-between w-full">
            Ungefähre Verarbeitungszeit:
            <strong>{{ message.downloadDuration }}</strong>
          </div>
          <div class="flex justify-content-between w-full">
            Dateigröße: <strong>{{ message.downloadSize }}</strong>
          </div>
          <div class="flex align-items-center gap-2 mt-4">
            <Button
              label="Ja"
              outlined
              severity="info"
              @click="acceptCallback"
              class="w-8rem"></Button>
            <Button
              label="Nein"
              severity="danger"
              outlined
              @click="rejectCallback"
              class="w-8rem"></Button>
          </div>
        </div>
      </template>
    </ConfirmDialog>

    <div class="card p-fluid">
      <Loading v-show="loading" />
      <MultiSelect
        v-show="showAdvanced"
        :modelValue="selectedColumns"
        :options="customerUid ? columnsCustomer : columns"
        optionLabel="header"
        @update:modelValue="onToggle"
        display="chip"
        placeholder="Spalten Ansicht wählen"
        :class="dtClass"
        class="w-full" />
      <DataTable
        v-model:filters="filters"
        :value="invoices"
        v-model:selection="selectedInvoices"
        editMode="row"
        :class="['dtClass', 'custom-datatable']"
        scrollable
        scrollHeight="85vh"
        :filterDisplay="showAdvanced ? 'row' : ''"
        tableStyle="min-width: 10%"
        resizableColumns
        columnResizeMode="fit"
        stripedRows
        selectionMode="row"
        dataKey="invoiceDocumentId"
        sortField="created_us"
        :sortOrder="-1"
        class="p-datatable-sm"
        @row-dblclick="openReadOnlyInvoice"
        ref="dataTableRef">
        <template #empty>
          <div
            v-if="loading"
            class="flex justify-content-center align-items-center">
            <span
              class="text-center pi pi-spin pi-spinner text-4xl font-bold text-primary"></span>
            <span class="text-4xl px-4"> Daten werden geladen...</span>
            <span
              class="text-center pi pi-spin pi-spinner text-4xl font-bold text-primary"></span>
          </div>
          <div v-else>
            <span class="text-2xl font-bold text-primary"
              >Keine Einträge gefunden</span
            >
          </div>
        </template>
        <Column
          :headerStyle="
            showAdvanced ? 'width: 2rem; text-align: center' : 'display: none'
          "
          :bodyStyle="
            showAdvanced
              ? 'text-align: center; overflow: visible'
              : 'display: none'
          ">
          <template #header>
            <div>
              <div>
                <Button
                  v-show="showAdvanced"
                  type="button"
                  outlined
                  id="isNew"
                  class="p-1"
                  :class="dtClass"
                  icon="pi pi-plus"
                  :icon="dtClass"
                  @click="handleNewRow" />
              </div>
            </div>
          </template>
          <template #body="{ data }">
            <!--             <Button
              v-show="showAdvanced"
              type="button"
              :pt="{ icon: { class: 'text-xl' } }"
              id="editCustomer"
              text
              :class="dtClass"
              icon="pi pi-file-edit"
              rounded
              @click="openReadOnlyInvoice({ data })" /> -->
            <Button
              type="button"
              id="invoiceAction"
              icon="pi pi-bars text-xs"
              class="p-0 m-0 h-2rem w-2rem"
              outlined
              aria-haspopup="true"
              :aria-controls="'overlay_menu'"
              rounded
              @click="popUpToggle($event, data)"
              v-tooltip="'Ansehen/Bearbeiten'" />
            <Menu
              ref="menuInvoiceRef"
              id="overlay_menu"
              :model="popUpMenuItems"
              :popup="true" />
          </template>
        </Column>
        <Column
          selectionMode="multiple"
          :headerStyle="
            showAdvanced ? 'width: 0.1%; text-align: center' : 'display: none'
          "
          :bodyStyle="
            showAdvanced
              ? 'text-align: left; overflow: visible'
              : 'display: none'
          ">
          <template #header>
            <div>
              <div>
                <Button
                  v-show="showAdvanced"
                  type="button"
                  outlined
                  id="isNew"
                  class="p-1"
                  :class="dtClass"
                  icon="pi pi-download"
                  :icon="dtClass"
                  @click="batchDownload" />
              </div>
            </div>
          </template>
          <template #body="{ data }">
            <Checkbox
              v-show="showAdvanced"
              v-model="selectedInvoices"
              :value="data" />
          </template>
        </Column>

        <Column
          field="docName"
          header="Rechnungsnummer"
          :footer="showAdvanced ? 'Rechnungsnummer' : ''"
          key="docName"
          :showFilterMenu="false"
          :filterMenuStyle="{ width: '15%' }"
          style="min-width: 15%"
          sortable>
          <template #body="{ data }">
            <span
              v-tooltip="'Mahnstufe ' + data?.['mahnstufe']?.level"
              :class="
                data?.['mahnstufe']
                  ? getClassForLevel(data?.['mahnstufe'].level)
                  : ''
              ">
              <span
                class="pi pi-exclamation-triangle pr-1"
                :class="
                  data?.['mahnstufe']
                    ? getClassForLevel(data?.['mahnstufe'].level)
                    : 'hidden'
                "
                v-badge="data?.['mahnstufe']?.level"></span>
              <template v-if="data.eventAction != 'INVOICE_DRAFT'">
                {{ data.docName }}
              </template>
              <template v-else>
                <span class="font-light">Entwurf</span>
              </template>
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              v-model="filterModel.value"
              v-tooltip.top.focus="'Zum Anwenden Enter drücken'"
              type="text"
              @keydown.enter="filterCallback()"
              @input="filterModel.value || filterCallback()"
              :class="dtClass"
              class="p-column-filter" />
          </template>
        </Column>
        <Column
          v-for="col of selectedColumns"
          :key="col.field"
          :field="col.field"
          :header="col.header"
          :footer="showAdvanced ? col.header : ''"
          :showFilterMenu="false"
          sortable
          :sortField="
            isSortable(col.field) ? col.field.replace('_de', '_us') : col.field
          ">
          <template #filter="{ filterModel, filterCallback }">
            <div
              v-if="col.field === 'created_de' || col.field === 'updated_de'">
              <Calendar
                v-model="filterModel.value"
                selectionMode="range"
                hideOnRangeSelection
                placeholder="Auswahl"
                mask="99.99.9999"
                showButtonBar
                showIcon
                :inputClass="dtClass"
                :class="dtClass"
                iconDisplay="input"
                @date-select="filterCallback()" />
            </div>
            <div v-else>
              <InputText
                v-model="filterModel.value"
                v-tooltip.top.focus="'Zum Anwenden Enter drücken'"
                type="text"
                @keydown.enter="filterCallback()"
                @input="filterModel.value || filterCallback()"
                :class="dtClass"
                class="p-column-filter" />
            </div>
          </template>
          <template #body="{ data, field }">
            <span
              :class="
                data?.['mahnstufe']
                  ? getClassForLevel(data?.['mahnstufe'].level)
                  : ''
              ">
              {{ data[field] }}
            </span>
          </template>
          <template #loading>
            <Skeleton width="60%" height="2rem" />
          </template>
        </Column>
        <Column
          field="documentStatus"
          header="Status"
          :footer="showAdvanced ? 'Status' : ''"
          key="documentStatus"
          :showFilterMenu="false"
          :filterMenuStyle="{ width: '15%' }"
          style="min-width: 15%"
          sortable>
          <template #body="{ data }">
            <Tag
              :severity="getRechnungSeverity(data.documentStatus)"
              :icon="getRechnungIcon(data.documentStatus)"
              :value="getActionLabel(data.documentStatus)"
              :class="dtClass"></Tag>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <Dropdown
              v-model="filterModel.value"
              @change="filterCallback()"
              :options="statuses"
              optionLabel="label"
              optionValue="value"
              placeholder="Auswählen"
              class="p-column-filter"
              :inputClass="dtClass"
              :class="dtClass"
              style="min-width: 10%"
              :showClear="true">
              <template #option="slotProps">
                <Tag
                  :value="slotProps.option.label"
                  :class="slotProps.option.label"
                  :severity="getRechnungSeverity(slotProps.option.value)" />
              </template>
            </Dropdown>
          </template>
        </Column>

        <!--       <Column headerStyle="width: 2rem; text-align: center" header="" footer=""
        bodyStyle="text-align: center; overflow: visible">
        <template #body="{ data }">
          <Button type="button" :userData="data" outlined text size="small" icon="pi pi-book" label="Storno"
            v-tooltip="'Korrektur Rechnung anlegen'" severity="danger" rounded @click="openRechnung(data)" />
        </template>
      </Column> -->
      </DataTable>
      <iframe id="pdfFrame" style="display: none"></iframe>
    </div>
  </template>
</template>

<script setup>
import {
  ref,
  onMounted,
  onBeforeMount,
  defineAsyncComponent,
  markRaw,
  reactive,
  computed,
  onUnmounted,
} from "vue";
import { FilterMatchMode, FilterService } from "primevue/api";
import { InvoicesService } from "@/service/InvoicesService";
import { formatCurrency, formatDatum } from "@/utils/formatUtils";
import Loading from "@/components/Loading.vue";
import { useRouter } from "vue-router";
import { useDialog } from "primevue/usedialog";
import { useToast } from "primevue/usetoast";
import { saveAndGetPDF,printPDF } from "@/utils/invoiceUtils";
import download from "downloadjs";
import iAxios from "@/store/axiosInterface";
import { useConfirm } from "primevue/useconfirm";
import { userStore } from "@/store/userStore";
import { storeToRefs } from "pinia";
const MahnHistoryComponent = defineAsyncComponent(() =>
  import("@/components/MahnHistoryComponent.vue")
);
const InvoicesEditComponent = defineAsyncComponent(() =>
  import("@/views/InvoicesEditComponent.vue")
);
const DefaultFooter = defineAsyncComponent(() =>
  import("@/views/InvoicesEditFooter.vue")
);
const DefaultHeader = defineAsyncComponent(() =>
  import("@/views/InvoicesEditHeader.vue")
);

const OrdersEditComponent = defineAsyncComponent(() =>
  import("@/views/OrdersEditComponent.vue")
);
const OrderEditFooter = defineAsyncComponent(() =>
  import("@/views/OrdersEditFooter.vue")
);
const OrderEditHeader = defineAsyncComponent(() =>
  import("@/views/OrdersEditHeader.vue")
);

const OffersEditComponent = defineAsyncComponent(() =>
  import("@/views/OffersEditComponent.vue")
);
const OffersEditFooter = defineAsyncComponent(() =>
  import("@/views/OffersEditFooter.vue")
);
const OffersEditHeader = defineAsyncComponent(() =>
  import("@/views/OffersEditHeader.vue")
);

const store = userStore();
const router = useRouter();
const confirm = useConfirm();
const toast = useToast();
const { dtClass, customerUid, showAdvanced } = defineProps({
  dtClass: {
    type: String,
    required: false,
    default: "",
  },
  customerUid: {
    type: String,
    required: false,
    default: "",
  },
  showAdvanced: {
    type: Boolean,
    required: false,
    default: true,
  },
});
FilterService.register("dateFilter", dateFilter);
const selectedInvoices = ref([]);

const columns = ref([
  { field: "kundenNummer", header: "Kundennr" },
  { field: "name1", header: "Name" },
  { field: "postCode", header: "PLZ" },
  { field: "documentValueBrutto", header: "Rechnungsbetrag" },
  { field: "documentValueNetto", header: "Rechnungsbetrag Netto" },
  { field: "created_de", header: "Erstelldatum" },
  { field: "updated_de", header: "Zuletzt Geändert" },
  { field: "createdUser", header: "Erstellt Benutzer" },
  { field: "updatedUser", header: "Geändert Benutzer" },
  { field: "bezahlArten", header: "Bezahlart/en" },
]);
const startColumns = ref([
  { field: "name1", header: "Name" },
  { field: "documentValueBrutto", header: "Rechnungsbetrag" },
  { field: "created_de", header: "Erstelldatum" },
]);
const columnsCustomer = ref([
  { field: "documentValueBrutto", header: "Rechnungsbetrag" },
  { field: "documentValueNetto", header: "Rechnungsbetrag Netto" },
  { field: "created_de", header: "Erstelldatum" },
  { field: "updated_de", header: "Zuletzt Geändert" },
  { field: "createdUser", header: "Erstellt Benutzer" },
  { field: "updatedUser", header: "Geändert Benutzer" },
  { field: "bezahlArten", header: "Bezahlart/en" },
]);
const startColumnsCustomer = ref([
  { field: "created_de", header: "Erstelldatum" },
  { field: "documentValueBrutto", header: "Rechnungsbetrag" },
]);

const selectedColumns = customerUid
  ? ref(startColumnsCustomer.value)
  : ref(startColumns.value);
const onToggle = (val) => {
  const cols = customerUid ? columnsCustomer : columns;
  const valFields = val.map((v) => v.field);
  cols.value.forEach((col) => {
    if (
      valFields.includes(col.field) &&
      !selectedColumns.value.some(
        (selectedCol) => selectedCol.field === col.field
      )
    ) {
      selectedColumns.value.push(col);
    } else if (!valFields.includes(col.field)) {
      selectedColumns.value = selectedColumns.value.filter(
        (selectedCol) => selectedCol.field !== col.field
      );
    }
  });
};

function dateFilter(value, filter) {
  // Konvertieren ob das Datum in das Format 'yyyy-MM-dd'
  const [day, month, yearTime] = value.split(".");
  const [year, time] = yearTime.split(" ");
  const date = new Date(`${year}-${month}-${day} ${time}`);
  const formattedDate =
    date.getFullYear() +
    "-" +
    (date.getMonth() + 1).toString().padStart(2, "0") +
    "-" +
    date.getDate().toString().padStart(2, "0");

  // Überprüfen, ob der Filterwert ein Bereich ist
  if (Array.isArray(filter)) {
    const [start, end] = filter.map((dateStr) => {
      const date = new Date(dateStr);
      return (
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1).toString().padStart(2, "0") +
        "-" +
        date.getDate().toString().padStart(2, "0")
      );
    });
    return formattedDate >= start && formattedDate <= end;
  }

  // Überprüfen, ob das formatierte Datum den Filterwert enthält
  return formattedDate.includes(filter);
}

const menuInvoiceRef = ref();

const popUpToggle = (event, data) => {
  rowData = data;
  popUpLabel.label = rowData.docName+' ('+rowData.name1+')';
  menuInvoiceRef.value.toggle(event);
};

const popUpMenuItems = computed(() => [
  {
    label: popUpLabel.label,
    items: [
      {
        label: "Ansehen/Bearbeiten",
        icon: "pi pi-file-edit",
        command: () => {
          console.log(rowData);
          if (rowData) {
            openReadOnlyInvoice({ data: rowData });
          }
        },
      },
      {
        label: "Mahnhistorie",
        icon: "pi pi-list",
        disabled: !rowData?.mahnstufe,
        visible: rowData?.eventAction!='INVOICE_DRAFT',
        command: () => {
          console.log(rowData);
          if (rowData) {
            openMahnHistory({ data: rowData });
          }
        },
      },
    ],
  },
  {
    label: rowData?.docContent.faelligkeitsDatum ? 'Fälligkeit:'+formatDatum(rowData?.docContent.faelligkeitsDatum)+' ('+ getFalligkeitInDays(rowData?.docContent.faelligkeitsDatum)+')' : "",
    class: "text-red-300 font-light",
    items:[],
  },
  {
    label: getMahnLabel(rowData?.mahnstufe?.level)+'...',
    visible: rowData?.eventAction!='INVOICE_DRAFT',
    class: "text-gray-500 font-bold",
    items: [
    {
        label: '...'+getMahnLabelText(rowData?.mahnstufe?.level),
        class: "bg-primary-50 ont-semibold p-1 mb-1",
        icon: "pi pi-send",
        command: () => {
          console.log(rowData);
          if (rowData) {
            sendReminder(rowData ,rowData?.mahnstufe?.level || 0);
          }
        },
      },
      {
        label: '...Drucken',
        class: "bg-primary-50 ont-semibold p-1 mb-1",
        icon: "pi pi-print",
        command: () => {
          console.log(rowData);
          if (rowData) {
            sendReminder(rowData ,rowData?.mahnstufe?.level || 0,true);
          }
        },
      },
    ],
  },
  
  {
    label: getNextMahnLabel(rowData?.mahnstufe?.level)+'...',
    visible: rowData?.eventAction!='INVOICE_DRAFT' && rowData?.mahnstufe?.level < 3 && rowData?.mahnstufe?.level >= 0,
    class: "text-gray-500 font-bold",
    items: [

  {
        label: '...senden',
        visible: rowData?.eventAction!='INVOICE_DRAFT' && rowData?.mahnstufe?.level < 3 && rowData?.mahnstufe?.level >= 0,
        icon: "pi pi-send",
        command: () => {
          console.log(rowData);
          if (rowData) {
            sendReminder( rowData,(rowData?.mahnstufe?.level)+1 || 1);
          }
        },
      },
      {
        label: '...Drucken',
        class: "bg-primary-50 ont-semibold p-1 mb-1",
        visible: rowData?.eventAction!='INVOICE_DRAFT' && rowData?.mahnstufe?.level < 3 && rowData?.mahnstufe?.level >= 0,
        icon: "pi pi-print",
        command: () => {
          console.log(rowData);
          if (rowData) {
            sendReminder(rowData ,(rowData?.mahnstufe?.level)+1 || 1,true);
          }
        },
      },
    ],
  },
]);


//"company/send-payment-reminder-man",
const sendReminder=(data,lvl,downloadPrint) => {
  if (lvl == null) lvl = 0;
  if (lvl > 3) lvl=3;
  console.log("sendReminder");
  const url="/company/send-payment-reminder-man";
  const remData={
    invoiceDocumentId: data.invoiceDocumentId,
    reminderLevel: lvl,
    downloadPdf: Boolean(downloadPrint),
    //emailReceiver: data.emailReceiver,
  };

  console.log("🚀 ~ file: InvoicesView.vue:591 ~ remData:", remData)


  iAxios
    .post(url, remData, {
            responseType: 'blob',})
    .then(async (response) => {
      toast.add({
        severity: "success",
        summary: getMahnLabel(lvl),
        detail: getMahnLabel(lvl)+downloadPrint?" Drucken":" wurde gesendet.",
        life: 3000,
      });
      if(downloadPrint){
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(blob);
            download(blob, "Mahnung_" + data.receiver.name1 + "_" + formatDatum(data.docContent.documentDatum) + ".pdf", "application/pdf"); 
           await printPDF(fileURL);
      }
    })
    .catch((error) => {
      toast.add({
        severity: "error",
        summary: getMahnLabel(lvl),
        detail: getMahnLabel(lvl)+downloadPrint?" Drucken":" konnte nicht gesendet werden.",
        life: 3000,
      });
      console.log(error);
    });
}

const getFalligkeitInDays = (faelligkeitsDatum) => {
  const faelligkeit = new Date(faelligkeitsDatum); // Konvertiere das Fälligkeitsdatum in ein Date-Objekt
  const today = new Date(); // Heutiges Datum
  today.setHours(0, 0, 0, 0); // Setze die Uhrzeit von "today" auf 00:00:00
  const diffTime = faelligkeit - today; // Zeitdifferenz in Millisekunden
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); // Umrechnung in Tage

  // Rückgabe der Nachricht je nach Fälligkeit
  if (diffDays > 0) {
    return diffDays === 1
      ? `In ${diffDays} Tag` // Für 1 Tag
      : `In ${diffDays} Tagen`; // Für mehr als 1 Tag
  } else if (diffDays === 0) {
    return "Seit heute fällig"; // Für heute
  } else {
    const overdueDays = Math.abs(diffDays); // Betrag der überfälligen Tage
    return overdueDays === 1
      ? `Seit ${overdueDays} Tag` // Für 1 Tag überfällig
      : `Seit ${overdueDays} Tagen`; // Für mehr als 1 Tag überfällig
  }
};

/* const getMahnLabelText = (lvl)=>{
  if (lvl == null) return "Zahlungserinnerung senden";
  else return getMahnLabel(lvl) + " erneut senden";
} */
const getMahnLabelText = (lvl)=>{
  if (lvl == null) return " senden";
  else return " erneut senden";
}

const getMahnLabel = (lvl) => {
  switch (lvl) {
    case 0:
      return "Zahlungserinnerung";
    case 1:
      return "1. Mahnung";
    case 2:
      return "2. Mahnung";
    case 3:
      return "3. Mahnung";
    default:
      return "Unbekannt";
  }
};

const getNextMahnLabel = (lvl) => {
  switch (lvl) {
    case null:
      return "Zahlungserinnerung";
    case 0:
      return "1. Mahnung";
    case 1:
      return "2. Mahnung";
    case 2:
      return "3. Mahnung";
    default:
      return "3. Mahnung";
  }
};

const levelClassMap = {
  0: "text-purple-300 font-semibold",
  1: "text-yellow-300 font-semibold",
  2: "text-orange-300 font-semibold",
  3: "text-red-300 font-semibold", // Standard für Level 2 und höher
};

const getClassForLevel = (level) => levelClassMap[level] || "";

const filters = ref({
  docName: { value: null, matchMode: FilterMatchMode.CONTAINS },
  documentStatus: { value: null, matchMode: FilterMatchMode.CONTAINS },
  name1: { value: null, matchMode: FilterMatchMode.CONTAINS },
  postCode: { value: null, matchMode: FilterMatchMode.CONTAINS },
  documentValueBrutto: { value: null, matchMode: FilterMatchMode.CONTAINS },
  documentValueNetto: { value: null, matchMode: FilterMatchMode.CONTAINS },
  createdUser: { value: null, matchMode: FilterMatchMode.CONTAINS },
  created_de: {
    value: null,
    matchMode: "dateFilter",
    filterFunction: dateFilter,
    dateRange: [null, null],
  },
  updatedUser: { value: null, matchMode: FilterMatchMode.CONTAINS },
  updated_de: {
    value: null,
    matchMode: "dateFilter",
    filterFunction: dateFilter,
    dateRange: [null, null],
  },
  kundenNummer: { value: null, matchMode: FilterMatchMode.CONTAINS },
  documentStatus: { value: null, matchMode: FilterMatchMode.CONTAINS },
  bezahlArten: { value: null, matchMode: FilterMatchMode.CONTAINS },
});

/* const filtersCustomer = ref({
  docName: { value: null, matchMode: FilterMatchMode.CONTAINS },
  documentStatus: { value: null, matchMode: FilterMatchMode.CONTAINS },
  documentValueBrutto: { value: null, matchMode: FilterMatchMode.CONTAINS },
  documentValueNetto: { value: null, matchMode: FilterMatchMode.CONTAINS },
  createdUser: { value: null, matchMode: FilterMatchMode.CONTAINS },
  created_de: {
    value: null,
    matchMode: "dateFilter",
    filterFunction: dateFilter,
    dateRange: [null, null],
  },
  updatedUser: { value: null, matchMode: FilterMatchMode.CONTAINS },
  updated_de: {
    value: null,
    matchMode: "dateFilter",
    filterFunction: dateFilter,
    dateRange: [null, null],
  },
  documentStatus: { value: null, matchMode: FilterMatchMode.CONTAINS },
  bezahlArten: { value: null, matchMode: FilterMatchMode.CONTAINS },
}); */

function isSortable(field) {
  return field === "updated_de" || field === "created_de";
}
const isMobile = ref(false);
const loading = ref(true);

const invoices = ref([]);
const dialog = useDialog();
let rowData = null;
const dataTableRef = ref();
const popUpLabel = reactive({ label: "" });

const statuses = reactive([
  { label: "Entwurf", value: "INVOICE_DRAFT_OPEN" },
  { label: "Storniert", value: "INVOICE_CANCELED" },
  { label: "Gutschrift", value: "INVOICE_CORRECTION" },
  { label: "Offen", value: "INVOICE_OPEN" },
  { label: "Bezahlt", value: "INVOICE_PAID" },
  { label: "Teilzahlung", value: "INVOICE_PARTIAL" },
]);

/* const menu = ref();
const popUpitems = computed(() => [
  {
    label: popUpLabel.label,
    items: [
      {
        label: "Offen",
        icon: 'pi pi-money-bill',
        command: () => {
          if (rowData && rowData.documentStatus != 'INVOICE_OPEN') {
            setDocumentStatus(rowData.invoiceDocumentId, 'INVOICE_OPEN');
            rowData.documentStatus = 'INVOICE_OPEN';
          }
        },
      },
      {
        label: "Bezahlt",
        icon: 'pi pi-dollar',
        command: () => {
          if (rowData && rowData.documentStatus != 'INVOICE_PAID') {
            setDocumentStatus(rowData.invoiceDocumentId, 'INVOICE_PAID');
            rowData.documentStatus = 'INVOICE_PAID';
          }
        },
      }
    ],
  },
]); */

const pdfFile = ref();
const handleNewRow = async (event) => {
  const dialogRef = dialog.open(InvoicesEditComponent, {
    props: {
      position: "top",
      header: "Rechnung erstellen",
      style: {
        width: "",
      },
      maximizable: true,
      breakpoints: {
        // breakpoints so lassen! bei fragen an abdullah
        "2135px": "48vw",
        "1800px": "50vw",
        "1600px": "60vw",
        "1460px": "70vw",
        "1336px": "75vw",
        "1000px": "90vw",
        "831px": "100vw",
      },
      modal: true,
      closable: false,
    },
    data: {
      invoice: {
        invoiceDocumentId: null,
        readOnly: false,
        storno: false,
        isNew: true,
      },
      rechnungsDaten: event.data,
    },
    templates: {
      footer: markRaw(DefaultFooter),
      header: markRaw(DefaultHeader),
    },
    onClose: async (options) => {
      if (options.data) {
        const buttonType = options.data.buttonType;
        if (buttonType == "Save") {
          let responseFile = await saveAndGetPDF(dialogRef);
          pdfFile.value = dialogRef.data.pdfFile;
        } else if (buttonType == "Generate") {
          let response = speichern(dialogRef, true);
        } else if (buttonType == "Delete") {
          //von virtalInvoices löschen mit invoiceDocumentId
          refreshReRenderTable();
          /*           const index = virtualInvoices.value.findIndex((invoice) => invoice.invoiceDocumentId == options.data.invoiceDocumentId);
                    if (index > -1) {
                      virtualInvoices.value.splice(index, 1);
                    }
                    //von invoices löschen mit invoiceDocumentId
                    const index2 = invoices.value.findIndex((invoice) => invoice.invoiceDocumentId == options.data.invoiceDocumentId);
                    if (index2 > -1) {
                      invoices.value.splice(index2, 1);
                    } */
        } else if (buttonType == "GoToInvoice") {
          openInvoice(options.data.invoiceDocumentId);
        } else refreshReRenderTable();
      }
    },
  });
};

const refreshReRenderTable = async () => {
  await werteLaden();
};

const openMahnHistory = async (event) => {
  const dialogRef = dialog.open(MahnHistoryComponent, {
    props: {
      header: "Mahnhistorie: " + event.data.name1 + ", " + event.data.docName,
      style: {
        width: "",
      },
      breakpoints: {
        // breakpoints so lassen! bei fragen an abdullah
        "2135px": "48vw",
        "1800px": "50vw",
        "1600px": "60vw",
        "1460px": "70vw",
        "1336px": "75vw",
        "1000px": "90vw",
        "831px": "100vw",
      },
      modal: true,
      closable: true,
    },
    data: event.data.mahnstufe,
  });
};

const openReadOnlyInvoice = async (event, proceedType) => {
  console.log("🚀 ~ file: InvoicesView.vue:741 ~ event:", event);

  const isCloned = proceedType == "Clone";
  const isDraft = event.data.eventAction == "INVOICE_DRAFT" || isCloned;
  const isCancelledDone = event.data.relationFrom.invoiceCancelledFromId;
  const isCancelled = proceedType == "Cancellation" || isCancelledDone;
  const isDuplicated = isCloned || isCancelled;
  console.log(
    "🚀 ~ file: InvoicesView.vue:392 ~ openReadOnlyInvoice ~ isDraft:",
    isDraft
  );

  //event.data.responsed.invoiceDocumentId = event.data.invoiceDocumentId || event.data.invoiceDocumentId;
  // event.data.responsed.documentName = event.data.docName;
  const dialogRef = dialog.open(InvoicesEditComponent, {
    props: {
      position: "top",
      header: isCancelled ? "Rechnungskorrektur " : "Rechnung",
      subHeader: isDraft ? "(Entwurf)" : event.data.docName,
      style: {
        width: "",
      },
      maximizable: true,
      breakpoints: {
        // breakpoints so lassen! bei fragen an abdullah
        "2135px": "48vw",
        "1800px": "50vw",
        "1600px": "60vw",
        "1460px": "70vw",
        "1336px": "75vw",
        "1000px": "90vw",
        "831px": "100vw",
      },
      modal: true,
      closable: false,
    },
    templates: {
      footer: markRaw(DefaultFooter),
      header: markRaw(DefaultHeader),
    },
    data: {
      invoice: {
        isLoading: loading.value,
        isEditable: isDuplicated || isDraft,
        isCancelled: isCancelled,
        isCloned: isCloned,
        isDraft: isDraft,
        invoiceDocumentId: event.data.invoiceDocumentId
          ? event.data.invoiceDocumentId
          : event.data.invoiceDocumentId,
        docName: !isDuplicated ? event.data.docName : null,
        readOnly: (event.data.invoiceDocumentId != null) & !isDraft,
        storno: isCancelled,
      },
      rechnungsDaten: event.data,
    },
    onClose: async (options) => {
      const buttonType = options?.data?.buttonType;
      if (options.data && isDraft) {
        if (buttonType == "Save") {
        } else if (buttonType == "Delete") {
          //von virtalInvoices löschen mit invoiceDocumentId
          refreshReRenderTable();
          /*           const index = virtualInvoices.value.findIndex((invoice) => invoice.invoiceDocumentId == options.data.invoiceDocumentId);
                    if (index > -1) {
                      virtualInvoices.value.splice(index, 1);
                    }
                    //von invoices löschen mit invoiceDocumentId
                    const index2 = invoices.value.findIndex((invoice) => invoice.invoiceDocumentId == options.data.invoiceDocumentId);
                    if (index2 > -1) {
                      invoices.value.splice(index2, 1);
                    } */
        }
        refreshReRenderTable();
      } else if (buttonType == "Cancellation") {
        cloneInvoice(event, "Cancellation", dialogRef);
      } else if (buttonType == "Clone") {
        cloneInvoice(event, "Clone", dialogRef);
      } else if (buttonType == "GoToInvoice") {
        openInvoice(options.data.invoiceDocumentId);
      } else if (buttonType == "GoToOrder") {
        openOrder(options.data.orderDocumentId);
      } else refreshReRenderTable();
    },
  });
};

const openInvoice = async (invoiceDocumentId) => {
  await werteLaden();
  invoices.value.forEach((invoice) => {
    if (invoice.invoiceDocumentId == invoiceDocumentId) {
      openReadOnlyInvoice({ data: invoice });
    }
  });
};

const cloneInvoice = async (event, proceedType, dialogRef) => {
  const copyEvent = JSON.parse(JSON.stringify(event));
  const rechnungsDatenCopy = copyEvent.data;
  let docNameFrom = rechnungsDatenCopy.docName;
  let invoiceDocumentIdFrom = rechnungsDatenCopy.invoiceDocumentId;
  event.data.docContent.documentDatum = new Date().toISOString().slice(0, 10);
  event.data.docName = null;
  event.data.invoiceDocumentId = null;
  event.data.storno = true;
  ///TODO open paid status setzen
  switch (proceedType) {
    case "Cancellation":
      {
        event.data.docContent.invoiceTitel = "Rechnungskorrektur/ Gutschrift";
        event.data.documentStatus = "INVOICE_CANCELED";
        event.data.docContent.invoiceIntroduction =
          " Sie erhalten anbei die Rechnungskorrektur/ Gutschrift zur Rechnung " +
          docNameFrom;
        event.data.relationFrom = {
          invoiceCancelledFromId: invoiceDocumentIdFrom,
          invoiceCancelledDocName: docNameFrom,
        };
        const itemList = event.data.itemList;
        itemList.forEach((item) => {
          item.quantity = item.quantity * -1;
        });
      }
      break;
    case "Clone":
      {
        event.data.docContent.invoiceTitel = "Rechnung";
        event.data.documentStatus = "INVOICE_OPEN";
        event.data.docContent.invoiceIntroduction =
          " Sie erhalten anbei unsere Rechnung.";
        event.data.relationFrom = {
          duplicatedFromId: invoiceDocumentIdFrom,
          duplicatedFromDocName: docNameFrom,
        };
        event.data.isNew = true;
      }
      break;
  }
  openReadOnlyInvoice(event, proceedType);
};

const openOffer = (offerDocumentId) => {
  iAxios.get("/company/the-offer/" + offerDocumentId).then((response) => {
    if (response.data) {
      openOfferDialog({ data: response.data });
    }
  });
};

const openOfferDialog = async (event, proceedType) => {
  const isCloned = proceedType == "Clone";
  const isDraft = event.data.eventAction == "ORDER_DRAFT" || isCloned;
  const isCancelledDone = event.data.relationFrom.offerCancelledFromId;
  const isCancelled = proceedType == "Cancellation" || isCancelledDone;
  const isDuplicated = isCloned || isCancelled;
  const isOrderToOffer = proceedType == "fromOrder";

  //event.data.responsed.offerDocumentId = event.data.offerDocumentId || event.data.offerDocumentId;
  // event.data.responsed.documentName = event.data.docName;
  const dialogRef = dialog.open(OffersEditComponent, {
    props: {
      header: isCancelled ? "Angebotskorrektur " : "Angebot",
      subHeader: isOrderToOffer
        ? "(Entwurf erstellt von Auftrag " + event.data.docName + ")"
        : isDraft
        ? "(Entwurf)"
        : event.data.docName,
      style: {
        width: "",
      },
      maximizable: true,
      breakpoints: {
        // breakpoints so lassen! bei fragen an abdullah
        "2135px": "48vw",
        "1800px": "50vw",
        "1600px": "60vw",
        "1460px": "70vw",
        "1336px": "75vw",
        "1000px": "90vw",
        "831px": "100vw",
      },
      modal: true,
    },
    templates: {
      footer: markRaw(OffersEditFooter),
      header: markRaw(OffersEditHeader),
    },
    data: {
      offer: {
        isLoading: loading.value,
        isEditable: isDuplicated || isDraft,
        isCancelled: isCancelled,
        isCloned: isCloned,
        isDraft: isDraft,
        offerDocumentId: event.data.offerDocumentId
          ? event.data.offerDocumentId
          : event.data.offerDocumentId,
        docName: !isDuplicated ? event.data.docName : null,
        readOnly: false,
        storno: isCancelled,
      },
      angebotsDaten: event.data,
    },
    onClose: async (options) => {
      console.log(
        "🚀 ~ file: OffersView.vue:387 ~ onClose: ~ options:",
        options
      );

      const buttonType = options?.data?.buttonType;
      if (options.data && isDraft) {
        if (buttonType == "Save") {
        } else if (buttonType == "Delete") {
          //von virtalOffers löschen mit offerDocumentId
          refreshReRenderTable();
          /*           const index = virtualOffers.value.findIndex((offer) => offer.offerDocumentId == options.data.offerDocumentId);
                    if (index > -1) {
                      virtualOffers.value.splice(index, 1);
                    }
                    //von offers löschen mit offerDocumentId
                    const index2 = offers.value.findIndex((offer) => offer.offerDocumentId == options.data.offerDocumentId);
                    if (index2 > -1) {
                      offers.value.splice(index2, 1);
                    } */
        }
        refreshReRenderTable();
      } else if (buttonType == "offerToOrder") {
        console.log("offerToOrder");
        event;
      } else if (buttonType == "Clone") {
        cloneOffer(event, "Clone", dialogRef);
      } else if (buttonType == "GoToOffer") {
        openOffer(options.data.offerDocumentId);
      } else if (buttonType == "GoToOrder") {
        openOrder(options.data.orderDocumentId);
      } else refreshReRenderTable();
    },
  });
};

const openOrder = (orderDocumentId) => {
  iAxios.get("/company/the-order/" + orderDocumentId).then((response) => {
    if (response.data) {
      openOrderDialog({ data: response.data });
    }
  });
};

const openOrderDialog = async (event, proceedType) => {
  console.log("openOrderDialog");
  const isCloned = proceedType == "Clone";
  const isFromInvoice = proceedType == "fromInvoice";
  const isDraft = event.data.eventAction == "OFFER_DRAFT" || isCloned;
  const isCancelledDone = event.data?.relationFrom?.orderCancelledFromId;
  const isCancelled = proceedType == "Cancellation" || isCancelledDone;
  const isDuplicated = isCloned || isCancelled;

  //event.data.responsed.orderDocumentId = event.data.orderDocumentId || event.data.orderDocumentId;
  // event.data.responsed.documentName = event.data.docName;
  const dialogRef = dialog.open(OrdersEditComponent, {
    props: {
      position: "top",
      header: "Auftrag",
      subHeader: isDraft ? "(Entwurf)" : event.data.docName,
      subHeader: isDraft
        ? "(Entwurf)"
        : isFromInvoice
        ? "(Entwurf Auftrag erstellt von Angebot " + event.data.docName + ")"
        : event.data.docName,
      style: {
        width: "",
      },
      maximizable: true,
      breakpoints: {
        // breakpoints so lassen! bei fragen an abdullah
        "2135px": "48vw",
        "1800px": "50vw",
        "1600px": "60vw",
        "1460px": "70vw",
        "1336px": "75vw",
        "1000px": "90vw",
        "831px": "100vw",
      },
      modal: true,
    },
    templates: {
      footer: markRaw(OrderEditFooter),
      header: markRaw(OrderEditHeader),
    },
    data: {
      order: {
        isLoading: loading.value,
        isEditable: isDuplicated || isDraft,
        isCancelled: isCancelled,
        isCloned: isCloned,
        isFromOffer: true,
        isDraft: isDraft,
        orderDocumentId: event.data.orderDocumentId
          ? event.data.orderDocumentId
          : event.data.orderDocumentId,
        docName: !isDuplicated ? event.data.docName : null,
        readOnly: (event.data.orderDocumentId != null) & !isDraft,
        storno: isCancelled,
      },
      auftragsDaten: event.data,
    },
    onClose: async (options) => {
      console.log("🚀 ~ file: InvoiceView.vue:629 ~ options:", options);

      const buttonType = options?.data?.buttonType;
      if (options.data && isDraft) {
        if (buttonType == "Save") {
        } else if (buttonType == "Delete") {
          console.log("order deleted1");
          refreshReRenderTable();
        }
        refreshReRenderTable();
      } else if (buttonType == "Cancellation") {
        cloneOrder(event, "Cancellation", dialogRef);
      } else if (buttonType == "Clone") {
        cloneOrder(event, "Clone", dialogRef);
      } else if (buttonType == "GoToOffer") {
        openOffer(options.data.offerDocumentId);
      } else if (buttonType == "GoToInvoice") {
        openInvoice(options.data.invoiceDocumentId);
      } else confirmChangeToOrderWindow();
    },
  });
};

const confirmChangeToOrderWindow = () => {
  confirm.require({
    message: "Möchten Sie zum Bereich Aufträge wechseln?",
    header: "Zu Aufträge verlassen",
    icon: "pi pi-exclamation-triangle",
    acceptClass: "p-button-danger",
    acceptLabel: "Ja",
    rejectLabel: "Nein",
    defaultFocus: "reject",
    accept: () => {
      store.headerTitel = "AUFTRAGSVERWALTUNG";
      router.push("/OrdersView");
    },
    reject: () => {},
  });
};

const getRechnungIcon = (statusRechnung) => {
  switch (statusRechnung) {
    case "INVOICE_OPEN":
      return "pi pi-money-bill";
    case "INVOICE_PAID":
      return "pi pi-star-fill";
    case "INVOICE_PARTIAL":
      return "pi pi-star-half-fill";
    case "INVOICE_CANCELED":
      return "pi pi-times";
    case "INVOICE_CORRECTION":
      return "pi pi-file-edit";
  }
  return "pi pi-file-edit";
};

const getRechnungSeverity = (statusRechnung) => {
  switch (statusRechnung) {
    case "INVOICE_OPEN":
      return "danger";

    case "INVOICE_PAID":
      return "success";

    case "INVOICE_PARTIAL":
      return "warning";

    case "INVOICE_CANCELED":
      return "contrast";

    case "INVOICE_CORRECTION":
      return "info";
  }
  return "secondary";
};

/* const openRechnung = (event) => {
  const dialogRef = dialog.open(InvoicesEditComponent, {
    props: {
      header: 'Storno Rechnung Erstellen',
      style: {
        width: '90vw',
      },
      breakpoints: {
        '960px': '75vw',
        '640px': '90vw'
      },
      modal: true,
    },
    templates: {
      footer: markRaw(DefaultFooter),
      header: markRaw(DefaultHeader)
    },
    data: {
      invoice: {
        userObj: null,
        invoiceId: data.invoiceId,
        readOnly: false,
        storno: true
      },
      rechnungsDaten: event.data,
    },
    onClose: async (options) => {
      if (options.data) {
        const buttonType = options.data.buttonType;
        if (buttonType == "Save") {
          let response = speichern(dialogRef);
          console.log(response.data);
        }
      }
    }
  });
}; */

const getActionLabel = (label) => {
  let status = statuses.find((o) => o.value == label);
  if (!status) {
    return label;
  }
  return status.label;
};

const formatDate = (value) => {
  const date = new Date(value);
  return date.toLocaleDateString("de-DE", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
};

async function zeitMessen(funktion) {
  const start = performance.now();
  await funktion();
  const end = performance.now();
  const dauer = end - start;
  console.log(`InvoiceView Komplett geladen in ${dauer / 1000} Sekunden.`);
}

const getBezahltArten = (invoice) => {
  const paymentMethods = invoice?.payProgress?.paymentInput;
  if (!paymentMethods) return "";
  //paymentMethod, wird immer jeweils 1x von jeder Zahlungsart in einem String gespeichert, bspw. Rückgabe "Überweisung, Bar" für 4 Einträge paymentMethod="Überweisung, Bar, Überweisung, Bar", es wird nur jeweils das vorkommen der Zahlungsart 1x übernommen
  const uniquePaymentMethods = paymentMethods
    .map((bezahltArt) => bezahltArt.paymentMethod)
    .filter((value, index, self) => self.indexOf(value) === index);
  return uniquePaymentMethods.join(", ");
};

async function werteLaden() {
  loading.value = true;
  let allInvoices = null;
  await InvoicesService.getInvoices().then((data) => {
    if (data && data.invoices) {
      allInvoices = data.invoices.map((invoice) => ({
        ...invoice,
        name1: invoice.receiver.name1,
        postCode: invoice.receiver.plz,
        documentValueBrutto: formatCurrency(
          invoice.docContent.documentValueBrutto
        ),
        documentValueNetto: formatCurrency(
          invoice.docContent.documentValueNetto
        ),
        bezahlArten: getBezahltArten(invoice),
      }));
    }
  });
  if (customerUid && allInvoices) {
    invoices.value = await allInvoices.filter(
      (d) => d.customerUid === customerUid
    );
  } else invoices.value = allInvoices;

  if (store.debug)
    console.log(
      "🚀 ~ file: InvoicesView.vue:1035 ~ invoices.value:",
      invoices.value
    );
  loading.value = false;
}

const batchDownload = () => {
  if (selectedInvoices.value.length === 0) {
    toast.removeAllGroups();
    toast.add({
      severity: "warn",
      summary: "Keine Rechnungen ausgewählt",
      life: 3000,
    });
    return;
  }
  const invoiceIds = selectedInvoices.value.map(
    (invoice) => invoice.invoiceDocumentId
  );

  iAxios
    .post("/company/invoice-batch-timecheck ", { documentList: invoiceIds })
    .then((response) => {
      if (response.data) {
        console.log(
          "🚀 ~ file: InvoicesView.vue:1078 ~ response.data:",
          response.data
        );

        confirmStartDownload(response, invoiceIds);
      }
    });

  console.log(
    "🚀 ~ file: InvoicesView.vue:1034 ~ selectedInvoices:",
    selectedInvoices
  );
};

//Funktion gibt je nach dem nur Sekunden, Minuten und Sekunden, wenn mehr als 59 Sek.
//oder Stunden, Minuten und Sekunden, wenn mehr als 59 Min. zurück
const formatDuration = (seconds) => {
  if (seconds < 60) {
    return `${seconds} Sek.`;
  }
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  if (minutes < 60) {
    return `${minutes} Min. ${remainingSeconds} Sek.`;
  }
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  return `${hours} Std. ${remainingMinutes} Min. ${remainingSeconds} Sek.`;
};

const formatFileSize = (bytes) => {
  if (bytes < 1024) {
    return `${bytes} Bytes`;
  }
  const kilobytes = bytes / 1024;
  if (kilobytes < 1024) {
    return `${kilobytes.toFixed(2)} KB`;
  }
  const megabytes = kilobytes / 1024;
  return `${megabytes.toFixed(2)} MB`;
};

const confirmStartDownload = (response, invoiceIds) => {
  confirm.require({
    group: "downloadInvoice",
    header: "Rechnungen Herunterladen?",
    downloadDuration: formatDuration(response.data.downloadDuration),
    downloadSize: formatFileSize(response.data.downloadSize * 1024),
    numberOfDocuments: response.data.numberOfDocuments,
    accept: () => {
      iAxios
        .post(
          "/company/download-invoice-batch",
          { documentList: invoiceIds },
          {
            responseType: "arraybuffer",
          }
        )
        .then((response) => {
          if (response.data) {
            download(response.data, "Rechnungen.zip", "application/zip");
          }
        });
    },
    reject: () => {},
  });
};

const checkMobile = () => {
  isMobile.value = window.innerWidth <= 768; // Anpassen der Bildschirmbreite nach Bedarf
};

onMounted(() => {
  const state = router?.currentRoute?.value?.params?.state;
  if (state) {
    if (state=="open")
    filters.value.documentStatus.value = "INVOICE_OPEN";
  }
  console.log("🚀 ~ state:", state)
  console.log("🚀 ~ router:", router)

  console.log("customerUid", customerUid);
  checkMobile();
  window.addEventListener("resize", checkMobile);
});



onUnmounted(() => {
  window.removeEventListener("resize", checkMobile);
});

onBeforeMount(async () => {
  if (!store.isAuthenticated) return;
  await zeitMessen(werteLaden);
  //await werteLaden();
});

function closePreview() {
  pdfFile.value = null;
}

const MenuItems = ref([
  {
    label: "Exportieren",
    icon: "pi pi-download",
    command: () => {
      dataTableRef.value.exportCSV();
      toast.add({
        severity: "success",
        summary: "Exportieren",
        detail: "Exportierte Daten herunterladen",
        life: 3000,
      });
    },
  },
  {
    label: "Neue Rechnung erstellen",
    icon: "pi pi-file",
    command: () => {
      handleNewRow({ data: {} });
    },
  },
]);
</script>

<style scoped>
:deep(.text-purple-300 .p-badge) {
  background-color: #d8b4fe; /* Purple */
  min-width: 0.7rem;
  height: 0.7rem;
  line-height: 0.75rem;
  margin-right: 3px;
  margin-left: 10px;
  font-size: 0.6rem;
}

:deep(.text-yellow-300 .p-badge) {
  background-color: #fde047; /* Gelb */
  min-width: 0.7rem;
  height: 0.7rem;
  line-height: 0.75rem;
  margin-right: 3px;
  margin-left: 10px;
  font-size: 0.6rem;
}

:deep(.text-orange-300 .p-badge) {
  background-color: #fb923c; /* Orange */
  min-width: 0.7rem;
  height: 0.7rem;
  line-height: 0.75rem;
  margin-right: 3px;
  margin-left: 10px;
  font-size: 0.6rem;
}

:deep(.text-red-300 .p-badge) {
  background-color: #f87171; /* Rot */
  min-width: 0.7rem;
  height: 0.7rem;
  line-height: 0.75rem;
  margin-right: 3px;
  margin-left: 10px;
  font-size: 0.6rem;
}

:deep(.editable-cells-table td.p-cell-editing) {
  padding-top: 0;
  padding-bottom: 0;
}

:deep(.titelline) {
  border-color: #8085a1;
  border-right-width: 0 !important;
  border-bottom-width: 0 !important;
  border-style: solid;
}

:deep(.titel) {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.5rem;
  padding: 40px 0px 0px 50px;
  text-align: left;
  line-height: 0;
}

:deep(.p-card .p-card-title) {
  font-size: 1.2rem;
  font-weight: 400;
}

@media (max-width: 768px) {
  :deep(.p-fluid .p-inputtext) {
    width: 100%;
    min-width: 52px;
  }

  :deep(.p-inputtext) {
    padding: 0.75rem 0.25rem 0.75rem 0.25rem;
  }
}

:deep(.p-datatable .p-datatable-tbody) {
  padding: 0;
}

:deep(.p-inputswitch) {
  width: 3rem;
  height: 1.45rem;
  margin-top: 2px;
  top: -2px;
}

.no-outline:focus {
  outline: none !important;
  box-shadow: none !important;
}
</style>
