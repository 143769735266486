<template>
  <template v-if="isMainUser">
    <div v-if="store.whoAmIData.status == 'ACTIVE'">
      <ExpandableCard title="Ihre Tarifauswahl">
        <div class="w-full">
          Ihr Ausgewählter Tarif <b>{{ paymentData?.plan }}</b>
        </div>
        <div class="w-full pt-3">
          Nachfolgend finden Sie eine Übersicht aller Tarife und ihrer Eigenschaften im Vergleich. Sie haben die
          Möglichkeit, Ihren Tarif bequem zu upgraden oder downzugraden.
        </div>
        <table class="w-full pt-3">
          <thead>
            <tr style="background: #f9fafb;">
              <th class="p-3 text-left"></th>
              <th class="p-3 text-center rounded-lg text-lg"
                :class="paymentData?.plan === 'BASIC' ? 'bg-yellow-500' : ''">
                Basic</th>
              <th class="p-3 text-center rounded-lg text-lg"
                :class="paymentData?.plan === 'PREMIUM' ? 'bg-yellow-500' : ''">Premium</th>
              <th class="p-3 text-center rounded-lg text-lg"
                :class="paymentData?.plan === 'BUSINESS' ? 'bg-yellow-500' : ''">Business</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, index) in pricingPlanDetails" :key="index">
              <td class="p-3">{{ row.feature }}</td>
              <td class="p-3 text-center" :class="paymentData?.plan === 'BASIC' ? 'border-yellow-500' : ''">
                <template v-if="typeof row.plans.Basic === 'boolean'">
                  <i :class="row.plans.Basic ? 'pi pi-check text-green-500' : 'pi pi-times text-gray-500'"></i>
                </template>
                <template v-else>{{ row.plans.Basic }} <small v-if="row.plans?.SubBasic"><br>{{ row.plans.SubBasic }}
                  </small></template>
              </td>
              <td class="p-3 text-center" :class="paymentData?.plan === 'PREMIUM' ? 'border-yellow-500' : ''">
                <template v-if="typeof row.plans.Premium === 'boolean'">
                  <i :class="row.plans.Premium ? 'pi pi-check text-green-500' : 'pi pi-times text-gray-500'"></i>
                </template>
                <template v-else>{{ row.plans.Premium }}<small v-if="row.plans?.SubPremium"><br>{{ row.plans.SubPremium
                    }}
                  </small></template>
              </td>
              <td class="p-3 text-center" :class="paymentData?.plan === 'BUSINESS' ? 'border-yellow-500' : ''">
                <template v-if="typeof row.plans.Business === 'boolean'">
                  <i :class="row.plans.Business ? 'pi pi-check text-green-500' : 'pi pi-times text-gray-500'"></i>
                </template>
                <template v-else>{{ row.plans.Business }}<small v-if="row.plans?.SubBusiness"><br>{{
                  row.plans.SubBusiness
                    }} </small></template>
              </td>
            </tr>
            <tr>
              <td></td>
              <td class="p-3 text-center"><template v-if="paymentData?.plan !== 'BASIC'"><Button
                    label="Tarif downgraden" @click="switchPlan('BASIC')"></Button></template>
              </td>
              <td class="p-3 text-center">
                <template v-if="paymentData?.plan === 'BASIC'"><Button label="Tarif upgraden"
                    @click="switchPlan('PREMIUM')"></Button></template>
                <template v-else-if="paymentData?.plan === 'BUSINESS'"><Button label="Tarif downgraden"
                    @click="switchPlan('PREMIUM')"></Button></template>
              </td>
              <td class="p-3 text-center"><template v-if="paymentData?.plan !== 'BUSINESS'"><Button
                    label="Tarif upgraden" @click="switchPlan('BUSINESS')"></Button></template>
              </td>
            </tr>
          </tbody>
        </table>
        <ExpandableCard title="Kündigung">
          <div class="pb-3">
            Hier können Sie ihren Vertrag zum nächstmöglichen Termin Kündigen.
          </div>

          <Button label="Vertrag Kündigen" @click="confirmPlanCancellation" />
        </ExpandableCard>
      </ExpandableCard>
      <ExpandableCard expanded=true title="Zusätzlich buchbare module" v-if="bookableModules">
        <div v-for="module in Object.values(bookableModules).filter(o => o.active)" :key="module.moduleId">
          <div class="p-card-header">
            <h3 class="p-text-bold">{{ module.moduleName }}</h3>
            <p>
              Preis: <b>{{ module.modulePrice.toFixed(2) }} €</b> / {{ getIntervall(module.paymentInterval) }}
            </p>
          </div>

          <template v-if="module.moduleId === 'subAccounts'">
            <p v-if="bookedModules">Gesamt verfügbare Zusatznutzer: <b>{{ bookedModules[module.moduleId] ? bookedModules[module.moduleId] : 0 }}</b></p>
            <div class="p-grid p-ai-center p-gap-2">
              <div class="p-col">
                <span>Zusätzliche Nutzer buchen:</span>
                <div class="p-d-flex p-ai-center p-gap-2 pt-2">
                  <InputNumber v-model="module.toOrder" showButtons buttonLayout="horizontal" :step="5" :min="0" 
                  :pt="{ input: { style: { width: '60px' } } }">
                    <template #incrementbuttonicon><span class="pi pi-plus" /></template>
                    <template #decrementbuttonicon><span class="pi pi-minus" /></template>
                  </InputNumber>
                  <template v-if="module.toOrder > 0">
                  <Button @click="orderMultipleAmount(module)" label="Bestellen" class="p-button-success" />
                    Zusatzkosten von +{{ ((module.toOrder/5) * module.modulePrice).toFixed(2) }} € / {{ getIntervall(module.paymentInterval) }}</template>
                </div>
              </div>

              <div class="p-col pt-3" v-if="bookedModules && bookedModules[module.moduleId] > 0">
                <span>Gebuchte Zusatznutzer kündigen:</span>
                <div class="p-d-flex p-ai-center p-gap-2 pt-2">
                  <InputNumber v-model="module.toCancel" showButtons buttonLayout="horizontal" :step="5" :min="0" :max="bookedModules[module.moduleId]">
                    <template #incrementbuttonicon><span class="pi pi-plus" /></template>
                    <template #decrementbuttonicon><span class="pi pi-minus" /></template>
                  </InputNumber>
                  <template v-if="module.toCancel > 0">
                  <Button @click="cancelMultipleAmount(module)" label="Kündigen" class="p-button-danger" />
                    belastung von -{{ ((module.toCancel/5) * module.modulePrice).toFixed(2) }} € / {{ getIntervall(module.paymentInterval) }} weniger</template>
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="p-d-flex p-jc-between">
              <Button v-if="bookedModules && bookedModules[module.moduleId]" @click="cancelModule(module)" label="Kündigen"
                class="p-button-danger" />
              <Button v-else @click="orderModule(module)" label="Bestellen" class="p-button-success" />
            </div>
          </template>

          <Divider />

        </div>
      </ExpandableCard>
      <ConfirmDialog group="cancelPlan">
        <template #container="{ message, acceptCallback, rejectCallback }">
          <div class="flex flex-column align-items-center p-5 surface-overlay border-round">
            <div
              class="border-circle bg-red-500 inline-flex justify-content-center align-items-center h-6rem w-6rem -mt-8">
              <i class="pi pi-trash text-5xl text-white"></i>
            </div>
            <span class="font-bold text-2xl block mb-2 mt-4">{{
              message.header
              }}</span>
            <p class="mb-0">
              Möchten Sie wirklich ihren Tarif kündigen?
            </p>
            <p>
              Geben Sie aus Sicheheitsgründen das Wort "KÜNDIGUNG" in Großbuchstaben und die Pin
              <span class="font-bold">{{ confirmPin }}</span> ein.
            </p>
            Bestätigung:
            <InputText v-model="confirmCancelWord" class="w-12rem" />
            <p></p>
            Pin:
            <div class="card flex justify-content-center">
              <InputOtp v-model="confirmedPin" integerOnly />
            </div>
            <div class="flex align-items-center gap-2 mt-4">
              <Button label="Ja" outlined severity="danger" @click="acceptCallback" class="w-8rem"></Button>
              <Button label="Nein" outlined @click="rejectCallback" class="w-8rem"></Button>
            </div>
          </div>
        </template>
      </ConfirmDialog>
    </div>
    <div v-else>
      <div v-if="paymentData?.paymentStatus === 'open' && paymentData?.paymentLinks.checkout.status == 'open'">
        <div class="p-card w-full p-3 mt-3 justify-content-center">
          <h2>
            Ihre Zahlung wird verarbeitet, bitte haben Sie etwas Geduld.
            <br />
            <Button class="mt-3" @click="goToCheckout" label="Hier gelangen Sie zum Bezahlvorgang" />
          </h2>
        </div>
      </div>
      <div v-else>
        <div class="flex justify-content-center text-2xl mb-5">
          <template v-if="store.whoAmIData.availableTrialDays > 0">
            Ihre Testzeit läuft in
            <span class="text-red-200 font-bold px-2"> {{ store.whoAmIData.availableTrialDays }} Tagen</span>
            aus.
          </template>
          <template v-else>
            <span class="text-red-200 font-bold px-2">Ihre Testzeit ist abgelaufen.</span>
          </template>
        </div>

        <PricingPlanShort v-if="!showDetails" @show-details="showDetails = true" />
        <PricingPlanCompare v-else />

        <Button :label="showDetails ? 'Buchen' : 'Vergleichen'" class="w-full p-button-lg mt-7"
          @click="showDetails = !showDetails" />
      </div>
    </div>
  </template>
</template>

<script setup>
import { onMounted, ref } from "vue";
import PricingPlanShort from "./PricingPlanShort.vue";
import PricingPlanCompare from "./PricingPlanCompare.vue";
import { userStore } from "@/store/userStore";
import { storeToRefs } from "pinia";
import iAxios from "@/store/axiosInterface";
import { useToast } from "primevue/usetoast";
import { useConfirm } from "primevue/useconfirm";
import ExpandableCard from "@/components/widgets/ExpandableCard.vue";

const store = userStore();
const {
  isMainUser,
} = storeToRefs(store);
const paymentData = ref();
const bookedModules = ref([]);
const pricingPlanDetails = ref();
const bookableModules = ref([]);

const confirmPin = ref();
const confirmedPin = ref();
const confirmCancelWord = ref();

const toast = useToast();
const confirm = useConfirm();

const goToCheckout = () => {
  let checkoutURL = paymentData.value.paymentLinks.checkout.href;

  document.location.href = checkoutURL;
}

function generatePin() {
  return Math.floor(Math.random() * 9000) + 1000;
}

const switchPlan = (planIdentifier) => {
  confirm.require({
    message: 'Sie sind dabei in einen neuen Tarif zu wechseln. Sind Sie sicher?',
    header: 'Confirmation',
    icon: 'pi pi-exclamation-triangle',
    rejectClass: 'p-button-secondary p-button-outlined',
    rejectLabel: 'Abbrechen',
    acceptClass: 'p-button-danger',
    acceptLabel: 'Ja',
    accept: () => {
      iAxios.get(process.env.VUE_APP_BASE_API_URL + "/company/plan-upgrade/" + planIdentifier)
        .then(async (res) => {
          console.log(res);
          if (res.status == 200) {
            toast.add({
              severity: "info",
              summary:
                "Tarif umgeändert",
              detail: "Ihr Tarif wurde erfolgreich umgeändert.",
              life: 3000,
            });
            let response = await iAxios.get(process.env.VUE_APP_BASE_API_URL + "/company/payment-comeback");
            paymentData.value = response.data;

            store.whoAmIData = await store.whoAmI();
            console.log(store.whoAmIData);
          }
        });
    },
    reject: () => {
      toast.add({ severity: 'error', summary: 'Tarifwechsel abgebrochen', detail: 'Sie haben den Tarifwechsel abgebrochen.', life: 3000 });
    }
  });
}

const confirmPlanCancellation = () => {
  confirmPin.value = generatePin();
  confirm.require({
    group: "cancelPlan",
    header: "Tarif kündigung bestätigen",
    icon: "pi pi-trash text-red-500",
    acceptClass: "p-button-danger",
    acceptLabel: "Ja",
    rejectLabel: "Nein",
    defaultFocus: "reject",
    accept: () => {
      const pinCorrect = confirmPin.value == confirmedPin.value;
      const cancelConfirmation = confirmCancelWord.value === "KÜNDIGUNG";
      if (pinCorrect && cancelConfirmation) {
        iAxios
          .get(process.env.VUE_APP_BASE_API_URL + "/company/payment-quit")
          .then(async (res) => {
            console.log(res);
            if (res.status == 200) {
              toast.add({
                severity: "info",
                summary:
                  "Der Vertrag wurde gekündigt",
                detail: "Vertrag gekündigt",
                life: 3000,
              });
              store.whoAmIData.status = 'TRIAL';

              store.whoAmIData = await store.whoAmI();
              console.log(store.whoAmIData);
            }
          });

      } else {
        confirmCancelWord.value = "";
        confirmedPin.value = "";
        toast.add({
          severity: "error",
          summary:
            "Fehler beim Kündigen ihres Tarifs",
          detail:
            (!pinCorrect ? " Pin fehlerhaft" : "") +
            (!cancelConfirmation ? " Kündigungsbestätigung Falsch" : ""),
          life: 3000,
        });
      }
      confirmedPin.value = "";
      confirmCancelWord.value = "";
    },
    reject: () => {
      confirmedPin.value = "";
      confirmCancelWord.value = "";
      toast.add({
        severity: "error",
        summary: "Abgebrochen",
        detail: "Tarif Kündigung abgebrochen",
        life: 3000,
      });
    },
  });
};

onMounted(async () => {
  reloadData();
});

const cancelModule = (module) => {
  let moduleName = module.moduleName;
  let moduleId = module.moduleId;

  confirm.require({
    message: `Sie sind dabei das Modul '${moduleName}' zu Kündigen, sind Sie sicher?`,
    header: 'Bestätigung',
    icon: 'pi pi-exclamation-triangle',
    rejectClass: 'p-button-outlined p-button-sucess',
    acceptClass: 'p-button-danger',
    rejectLabel: 'Nein, kündigung Abbrechen',
    acceptLabel: 'Ja, Bitte Modul Kündigen',
    accept: () => {
      iAxios.post(process.env.VUE_APP_BASE_API_URL + "/company/quit-module", { moduleId: moduleId }).then(resp => {
        toast.add({ severity: 'info', summary: 'Modul gekündigt', detail: `Das Modul '${moduleName}' wurde erfolgreich gekündigt.`, life: 3000 });
        reloadData();
      }).catch((reason) => {
        toast.add({ severity: 'error', summary: 'Modul kündigung nicht erfolgreich', detail: `Das Modul '${moduleName}' konnte nicht gekündigt werden.`, life: 3000 });
      });
    },
    reject: () => {
      toast.add({ severity: 'info', summary: 'Kündigung agbreochen', detail: 'Kündigung wurde abgebrochen.', life: 3000 });
    }
  });
};

const orderModule = (module) => {
  let moduleName = module.moduleName;
  let modulePreis = module.modulePrice.toFixed(2);
  confirm.require({
    message: `Sie sind dabei das Modul '${moduleName}' für ${modulePreis} €/Monat zu Bestellen, sind Sie sicher?`,
    header: 'Bestätigung',
    icon: 'pi pi-exclamation-triangle',
    rejectClass: 'p-button-outlined p-button-sucess',
    acceptClass: 'p-button-danger',
    rejectLabel: 'Nein, bestellung Abbrechen',
    acceptLabel: 'Ja, Bitte bestellung Abschließen',
    accept: () => {
      iAxios.post(process.env.VUE_APP_BASE_API_URL + "/company/book-module", { moduleId: module.moduleId }).then(resp => {
        toast.add({ severity: 'info', summary: 'Bestellung ausgeführt', detail: 'Das gewünschte Modul wurde erfolgreich bestellt.', life: 3000 });
        reloadData();
      }).catch((reason) => {
        toast.add({ severity: 'error', summary: 'Bestellung nicht erfolgreich', detail: 'Das Modul konnte nicht bestellt werden.', life: 3000 });
      });
    },
    reject: () => {
      toast.add({ severity: 'error', summary: 'Bestellung agbreochen', detail: 'Bestellvorgang wurde abgebrochen.', life: 3000 });
    }
  });
};

const getIntervall = (intervall) => {
  if (intervall === '1 month') {
    return 'Monat'
  }
}

const cancelMultipleAmount = (module) => {
  let moduleName = module.moduleName;

  let count = module.toCancel;
  if (module.moduleId === 'subAccounts') {
    count = module.toCancel / 5;
  }
  confirm.require({
    message: `Sie sind dabei das Modul '${moduleName}' zu Kündigen, sind Sie sicher?`,
    header: 'Bestätigung',
    icon: 'pi pi-exclamation-triangle',
    rejectClass: 'p-button-outlined p-button-sucess',
    acceptClass: 'p-button-danger',
    rejectLabel: 'Nein, kündigung Abbrechen',
    acceptLabel: 'Ja, Bitte Modul Kündigen',
    accept: () => {
      let body = { moduleId: module.moduleId, count: count };
      iAxios.post(process.env.VUE_APP_BASE_API_URL + "/company/module-quit", body).then(resp => {
        toast.add({ severity: 'info', summary: 'Modul gekündigt', detail: 'Das gewünschte Modul wurde gekündigt.', life: 3000 });
        reloadData();
      }).catch(reason => {
        toast.add({ severity: 'error', summary: 'Modul nicht gekündigt', detail: 'Das gewünschte Modul konnte nicht gekündigt werden, bitte wenden Sie sich an den Kundendienst.', life: 3000 });
      })
    },
    reject: () => {
      toast.add({ severity: 'info', summary: 'Kündigung agbreochen', detail: 'Kündigung wurde abgebrochen.', life: 3000 });
    }
  });

}

const orderMultipleAmount = (module) => {
  let moduleName = module.moduleName;
  let modulePreis = module.modulePrice;
  let count = module.toOrder;
  if (module.moduleId === 'subAccounts') {
    count = module.toOrder / 5;
  }

  confirm.require({
    message: `Sie sind dabei das Modul '${moduleName}' für ` + (count * modulePreis) + ` €/Monat zu Bestellen, sind Sie sicher?`,
    header: 'Bestätigung',
    icon: 'pi pi-exclamation-triangle',
    rejectClass: 'p-button-outlined p-button-sucess',
    acceptClass: 'p-button-danger',
    rejectLabel: 'Nein, bestellung Abbrechen',
    acceptLabel: 'Ja, Bitte bestellung Abschließen',
    accept: () => {
      iAxios.post(process.env.VUE_APP_BASE_API_URL + "/company/book-module", { moduleId: module.moduleId, count: count }).then(resp => {
        toast.add({ severity: 'info', summary: 'Modul bestellt', detail: 'Das gewünschte Modul wurde bestellt.', life: 3000 });
        reloadData();
      }).catch(reason => {
        toast.add({ severity: 'error', summary: 'Modul nicht gekündigt', detail: 'Das gewünschte Modul konnte nicht bestellt werden, bitte wenden Sie sich an den Kundendienst.', life: 3000 });
      })
    },
    reject: () => {
      toast.add({ severity: 'error', summary: 'Bestellung agbreochen', detail: 'Bestellvorgang wurde abgebrochen.', life: 3000 });
    }
  });


}

const reloadData = async () => {
  let response = await iAxios.get(process.env.VUE_APP_BASE_API_URL + "/company/plans");
  pricingPlanDetails.value = response.data.pricingPlanDetails;
  bookableModules.value = response.data.bookableModules;

  response = await iAxios.get(process.env.VUE_APP_BASE_API_URL + "/company/payment-comeback");
  paymentData.value = response.data;
  bookedModules.value = response.data.bookedModules;
}
</script>

<style scoped>
th,
td {
  border-bottom: 1px solid #e5e5e5;
}

.p-card {
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background: white;
}

.p-card-header {
  margin-bottom: 1rem;
}

.p-d-flex {
  display: flex;
}

.p-ai-center {
  align-items: center;
}

.p-jc-between {
  justify-content: space-between;
}

.p-gap-2 {
  gap: 1rem;
}
</style>
